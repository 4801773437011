import * as React from "react";
import styled from "styled-components";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Grid, PrimaryButton, TableCell, TextField } from "@tanner/partner";
import { RoleDetail } from "../../../types";
import { api, updateRole } from "../../../api";
import { NotificationState } from "../../../hooks/useNotification";

const TableRowStyled = styled.tr`
  background-color: #f8f9fd;
  .title {
    font-size: 16px;
  }

  .header-box-btn {
    flex-direction: row;

    button {
      height: 44px;
      width: 180px;
      justify-content: center;
      margin: 0 25px 0 0;
    }

    .btn-cancel {
      color: #005cb9;
      background-color: transparent;
      border: 1px solid #005cb9 !important;
    }
  }

  .grid-center {
    display: flex;
    justify-content: flex-start;
  }

  .body {
    max-height: 350px;
    padding: 0.8em;

    .form-user {
      padding-right: 1.5em;

      label {
        font-size: 14px;
      }

      input,
      textarea {
        width: 386px;
        padding: 11px 20px 11px 15px !important;
        margin: 0 !important;

        ::placeholder {
          color: #bcbcbc;
        }

        ::-ms-input-placeholder {
          color: #bcbcbc;
        }
      }
    }
  }
`;

type PropsRowEdit = {
  role: RoleDetail;
  appId: string;
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setNotification: (message: string, type: NotificationState) => void;
  reload: () => void;
};

type ErrorApi = {
  id: string;
  message: string;
};

const errorInit = {
  status: false,
  message: "",
};

const RoleEditRowForm: React.FC<PropsRowEdit> = ({
  role,
  appId,
  setShowEdit,
  setNotification,
  reload,
}) => {
  const [errorRole, setErrorRole] = React.useState(errorInit);
  const [errorDescription, setErrorDescription] = React.useState(errorInit);

  const [isSaving, setIsSaving] = React.useState(false);
  const [roleName, setRoleName] = React.useState("");
  const [roleDescription, setRoleDescription] = React.useState("");

  const appInsights = useAppInsightsContext();

  const handleEditRole = async () => {
    if (roleName.trim().length < 5) {
      setErrorRole({
        status: true,
        message: "Ingresar un nombre para el rol",
      });
      return;
    }

    if (roleName.trim().length > 40) {
      setErrorRole({
        status: true,
        message: "El rol puede tener un máximo de 40 caracteres.",
      });
      return;
    }

    if (roleDescription.trim().length > 40) {
      setErrorDescription({
        status: true,
        message: "La descripción puede tener un máximo de 40 caracteres.",
      });
      return;
    }

    setIsSaving(true);

    try {
      const request = {
        name: roleName.trim(),
        description: roleDescription.trim(),
      };
      await api.put(updateRole(`:${appId}`, role.Id), request);
      reload();

      setNotification("Rol actualizado correctamente", "success");
    } catch (err) {
      if (err.response.status === 409) {
        const errorApi = err.response.data as ErrorApi;
        setNotification(errorApi.message, "error");
      } else {
        setNotification("Ocurrió un problema actualizando el rol.", "error");
        appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
      }
    }
    setIsSaving(false);
    setShowEdit(false);
  };

  React.useEffect(() => {
    setRoleName(role.Name);
    setRoleDescription(role.Description == null ? "" : role.Description);
  }, [role]);

  return (
    <TableRowStyled>
      <TableCell colspan={8}>
        <div className="content">
          <Grid container nomargin nopadding lg={12} md={12} sm={12} className="body">
            <Grid item sm={12} md={12} lg={12}>
              <p className="title">Información relacionada al rol</p>
            </Grid>
            <Grid item sm={12} md={12} lg={12} className="form-user">
              <label htmlFor="nameRoleInput">
                Nombre rol
                <TextField
                  disabled={isSaving}
                  error={errorRole.status}
                  errorText={errorRole.message}
                  value={roleName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setRoleName(e.currentTarget.value)
                  }
                />
              </label>
            </Grid>
            <Grid item sm={12} md={12} lg={12} className="form-user">
              <label htmlFor="descriptionRoleInput">
                Descripción
                <TextField
                  disabled={isSaving}
                  error={errorDescription.status}
                  errorText={errorDescription.message}
                  value={roleDescription}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setRoleDescription(e.currentTarget.value)
                  }
                />
              </label>
            </Grid>
            <Grid item sm={12} md={12} lg={12} className="grid-center header-box-btn">
              <PrimaryButton
                className="btn-cancel"
                disabled={isSaving}
                onClick={(e) => {
                  e.preventDefault();
                  setShowEdit(false);
                }}
              >
                Cancelar
              </PrimaryButton>
              <PrimaryButton disabled={isSaving} onClick={handleEditRole}>
                {isSaving && <span className="ticon-loading" />}
                Guardar
              </PrimaryButton>
            </Grid>
          </Grid>
        </div>
      </TableCell>
    </TableRowStyled>
  );
};

export default RoleEditRowForm;
