export const sanitizeIdForQuery = (id: string) => {
  return id.split(":")[1];
};

export const isValidUrl = (url: string | undefined) => {
  if (url == null) return false;
  const urlCleaned = url.toLowerCase().trim();
  const rUrl = new RegExp(
    /(?:^|[ \t])((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/gm
  );
  return rUrl.test(urlCleaned);
};

export const extractUrlsSeparatedByCommas = (urls: string): string[] => {
  return urls.split(",").map((u) => {
    return u.trim().toLowerCase();
  });
};
