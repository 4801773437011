import * as React from "react";
import { Grid, PrimaryButton, TextField } from "@tanner/partner";
import styled from "styled-components";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { api, createPermissionApp } from "../../../api";
import { NotificationState } from "../../../hooks/useNotification";

const CreateStyled = styled(Grid)`
  width: 100%;
  height: 205px;
  background-color: #f8f9fd;
  padding: 22px 40px;

  input::placeholder {
    color: #828282;
  }

  input::-ms-input-placeholder {
    color: #828282;
  }

  .form {
    display: flex;
    flex-direction: column;

    h4 {
      color: #005cb9;
      font-weight: 400;
      margin: 0 0 14px 0;
    }

    label {
      color: #afafaf;

      div {
        padding-top: 4px;
      }
    }

    .box-btn {
      display: flex;
      justify-content: space-between;

      button {
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      button:first-child {
        background-color: transparent;
        border: 1px solid #005cb9;
        color: #005cb9;
      }

      .ticon-loading {
        color: #fff;
      }
    }
  }
`;

type Props = {
  setShowCreate: React.Dispatch<React.SetStateAction<boolean>>;
  reload: () => void;
  appId: string;
  setNotification: (message: string, type: NotificationState) => void;
};

type ErrorApi = {
  id: string;
  message: string;
};

const CreatePermissionApplication: React.FC<Props> = (props) => {
  const { setShowCreate, reload, appId, setNotification } = props;
  const [creating, setCreating] = React.useState(false);
  const [inputError, setInputError] = React.useState(false);
  const [permissionName, setPermissionName] = React.useState("");

  const appInsights = useAppInsightsContext();

  const handleCreatePermission = async () => {
    if (permissionName.trim() === "") {
      setInputError(true);
      return;
    }
    setCreating(true);
    try {
      const request = {
        action: permissionName.trim(),
      };
      await api.post(createPermissionApp(appId), request);
      reload();

      setNotification("Permiso creado correctamente", "success");
    } catch (err) {
      if (err.response.status === 409) {
        const errorApi = err.response.data as ErrorApi;
        setNotification(errorApi.message, "error");
      } else {
        setNotification("Ocurrió un problema creando el permiso.", "error");
        appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
      }
    }
    setCreating(false);
    setShowCreate(false);
  };

  return (
    <CreateStyled container sm={12} md={12} lg={12} nopadding nomargin>
      <Grid item sm={12} lg={6} md={6} className="form">
        <h4>Ingresa información del nuevo permiso</h4>
        <label htmlFor="namePermissionInput">
          Crear nuevo permiso
          <TextField
            placeholder="Ingresa el nombre del nuevo permiso"
            error={inputError}
            errorText="El nombre del permiso es requerido."
            value={permissionName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPermissionName(e.currentTarget.value)
            }
          />
        </label>
        <div className="box-btn">
          <PrimaryButton className="btn-cancel" onClick={() => setShowCreate(false)}>
            Cancelar
          </PrimaryButton>
          <PrimaryButton onClick={handleCreatePermission}>
            {creating && <span className="ticon-loading" />}
            {creating ? "Creando" : "Guardar"}
          </PrimaryButton>
        </div>
      </Grid>
    </CreateStyled>
  );
};

export default CreatePermissionApplication;
