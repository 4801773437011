import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { getApplications } from "../api";
import { ApplicationsResume } from "../types";
import * as api from "../api";

interface ApplicationContextProps {
  appsStatus: "init" | "fetching" | "error" | "fetched";
  apps: ApplicationsResume | undefined;
  reload: () => void;
}

export const ApplicationContext = React.createContext({
  appsStatus: "init",
  apps: undefined,
  reload: () => {},
} as ApplicationContextProps);

export const ApplicationProvider: React.FC = ({ children }) => {
  const [totalApps, setTotalApps] = React.useState(99999);
  const [appsStatus, setAppsStatus] = React.useState<"init" | "fetching" | "error" | "fetched">(
    "init"
  );
  const [apps, setApps] = React.useState<ApplicationsResume>({} as ApplicationsResume);
  const { getAccessTokenSilently } = useAuth0();
  const appInsights = useAppInsightsContext();

  React.useEffect(() => {
    setAppsStatus("fetching");

    try {
      (async () => {
        const token = await getAccessTokenSilently();
        const response = await api.authorized(token).get(`${getApplications(1, totalApps)}`);
        setApps(response.data);
        setAppsStatus("fetched");
      })();
    } catch (err) {
      appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
    }
  }, [appInsights, getAccessTokenSilently, totalApps]);

  const reload = () => {
    setTotalApps((p) => p + 1);
  };

  return (
    <ApplicationContext.Provider value={{ appsStatus, apps, reload }}>
      {/* <ApplicationContext.Provider value={{ refresh, setRefresh, alert, showAlert }}> */}
      {children}
    </ApplicationContext.Provider>
  );
};
