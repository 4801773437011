import * as React from "react";
import styled from "styled-components";

type Props = {
  title: string;
  subtitle?: string;
  utilitiesBox?: React.ReactNode;
};

const Wrapper = styled.div`
  min-height: 63px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .titles {
    width: 50%;
  }

  .titles h1,
  .titles h3 {
    color: #fff;
  }

  .titles h1 {
    font-weight: 700;
    margin: 0;
  }

  .titles h3 {
    font-weight: 600;
    margin-top: 5px;
  }

  @media only screen and (max-width: 1240px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .titles {
      width: 100%;
    }
  }
`;

const Header: React.FC<Props> = ({ title, subtitle, utilitiesBox }) => {
  return (
    <Wrapper>
      <div className="titles">
        <h1>{title}</h1>
        {subtitle && <h3>{subtitle}</h3>}
      </div>
      <div>{utilitiesBox}</div>
    </Wrapper>
  );
};

export default Header;
