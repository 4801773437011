import * as React from "react";
import { View } from "@tanner/icons";
import { PrimaryText, Table, TableBody, TableCell, TableHead, TableRow } from "@tanner/partner";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import UserGridDataLoader from "../../loaders/UserGridDataLoader";
import { RoutesPath } from "../../../utils/enums";
import { ApplicationPermission, AppResume } from "../../../types";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

type Props = {
  mainTitle: string;
  headerTitle1: string;
  headerTitle2: string;
  data?: AppResume[] | ApplicationPermission[];
  isLoading?: boolean;
};

const LatestAppsCreated: React.FC<Props> = ({
  mainTitle,
  headerTitle1,
  headerTitle2,
  data,
  isLoading,
}) => {
  if (isLoading) {
    return <UserGridDataLoader />;
  }

  function isAppResume(app: AppResume | ApplicationPermission): app is AppResume {
    return (app as AppResume).id !== undefined;
  }

  return (
    <Wrapper>
      <PrimaryText type="h4">{mainTitle}</PrimaryText>
      <Table hover>
        <TableHead>
          <TableRow>
            <TableCell component="th">{headerTitle1}</TableCell>
            <TableCell component="th">{headerTitle2}</TableCell>
            <TableCell component="th">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((app: AppResume | ApplicationPermission) => {
            if (isAppResume(app)) {
              return (
                <TableRow key={app.id}>
                  <TableCell title={headerTitle1}>{app.name}</TableCell>
                  <TableCell title={headerTitle2}>{app.id}</TableCell>
                  <TableCell>
                    <NavLink key={app.id} to={`${RoutesPath.Applications}/:${app.id}`}>
                      <View size={20} />
                    </NavLink>
                  </TableCell>
                </TableRow>
              );
            }
            return (
              <TableRow key={app.permissionid}>
                <TableCell title={headerTitle1}>{app.applicationname}</TableCell>
                <TableCell title={headerTitle2}>{app.permissionaction}</TableCell>
                <TableCell>
                  <NavLink
                    to={`${RoutesPath.Applications}/:${app.applicationid}${RoutesPath.Permissions}`}
                  >
                    <View size={20} />
                  </NavLink>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Wrapper>
  );
};

export default LatestAppsCreated;
