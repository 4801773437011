import * as React from "react";
import { AlertMessageProps } from "../../types";
// import useFetch from "../../hooks/useFetch";
// import { AplicationsResume } from "../../types";
// import { getApplications } from "../../api";

interface PermissionContextProps {
  // appsStatus: "init" | "fetching" | "error" | "fetched";
  // apps: AplicationsResume | undefined;
  // reload: () => void;
  // changeApp: boolean;
  // setChangeApp: (value: boolean) => void;
  refresh: number;
  setRefresh: (value: number) => void;
  alert: AlertMessageProps;
  showAlert: (value: AlertMessageProps) => void;
}

export const PermissionContext = React.createContext({
  // appsStatus: "init",
  // apps: undefined,
  // reload: () => {},
} as PermissionContextProps);

export const PermissionProvider: React.FC = ({ children }) => {
  // todo: resolver problema con refresh automatico al crear un permiso.

  // const { status: appsStatus, data: apps, reload } = useFetch<AplicationsResume>(
  //   getApplications(1, 99999)
  // );

  // const [changeApp, setChangeApp] = React.useState(false);
  const [refresh, setRefresh] = React.useState(0);
  const [alert, showAlert] = React.useState({
    message: "",
    type: "none",
  } as AlertMessageProps);

  return (
    // <PermissionContext.Provider value={{ appsStatus, apps, reload, changeApp, setChangeApp }}>
    <PermissionContext.Provider value={{ refresh, setRefresh, alert, showAlert }}>
      {children}
    </PermissionContext.Provider>
  );
};
