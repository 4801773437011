export const getPercentageOfTotalIsAnAmount = (value: number, base: number): number => {
  return Math.round((value * 100) / base);
};

export function not(a: number[], b: number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

export function intersection(a: number[], b: number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export function union(a: number[], b: number[]) {
  return [...a, ...not(b, a)];
}

export const numberOfChecked = (selected: number[], items: number[]) =>
  intersection(selected, items).length;
