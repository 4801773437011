import * as React from "react";
import { Checkbox, TableCell, TableRow } from "@tanner/partner";
import { RoleDetail, RoleToDelete } from "../../../types";
import RoleEditRowForm from "../RoleEditRowForm";
import { NotificationState } from "../../../hooks/useNotification";

type PropsRow = {
  index: number;
  role: RoleDetail;
  appId: string;
  handleRowCheck: (text: number, roleId: string) => void;
  selected: boolean;
  setRoleToDelete: React.Dispatch<React.SetStateAction<RoleToDelete>>;
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setNotification: (message: string, type: NotificationState) => void;
  reload: () => void;
};

const RoleEditRow: React.FC<PropsRow> = ({
  role,
  appId,
  handleRowCheck,
  setRoleToDelete,
  setShowDeleteModal,
  setNotification,
  reload,
  selected,
  index,
}) => {
  const [openEdit, setOpenEdit] = React.useState(false);
  React.useEffect(() => {}, [selected, handleRowCheck]);
  return (
    <>
      <TableRow key={`row-${role.Id}`}>
        <TableCell>
          <Checkbox
            value={role.Id}
            label=""
            checked={selected}
            onChange={() => handleRowCheck(index, role.Id)}
          />
        </TableCell>
        <TableCell title="Lista de roles">{role.Name}</TableCell>
        <TableCell title="Descripción">{role.Description}</TableCell>
        <TableCell title="Permisos">+{role.Permissions.length}</TableCell>
        <TableCell title="Usuarios">+{role.Users.length}</TableCell>
        <TableCell />
        <TableCell>
          <div
            role="button"
            tabIndex={-1}
            onKeyDown={() => {
              setOpenEdit(true);
            }}
            className="table__cell-delete"
            onClick={() => {
              setOpenEdit(true);
            }}
          >
            <span className="ticon-edit" />
            Editar
          </div>
        </TableCell>
        <TableCell>
          <div
            role="button"
            tabIndex={-1}
            onKeyDown={() => {
              setRoleToDelete({ id: role.Id, name: role.Name });
              setShowDeleteModal(true);
            }}
            className="table__cell-delete"
            onClick={() => {
              setRoleToDelete({ id: role.Id, name: role.Name });
              setShowDeleteModal(true);
            }}
          >
            <span className="ticon-delete" />
            Eliminar
          </div>
        </TableCell>
      </TableRow>
      {openEdit && (
        <RoleEditRowForm
          key={`edit-${role.Id}`}
          role={role}
          setShowEdit={setOpenEdit}
          appId={appId}
          setNotification={setNotification}
          reload={reload}
        />
      )}
    </>
  );
};

export default RoleEditRow;
