import * as React from "react";
import styled from "styled-components";
import { Surface } from "@tanner/partner";
import ItemCreatedCard from "../ItemCreatedCard";
import { PieChartColors } from "../../../utils/enums";
import useFetch from "../../../hooks/useFetch";
import {
  getApplications,
  getAllPermissionSummary,
  getAllRolesSummary,
  getAllUsersAuth0,
} from "../../../api";
import {
  ApplicationsResume,
  AllPermissionSummary,
  UserApplicationRoles,
  ListUserAuth0,
} from "../../../types";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 24px;
  row-gap: 13px;
  width: 100%;

  .dashboard-total-created-surfaces {
    width: 318px;
    height: 140px;
    margin: 0 !important;
  }

  @media only screen and (min-width: 1240px) and (max-width: 1440px) {
    .dashboard-total-created-surfaces {
      width: 317px;
    }
  }
  @media only screen and (min-width: 1620px) {
    .dashboard-total-created-surfaces {
      width: 385px;
      height: 174px;
    }
`;

const TotalCreatedGrid = () => {
  const { status: appStatus, data: apps } = useFetch<ApplicationsResume>(getApplications(1, 1));
  const { status: permissionStatus, data: permission } = useFetch<AllPermissionSummary>(
    getAllPermissionSummary(1, 1)
  );
  const { status: rolesStatus, data: roles } = useFetch<UserApplicationRoles>(
    getAllRolesSummary(1, 1)
  );
  const { status: userStatus, data: users } = useFetch<ListUserAuth0>(getAllUsersAuth0(1, 1));

  return (
    <Wrapper>
      <Surface shadow={1} radius="medium" className="dashboard-total-created-surfaces">
        <ItemCreatedCard
          subtitle="Aplicaciones creadas"
          total={apps?.TotalRecords ?? 0}
          color={PieChartColors.Applications}
          isLoading={appStatus === "fetching"}
        />
      </Surface>
      <Surface shadow={1} radius="medium" className="dashboard-total-created-surfaces">
        <ItemCreatedCard
          subtitle="Roles creados"
          total={roles?.TotalRecords ?? 0}
          color={PieChartColors.Roles}
          isLoading={rolesStatus === "fetching"}
        />
      </Surface>
      <Surface shadow={1} radius="medium" className="dashboard-total-created-surfaces">
        <ItemCreatedCard
          subtitle="Permisos creados"
          total={permission?.TotalRecords ?? 0}
          color={PieChartColors.Permissions}
          isLoading={permissionStatus === "fetching"}
        />
      </Surface>
      <Surface shadow={1} radius="medium" className="dashboard-total-created-surfaces">
        <ItemCreatedCard
          subtitle="Usuarios creados"
          total={users?.TotalRecords ?? 0}
          color={PieChartColors.Users}
          isLoading={userStatus === "fetching"}
        />
      </Surface>
    </Wrapper>
  );
};

export default TotalCreatedGrid;
