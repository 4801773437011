import { sanitizeIdForQuery } from "../utils/string";

export const getAppsExistsUser = (userId: string) => {
  return `/user/getappsexistsuser/${userId}`;
};

export const getAllUsersAuth0 = (pageNumber: number, pageSize: number) => {
  return `/user/auth0?pagenumber=${pageNumber}&pagesize=${pageSize}`;
};

export const getAllUsersByName = (userName: string, pageNumber: number, pageSize: number) => {
  return `/user/auth0/search?pagenumber=${pageNumber}&pagesize=${pageSize}&q=name:${userName}*`;
};

export const getAllUsersByApplication = (
  applicationId: string,
  pageNumber: number,
  pageSize: number
) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `user/allbyapplication/${idCleaned}?pagenumber=${pageNumber}&pagesize=${pageSize}`;
};

export const updateUserAuth0 = (userId: string) => {
  return `user/updateuserauth0/${userId}`;
};

export const removeUserFromApplication = (applicationId: string, userId: string) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `user/removeapplication/${idCleaned}/${userId}`;
};

export const searchUserInApplication = (
  applicationId: string,
  username: string,
  pageNumber: number,
  pageSize: number
) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `user/searchinapplication/${idCleaned}?username=${username}&pagenumber=${pageNumber}&pagesize=${pageSize}`;
};

export const listUsersSummary = (pageNumber: number, pageSize: number) => {
  return `user/summary?pagenumber=${pageNumber}&pagesize=${pageSize}`;
};

export const listApplicationsByUser = (userId: string, pageNumber: number, pageSize: number) => {
  return `user/listapplications/${userId}?pagenumber=${pageNumber}&pagesize=${pageSize}`;
};

export const deleteUseraAth0 = (userId: string) => {
  return `user/deleteuserauth0/${userId}`;
};

export const addNewUserAuth0 = () => {
  return `/user/addnewuserauth0`;
};

export const listSearchUsersSummary = (search: string, pageNumber: number, pageSize: number) => {
  return `/user/searchsummary?search=${search}&pagenumber=${pageNumber}&pagesize=${pageSize}`;
};

export const getUser = (userId: string) => {
  return `/user/getuserauth0/${userId}`;
};

export const updateMetadataUserAuth0 = (userId: string) => {
  return `user/updateusermetadataauth0/${userId}`;
};
