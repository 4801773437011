import * as React from "react";
import { ErrorButton, Modal, SecondaryButton } from "@tanner/partner";
import styled from "styled-components";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { UserToRemove } from "../../../types";
import { api, deleteUseraAth0 } from "../../../api";
import { NotificationState } from "../../../hooks/useNotification";

const ModalStyled = styled(Modal)`
  padding-top: 25vh;

  div:first-child {
    margin-left: 0;
    margin-right: 0;
  }

  .modal-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .ticon-warning {
      font-size: 74px;
      color: #f48f00;
    }

    h4 {
      margin: 15px 0 10px 0;
      color: #005cb9;
    }

    p {
      color: #333333;
    }
  }

  div[class*="modal-title"] {
    height: 0 !important;
    margin: 0 !important;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    gap: 80px;
    width: 100%;
    margin-bottom: 10px;

    button {
      color: #005cb9;
    }

    button:first-child {
      background-color: transparent;
    }

    button:nth-child(2) {
      color: #fff;
      background-color: #f36963;
    }
  }
`;
const P1 = styled.p`
  margin-bottom: 0;
`;
const P2 = styled.p`
  padding: 0;
  margin: 0;
`;

type Props = {
  user: UserToRemove;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reload: () => void;
  setNotification: (message: string, type: NotificationState) => void;
  appId?: string;
};

type BodyProps = Pick<Props, "user">;

const Body: React.FC<BodyProps> = (props) => {
  const { user } = props;
  return (
    <div className="modal-body">
      <span className="ticon-warning" />
      <h4>Eliminar Usuario</h4>
      <P1>
        Se eliminará el Usuario: <strong> {user.name}</strong>
      </P1>
      <P2>({user.id})</P2>
      <p>¿Estás seguro de que deseas continuar?</p>
    </div>
  );
};

type FooterProps = {
  deleting: boolean;
  handleRemoveUser: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
} & Pick<Props, "setOpen">;

const Footer: React.FC<FooterProps> = (props) => {
  const { setOpen, handleRemoveUser, deleting } = props;
  return (
    <div className="modal-footer">
      <ErrorButton className="btn-cancel" onClick={() => setOpen(false)}>
        Cancelar
      </ErrorButton>
      <SecondaryButton onClick={handleRemoveUser}>
        {deleting && <span className="ticon-loading" />}
        Confirmar
      </SecondaryButton>
    </div>
  );
};

const ModalRemoveUser: React.FC<Props> = (props) => {
  const { open, setOpen, user, reload, setNotification } = props;
  const [deleting, setDeleting] = React.useState(false);

  const appInsights = useAppInsightsContext();

  const handleRemoveUser = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setDeleting(true);
    e.preventDefault();

    api
      .delete(deleteUseraAth0(user.id))
      .then(() => setNotification("Usuario eliminado correctamente", "success"))
      .catch((err) => {
        appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
        setNotification("Ocurrió un error al eliminar el usuario", "error");
      })
      .finally(() => {
        setDeleting(false);
        setOpen(false);
        reload();
      });
  };

  return (
    <ModalStyled
      isOpen={open}
      title=""
      size="md"
      content={<Body user={user} />}
      actions={<Footer setOpen={setOpen} handleRemoveUser={handleRemoveUser} deleting={deleting} />}
    />
  );
};

export default ModalRemoveUser;
