import { AppType } from "../utils/enums";

export type Option = { value: string; label: string };

export const optAppTypes: Option[] = [
  { value: AppType.RegularWeb, label: "Web regular" },
  { value: AppType.SPA, label: "SPA" },
  { value: AppType.NonInteractive, label: "No interactiva" },
  { value: AppType.Native, label: "Nativa" },
];

export const optAppTypesFromNumber: Record<string, Option> = {
  regular_web: { value: AppType.RegularWeb, label: "Web regular" },
  spa: { value: AppType.SPA, label: "SPA" },
  non_interactive: { value: AppType.NonInteractive, label: "No interactiva" },
  native: { value: AppType.Native, label: "Nativa" },
};
