import * as React from "react";
import styled from "styled-components";

import { Admin, Avatar, MobileCheck, Role } from "@tanner/icons";

import { PieChartColors } from "../../../utils/enums";
import ItemCreatedCardLoader from "../../loaders/ItemCreatedCardLoader";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 30px;
  overflow: hidden;

  svg {
    fill: ${(props) => props.color};
  }

  * {
    color: ${(props) => props.color};
  }

  .item-created-right-content {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    margin-left: 15px !important;
    width: 100%;
  }

  .item-created-title {
    font-size: 2.8em;
    margin-bottom: 6px;
    height: 39px;
  }

  .item-created-subtitle {
    color: #595959;
    height: 26px;
  }
`;

type IconLeftProp = Partial<Props>;

const IconLeft: React.FC<IconLeftProp> = ({ color }) => {
  const size = 100;
  if (color === PieChartColors.Permissions) {
    return <Admin size={size} />;
  }
  if (color === PieChartColors.Roles) {
    return <Role size={size} />;
  }
  if (color === PieChartColors.Users) {
    return <Avatar size={size} />;
  }
  return <MobileCheck size={size} />;
};

type Props = {
  color: PieChartColors;
  total: number;
  subtitle: string;
  isLoading?: boolean;
};

const ItemCreatedCard: React.FC<Props> = ({ color, total, subtitle, isLoading }) => {
  if (isLoading) {
    return <ItemCreatedCardLoader />;
  }

  return (
    <Wrapper color={color}>
      <IconLeft color={color} />
      <div className="item-created-right-content">
        <span className="item-created-title">{total}</span>
        <span className="item-created-subtitle">{subtitle}</span>
      </div>
    </Wrapper>
  );
};

export default ItemCreatedCard;
