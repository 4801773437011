import * as React from "react";
import { Grid, PrimaryButton, PrimaryText, Surface, TextField } from "@tanner/partner";
import styled from "styled-components";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { api, createRolApp } from "../../../api";
import { useForm } from "../../../hooks/useForm";
import { CreateRole, Option } from "../../../types";
import { NotificationState } from "../../../hooks/useNotification";

const WrapperSurface = styled(Surface)<{ error: boolean }>`
  height: 350px;
  justify-content: center;
  display: flex;
  margin: 0 0 15px 0;

  h2 {
    margin-bottom: 16px;
  }

  .grid-center {
    justify-content: center;
    display: flex;
  }
  .grid-container {
    width: 400px;
  }
  .header-box-btn {
    flex-direction: row;
    justify-content: space-between;

    button {
      height: 44px;
      width: 180px;
      justify-content: center;
    }

    .btn-cancel {
      color: #005cb9;
      background-color: transparent;
      border: 1px solid #005cb9 !important;
    }
  }

  .input-w {
    height: 49px;
    width: 100%;
    border-radius: 4px;
  }
  .label-w {
    margin-bottom: ${(props) => (props.error ? "35px" : "0")};
    text-align: left;
    width: 100%;
  }
`;

interface Props {
  clickHandlerCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  setShowNewRole: (state: boolean) => void;
  selectApp: Option;
  setNotification: (message: string, type: NotificationState) => void;
  reload: () => void;
}

const initialForm = {
  role: "",
  description: "",
};

const RoleAdd: React.FC<Props> = ({
  clickHandlerCancel,
  setShowNewRole,
  selectApp,
  setNotification,
  reload,
}) => {
  const { value } = selectApp;
  const appInsights = useAppInsightsContext();

  const errorInit = {
    status: false,
    message: "",
  };

  const [errorRole, setErrorRole] = React.useState(errorInit);
  const [errorDescription, setErrorDescription] = React.useState(errorInit);

  const [isSubmitting, setSubmitting] = React.useState(false);

  const { role, description, onChange } = useForm(initialForm);

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();

    setErrorRole(errorInit);
    setErrorDescription(errorInit);

    if (role.trim().length < 5) {
      setErrorRole({
        status: true,
        message: "Ingresar un nombre para el rol",
      });
      return;
    }

    if (role.trim().length > 40) {
      setErrorRole({
        status: true,
        message: "El rol puede tener un máximo de 40 caracteres.",
      });
      return;
    }

    if (description.trim().length > 40) {
      setErrorDescription({
        status: true,
        message: "La descripción puede tener un máximo de 40 caracteres.",
      });
      return;
    }

    setSubmitting(true);
    const request: CreateRole = {
      name: role,
      description,
    };
    try {
      await api.post(createRolApp(`:${value}`), request);
      setSubmitting(false);
      setShowNewRole(false);
      setNotification("Rol creado correctamente", "success");
      reload();
    } catch (err) {
      setNotification("Ocurrió un problema creando el rol.", "error");
      setShowNewRole(false);
      setSubmitting(false);

      appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
    }
  };

  return (
    <WrapperSurface shadow={1} radius="medium" error={errorRole.status || errorDescription.status}>
      <Grid
        container
        sm={12}
        md={12}
        lg={12}
        center
        nomargin
        className="grid-center grid-container"
      >
        <Grid item sm={12} md={12} lg={12} nopadding>
          <PrimaryText type="h2">Ingresa información del nuevo Rol</PrimaryText>
        </Grid>
        <Grid item sm={12} md={12} lg={12} className="grid-center">
          <label htmlFor="rolName" className="label-w">
            Crear nuevo Rol
            <TextField
              className="input-w"
              id="role"
              placeholder="Ingresa el nombre del nuevo rol"
              value={role}
              onChange={({ target }) => onChange(target.value, "role")}
              error={errorRole.status}
              errorText={errorRole.message}
            />
          </label>
        </Grid>
        <Grid item sm={12} md={12} lg={12} className="grid-center">
          <label htmlFor="descriptionName" className="label-w">
            Descripción
            <TextField
              className="input-w"
              id="description"
              placeholder="Ingresa descripción del nuevo rol"
              value={description}
              onChange={({ target }) => onChange(target.value, "description")}
              error={errorDescription.status}
              errorText={errorDescription.message}
            />
          </label>
        </Grid>
        <Grid item sm={12} md={12} lg={12} className="grid-center header-box-btn">
          <PrimaryButton onClick={clickHandlerCancel} className="btn-cancel">
            Cancelar
          </PrimaryButton>
          <PrimaryButton onClick={handleSubmitForm} disabled={isSubmitting}>
            {isSubmitting && <span className="ticon-loading" />}
            Guardar
          </PrimaryButton>
        </Grid>
      </Grid>
    </WrapperSurface>
  );
};

export default RoleAdd;
