import * as React from "react";
import { ErrorButton, Modal, SecondaryButton } from "@tanner/partner";
import styled from "styled-components";
import { GenericConfirmationModalProps } from "../../../types";

const ModalStyled = styled(Modal)`
  padding-top: 25vh;

  div:first-child {
    margin-left: 0;
    margin-right: 0;
  }

  .modal-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .ticon-warning {
      font-size: 74px;
      color: #f48f00;
    }

    h4 {
      margin: 15px 0 10px 0;
      color: #005cb9;
    }

    p {
      color: #333333;
    }
  }

  div[class*="modal-title"] {
    height: 0 !important;
    margin: 0 !important;
  }import Header from './../../../layouts/Header/Header';


  .modal-footer {
    display: flex;
    justify-content: center;
    gap: 80px;
    width: 100%;
    margin-bottom: 10px;

    button {
      color: #005cb9;
    }

    button:first-child {
      background-color: transparent;
    }

    button:nth-child(2) {
      color: #fff;
      background-color: #f36963;
    }
  }
`;

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  processing: boolean;
  handleConfirmation: () => void;
  genericConfirmationModalProps: GenericConfirmationModalProps;
};

type BodyProps = {
  genericConfirmationModalProps: GenericConfirmationModalProps;
};

const Body: React.FC<BodyProps> = (props) => {
  const { genericConfirmationModalProps } = props;
  return (
    <div className="modal-body">
      <span className="ticon-warning" />
      <h4>{genericConfirmationModalProps.header}</h4>
      <p>{genericConfirmationModalProps.messageBody}</p>
      <p>{genericConfirmationModalProps.messageConfirm}</p>
    </div>
  );
};

type FooterProps = {
  processing: boolean;
  handleConfirmation: () => void;
  genericConfirmationModalProps: GenericConfirmationModalProps;
} & Pick<Props, "setOpen">;

const Footer: React.FC<FooterProps> = (props) => {
  const { setOpen, handleConfirmation, processing, genericConfirmationModalProps } = props;

  return (
    <div className="modal-footer">
      <ErrorButton className="btn-cancel" disabled={processing} onClick={() => setOpen(false)}>
        {genericConfirmationModalProps.buttonCancelText}
      </ErrorButton>
      <SecondaryButton
        onClick={(e) => {
          e.preventDefault();
          handleConfirmation();
        }}
        disabled={processing}
      >
        {processing && <span className="ticon-loading" />}
        {processing
          ? genericConfirmationModalProps.textProcessing
          : genericConfirmationModalProps.buttonConfirmText}
      </SecondaryButton>
    </div>
  );
};

const GenericConfirmationModal: React.FC<Props> = (props) => {
  const { open, setOpen, genericConfirmationModalProps, handleConfirmation, processing } = props;
  return (
    <ModalStyled
      isOpen={open}
      title=""
      size="md"
      content={<Body genericConfirmationModalProps={genericConfirmationModalProps} />}
      actions={
        <Footer
          setOpen={setOpen}
          handleConfirmation={handleConfirmation}
          processing={processing}
          genericConfirmationModalProps={genericConfirmationModalProps}
        />
      }
    />
  );
};

export default GenericConfirmationModal;
