import * as React from "react";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import styled from "styled-components";
import { Grid, PrimaryButton, Select, TextArea, TextField } from "@tanner/partner";
import * as Yup from "yup";
import _ from "lodash";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { ApplicationContext } from "../../../contexts/ApplicationContext";
import ApplicationRightContent from "../../../layouts/ApplicationRightContent";
import { RoutesPath } from "../../../utils/enums";
import { api, createApplication } from "../../../api";
import {
  ApplicationFormValues,
  CreateApplicationRequest,
  optAppTypes,
  Option,
  ErrorApi,
} from "../../../types";
import { extractUrlsSeparatedByCommas, isValidUrl } from "../../../utils/string";
import { NotificationState } from "../../../hooks/useNotification";

const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;

  .body__selector {
    margin-bottom: 25px;
  }

  span[class*="indicatorSeparator"] {
    visibility: hidden;
  }

  div[class*="ValueContainer"] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 100%;
    display: flex !important;
    align-items: center !important;
    position: center;
  }

  div[class*="singleValue"] {
    color: #595959;
    padding-top: 4px;
    padding-left: 5px;
  }

  .header {
    width: 100%;
    min-height: 89px;
    background-color: #f1e2f5;
    color: #3055bd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 31px 25px;

    .header-box-btn {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex: 1;

      button {
        width: 180px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }

      button + button {
        margin-left: 20px;
      }

      .btn-cancel {
        color: #005cb9;
        background-color: transparent;
        border: 1px solid #005cb9 !important;
      }
    }

    h2 {
      flex: 1;
      margin: 5px;
    }
  }

  .body {
    padding: 29px 26px;
    color: #afafaf;
    font-weight: 300;
    line-height: 22px;

    .body__checkbox {
      display: flex;
      align-items: flex-start;

      span {
        margin-left: 10px;
      }
    }

    p {
      margin: 0 0 25px;
      color: #595959;
    }

    .body__labelTitle {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-weight: 400;
      line-height: 21px;
      flex-wrap: wrap;

      span:nth-child(2) {
        color: #a3a3a3;
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
      }
    }

    input,
    textarea {
      padding: 11px 20px 11px 15px !important;
      margin: 0 !important;

      ::placeholder {
        color: #bcbcbc;
      }

      ::-ms-input-placeholder {
        color: #bcbcbc;
      }
    }
  }
`;

type FieldStyledProps = {
  error?: boolean;
};

const FieldStyled = styled(Field)<FieldStyledProps>`
  padding: 0;
  margin-bottom: ${(props) => (props.error ? "10px" : "25px")};
  margin-top: 4px;

  span {
    margin: 0;
    white-space: pre;
    height: 15px;
  }
`;

type Props = {
  setNotification: (message: string, type: NotificationState) => void;
} & RouteComponentProps;

const CreateApplicationPage: React.FC<Props> = (props) => {
  const { setNotification } = props;
  const history = useHistory();
  const [select, setSelect] = React.useState({} as Option);

  const appInsights = useAppInsightsContext();

  const { reload } = React.useContext(ApplicationContext);

  const handleSubmit = async (
    values: ApplicationFormValues,
    actions: FormikHelpers<ApplicationFormValues>
  ) => {
    const { setSubmitting } = actions;
    setSubmitting(true);

    const request: CreateApplicationRequest = {
      name: values.name,
      description: values.description,
      callbacks: values.callbacks.split(","),
      initiateloginuri: values.initiateloginuri,
      allowedorigins: values.allowedorigins.split(","),
      allowedlogouturls: values.allowedlogouturls.split(","),
      allowedclients: values.allowedclients.split(","),
      apptype: values.apptype,
      showinuserapplist: values.showinuserapplist,
    };
    try {
      const response = await api.post(createApplication(), request);
      setSubmitting(false);
      setNotification("Aplicación creada correctamente", "success");
      reload();
      history.push(`${RoutesPath.Applications}/:${response.data.Id}`);
    } catch (err) {
      const errorApi = err.response.data as ErrorApi;
      setNotification(`${errorApi.message} (ID:${errorApi.traceid})`, "error");
      setSubmitting(false);
      appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
    }
  };

  return (
    <ApplicationRightContent>
      <Formik
        initialValues={
          {
            name: "",
            description: "",
            callbacks: "",
            initiateloginuri: "",
            allowedorigins: "",
            allowedlogouturls: "",
            allowedclients: "",
            apptype: "",
            showinuserapplist: false,
          } as ApplicationFormValues
        }
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required("El nombre de la aplicación es requerido.")
            .max(30, "Longitud máxima 30 caracteres."),
          description: Yup.string().required("La descripción es requerida."),
          initiateloginuri: Yup.string()
            .required("La URL de inicio de sesión es requerida.")
            .test(
              "is-valid-url",
              "La URL de inicio de sesión debe ser con protocolo https y no incluir localhost.",
              (value) => value != null && value.startsWith("https") && !value.includes("localhost")
            ),
          callbacks: Yup.string()
            .required("Las URLs de callback son requeridas.")
            .test("is-valid-url", "Debe introducir urls válidas", (value: string | undefined) => {
              if (value == null) return false;
              const urls = extractUrlsSeparatedByCommas(value);
              return _.every(urls, (u) => isValidUrl(u));
            }),
          allowedorigins: Yup.string()
            .required("Las URLs de orígenes permitidos son requeridas.")
            .test("is-valid-url", "Debe introducir urls válidas", (value: string | undefined) => {
              if (value == null) return false;
              const urls = extractUrlsSeparatedByCommas(value);
              return _.every(urls, (u) => isValidUrl(u));
            }),
          allowedlogouturls: Yup.string()
            .required("Las URLs de cierre de sesión son requeridas.")
            .test("is-valid-url", "Debe introducir urls válidas", (value: string | undefined) => {
              if (value == null) return false;
              const urls = extractUrlsSeparatedByCommas(value);
              return _.every(urls, (u) => isValidUrl(u));
            }),
          allowedclients: Yup.string()
            .required("Las URLs de clientes permitidos son requeridas.")
            .test("is-valid-url", "Debe introducir urls válidas", (value: string | undefined) => {
              if (value == null) return false;
              const urls = extractUrlsSeparatedByCommas(value);
              return _.every(urls, (u) => isValidUrl(u));
            }),
          apptype: Yup.string().required("El tipo de aplicación es requerido"),
        })}
      >
        {(p: FormikProps<ApplicationFormValues>) => {
          const { values, touched, errors, handleChange, isSubmitting, setFieldValue } = p;
          return (
            <Wrapper>
              <Grid container md={12} className="header" nopadding nomargin>
                <Grid item md={6} sm={12} nopadding>
                  <h2>Crear nueva applicación</h2>
                </Grid>
                <Grid item md={6} sm={12} className="header-box-btn" nopadding>
                  <PrimaryButton
                    className="btn-cancel"
                    onClick={() => history.push(RoutesPath.Applications)}
                  >
                    Cancelar
                  </PrimaryButton>
                  <PrimaryButton disabled={isSubmitting}>
                    {isSubmitting && <span className="ticon-loading" />}
                    Guardar
                  </PrimaryButton>
                </Grid>
              </Grid>

              <Grid className="body" container sm={12}>
                <Grid md={6} sm={12} item>
                  <p>Detalle de información de la aplicación</p>

                  <label htmlFor="appName">
                    <span>Nombre aplicación</span>
                    <FieldStyled
                      as={TextField}
                      error={touched.name && errors.name}
                      value={values.name}
                      onChange={handleChange("name")}
                      name="appName"
                      placeholder="Ingresa el nombre de la aplicación"
                      errorText={errors.name}
                    />
                  </label>

                  <label htmlFor="description">
                    <div className="body__labelTitle">
                      <span>Descripción</span>
                      <span>Máximo 200 carácteres</span>
                    </div>
                    <FieldStyled
                      as={TextArea}
                      maxLength={200}
                      rows={4}
                      cols={50}
                      error={touched.description && errors.description}
                      value={values.description}
                      onChange={handleChange("description")}
                      name="description"
                      placeholder="Ingresa una descripción para la aplicación"
                      errorText={errors.description}
                    />
                  </label>

                  <div className="body__selector">
                    <label htmlFor="appType">
                      <span>Tipo de aplicación</span>
                      <FieldStyled
                        as={Select}
                        error={touched.apptype && errors.apptype}
                        errorText={errors.apptype}
                        name="appType"
                        placeholder="Selecciona Tipo de Aplicación"
                        value={select}
                        options={optAppTypes}
                        onChange={(opt: Option) => {
                          setFieldValue("apptype", opt.value);
                          setSelect(opt);
                        }}
                      />
                    </label>
                  </div>

                  <label htmlFor="urlLogin">
                    <div className="body__labelTitle">
                      <span>URL iniciar sesión (https)</span>
                      <span>Ej: https://example.com</span>
                    </div>
                    <FieldStyled
                      as={TextField}
                      error={touched.initiateloginuri && errors.initiateloginuri}
                      value={values.initiateloginuri}
                      onChange={handleChange("initiateloginuri")}
                      name="urlLogin"
                      placeholder="Ingresa URL de la aplicación"
                      errorText={errors.initiateloginuri}
                    />
                  </label>

                  <label htmlFor="showinuserapplist" className="body__checkbox">
                    <FieldStyled type="checkbox" name="showinuserapplist" />
                    <span>
                      ¿Deseas agregar esta aplicación en el listado de accesos del menú de usuario?
                    </span>
                  </label>
                </Grid>
                <Grid md={6} sm={12} item>
                  <p>URL&apos;s para los diferentes accesos</p>

                  <label htmlFor="callbacks">
                    <div className="body__labelTitle">
                      <span>URL callbacks</span>
                      <span>Ej: https://example.com</span>
                    </div>
                    <FieldStyled
                      as={TextField}
                      error={touched.callbacks && errors.callbacks}
                      value={values.callbacks}
                      onChange={handleChange("callbacks")}
                      name="callbacks"
                      placeholder="Ingresa URLs para callbacks"
                      errorText={errors.callbacks}
                    />
                  </label>

                  <label htmlFor="urlAllowedOrigins">
                    <div className="body__labelTitle">
                      <span>URL orígenes permitidos</span>
                      <span>Ej: https://example.com</span>
                    </div>
                    <FieldStyled
                      as={TextField}
                      error={touched.allowedorigins && errors.allowedorigins}
                      value={values.allowedorigins}
                      onChange={handleChange("allowedorigins")}
                      name="urlAllowedOrigins"
                      placeholder="Ingresa URLs para orígenes permitidos"
                      errorText={errors.allowedorigins}
                    />
                  </label>

                  <label htmlFor="urlAllowedClients">
                    <div className="body__labelTitle">
                      <span>URL clientes permitidos</span>
                      <span>Ej: https://example.com</span>
                    </div>
                    <FieldStyled
                      as={TextField}
                      error={touched.allowedclients && errors.allowedclients}
                      value={values.allowedclients}
                      onChange={handleChange("allowedclients")}
                      name="urlAllowedClients"
                      placeholder="Ingresa URL para clientes permitidos"
                      errorText={errors.allowedclients}
                    />
                  </label>

                  <label htmlFor="urlAllowedLogouts">
                    <div className="body__labelTitle">
                      <span>URL cierre de sesión permitidas</span>
                      <span>Ej: https://example.com</span>
                    </div>
                    <FieldStyled
                      as={TextField}
                      error={touched.allowedlogouturls && errors.allowedlogouturls}
                      value={values.allowedlogouturls}
                      onChange={handleChange("allowedlogouturls")}
                      name="urlAllowedLogouts"
                      placeholder="Ingresa URL para cierre de sesión permitidas"
                      errorText={errors.allowedlogouturls}
                    />
                  </label>
                </Grid>
              </Grid>
            </Wrapper>
          );
        }}
      </Formik>
    </ApplicationRightContent>
  );
};

export default CreateApplicationPage;
