import React from "react";
import ContentLoader from "react-content-loader";

const ApplicationsListLoader = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={300}
    viewBox="0 0 400 300"
    backgroundColor="#f7f7f7"
    foregroundColor="#ecebeb"
  >
    <rect x="25" y="32" rx="5" ry="5" width="277" height="19" />
    <rect x="25" y="89" rx="5" ry="5" width="222" height="16" />
    <rect x="25" y="155" rx="5" ry="5" width="220" height="2" />
    <rect x="25" y="140" rx="5" ry="5" width="273" height="16" />
    <rect x="25" y="195" rx="5" ry="5" width="244" height="16" />
  </ContentLoader>
);

export default ApplicationsListLoader;
