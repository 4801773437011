import { Order } from "../utils/enums";
import { sanitizeIdForQuery } from "../utils/string";

export const getApplications = (pageNumber: number, pageSize: number, order: Order = Order.ASC) => {
  return `/application?pagenumber=${pageNumber}&pagesize=${pageSize}&orden=${order}`;
};

export const createApplication = () => {
  return "/application";
};

export const getApplication = (id: string) => {
  const idSanitized = sanitizeIdForQuery(id);
  return `/application/${idSanitized}`;
};

export const deleteApplication = (id: string) => {
  const idSanitized = sanitizeIdForQuery(id);
  return `/application/${idSanitized}`;
};

export const editApplication = (id: string) => {
  const idSanitized = sanitizeIdForQuery(id);
  return `/application/${idSanitized}`;
};
