import * as React from "react";
import styled from "styled-components";
import {
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
} from "@tanner/partner";

import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { paginationProps } from "@tanner/partner/dist/core/pagination/_types";
import axios, { CancelTokenSource } from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import SearchInput from "../../../ui/SearchInput";
import ArpLightButton from "../../../ui/ArpLightButton";
import {
  CheckAppType,
  UserList,
  UserToRemove,
  GenericConfirmationModalProps,
} from "../../../types";
import Notification from "../../commons/Notification";
import GenericConfirmationModal from "../../commons/GenericConfirmationModal";
import useNotification from "../../../hooks/useNotification";
import RemoveUserToRoleModal from "../RemoveUserToRoleModal/RemoveUserToRoleModal";
import * as api from "../../../api";
import { getUserByRol, searchUserInRol, removeUserRol } from "../../../api";
import InputSearchUserForAddRole from "../InputSearchUserForAddRole/InputSearchUserForAddRole";
import PermissionsUserRoleTableLoader from "../../loaders/PermissionsUserRoleTableLoader";
import { not, numberOfChecked, union } from "../../../utils/math";
import { verifyCancelToken } from "../../../utils/http";
import logger from "../../../utils/logger";

const NotiGridStyled = styled(Grid)`
  width: 100%;
  background-color: #f8f9fd;

  .alert-height {
    height: 44px;
    padding: 0 15px;
  }
`;

const CreateStyled = styled(Grid)`
  width: 100%;
  height: 100px;
  background-color: #f8f9fd;
  padding: 22px 15px;

  input::placeholder {
    color: #828282;
  }

  input::-ms-input-placeholder {
    color: #828282;
  }

  .alert-height {
    height: 44px;
    padding: 0 15px;
  }

  .form {
    display: flex;
    flex-direction: column;

    label {
      color: #afafaf;

      div {
        padding-top: 4px;
      }
    }
  }
`;

const TableRowStyled = styled.tr`
  padding: 1em;

  .content {
    border: 1px solid #ebebeb;
  }

  .header {
    background-color: #f1fae5;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8em;
  }

  .subheader {
    border-bottom: 1px solid #f7f7f7;
    padding: 0.8em;
  }

  h4 {
    color: #595959;
    font-weight: 800;
    margin: 0;
  }

  table {
    padding: 0.8em;

    thead tr {
      color: #afafaf;
      font-size: 14px;
      font-weight: 600;

      th:first-child {
        padding-left: 15px !important;
        width: 50px;
      }
    }

    tbody tr {
      border: 1px solid #ebebeb !important;
      background-color: #f7f7f7;
      height: 50px;
      border-radius: 4px;
      font-size: 14px;
      color: #595959;
      font-weight: 400 !important;

      td:first-child {
        padding-left: 15px !important;
        width: 50px;
      }

      td:nth-child(4) {
        width: 120px;
      }
    }
  }

  div.box-switch {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  div.table__cell-delete {
    color: #3055bd;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 25px;
    cursor: pointer;

    span {
      margin-right: 10px;
    }
  }
`;

const PaginationStyled = styled(Pagination)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type RowUsersRoleApplicationProp = {
  appId: string;
  appName: string;
  roleId: string;
  roleName: string;
  setCountUser: React.Dispatch<React.SetStateAction<number>>;
};

type ErrorApi = {
  id: string;
  message: string;
};

type ResponseApi = {
  code: number;
  message: string;
};

let source: CancelTokenSource;

const RowUsersRoleApplication: React.FC<RowUsersRoleApplicationProp> = ({
  appId,
  appName,
  roleId,
  roleName,
  setCountUser,
}) => {
  const [evtName, setEvtName] = React.useState<"writing" | "pagination" | undefined>();
  const [selected, setSelected] = React.useState<number[]>([]);
  const [positions] = React.useState<number[]>([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const [showRemoveModal, setShowRemoveModal] = React.useState(false);
  const [searchText, setSearchText] = React.useState<string>("");
  const [userToRemove, setUserToRemove] = React.useState<UserToRemove>({
    id: "",
    name: "",
    rut: "",
    rolId: "",
    rolName: "",
  });
  const [refresh, setRefresh] = React.useState(1);
  const [showForm, setShowForm] = React.useState(false);
  const {
    displayNotification,
    variantNotification,
    messageNotification,
    setNotification,
    setDisplayNotification,
  } = useNotification();
  const [userDetailStatus, setUserDetailStatus] = React.useState<string>("init");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [listUserDetail, setListUserDetail] = React.useState<UserList | undefined>();
  const [listCheckUsers, setListCheckUsers] = React.useState<CheckAppType[]>([]);
  const [isRemoving, setIsRemoving] = React.useState(false);
  const appInsights = useAppInsightsContext();
  const genericConfirmationModalProps: GenericConfirmationModalProps = {
    type: "delete",
    header: "Remover Usuarios",
    messageBody: "Se removerán los usuarios seleccionados en el listado",
    messageConfirm: "¿Estás seguro de que deseas continuar?",
    buttonConfirmText: "Remover usuarios",
    buttonCancelText: "Cancelar",
    textProcessing: "Removiendo",
  };
  const [showCancelAllModal, setShowCancelAllModal] = React.useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const handleToggleAll = () => () => {
    const tempList = listCheckUsers;
    if (numberOfChecked(selected, positions) === positions.length) {
      setSelected(not(selected, positions));
      /* eslint no-param-reassign: "error" */
      tempList.forEach((check) => {
        check.isChecked = false;
      });
    } else {
      /* eslint no-param-reassign: "error" */
      tempList.forEach((check) => {
        check.isChecked = true;
      });
      setSelected(union(selected, positions));
    }

    setListCheckUsers(tempList);
  };

  const reload = () => {
    setSelected([]);
    setRefresh(refresh + 1);
  };

  const updateCheckUser = (userId: string) => {
    const elementsIndex = listCheckUsers.findIndex((element) => element.value === userId);
    const newArray = [...listCheckUsers];

    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      isChecked: !newArray[elementsIndex].isChecked,
    };

    setListCheckUsers(newArray);
  };

  const handleRemoveUserSelected = async () => {
    setIsRemoving(true);

    const tempList = listCheckUsers;
    const ListPromise: Promise<ResponseApi | PromiseRejectedResult>[] = [];
    const token = await getAccessTokenSilently();

    tempList.forEach((check) => {
      if (check.isChecked) {
        const r = new Promise<ResponseApi | PromiseRejectedResult>((resolve, reject) => {
          const url = removeUserRol(appId, roleId, check.value);
          api
            .authorized(token)
            .delete(url)
            .then((response) => {
              resolve(response.data as ResponseApi);
            })
            .catch((err) => {
              reject(err);
            });
        });
        ListPromise.push(r);
      }
    });

    const data = await Promise.allSettled(ListPromise);

    const err = (data.find((res) => res.status === "rejected") as PromiseRejectedResult | undefined)
      ?.reason;

    if (err) {
      setIsRemoving(false);
      setSelected([]);
      reload();
      if (err.response.status === 409) {
        const errorApi = err.response.data as ErrorApi;
        setNotification(errorApi.message, "warning");
      } else {
        setNotification("Ocurrió un problema removiendo los usuarios.", "error");
        appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
      }
    } else {
      setSelected([]);
      setIsRemoving(false);
      setCountUser((p) => p - data.filter((res) => res.status !== "rejected").length);
      reload();
      setNotification("Usuarios removidos.", "success");
    }
    setShowCancelAllModal(false);
  };

  const handleRowCheck = (value: number, userId: string) => () => {
    const currentIndex = selected.indexOf(value);
    const newSelected = [...selected];

    updateCheckUser(userId);

    if (currentIndex === -1) {
      newSelected.push(value);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
  };

  React.useEffect(() => {
    setUserDetailStatus("fetching");
    let url = getUserByRol(appId, roleId, currentPage, 10);

    if (searchText.trim().length > 0 && evtName === "writing") {
      url = searchUserInRol(appId, roleId, searchText.trim(), 1, 10);
    }

    if (searchText.trim().length > 0 && evtName === "pagination") {
      url = searchUserInRol(appId, roleId, searchText.trim(), currentPage, 10);
    }

    source = verifyCancelToken(source);

    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.authorized(token).get(url, { cancelToken: source.token });

        setListUserDetail(response.data);
        setUserDetailStatus("fetched");
        setSelected([]);
        setListCheckUsers([]);
        (response.data as UserList).Data.map((u) =>
          setListCheckUsers((prevState) => [
            ...prevState,
            { value: u.Id, label: u.Name, isChecked: false },
          ])
        );
      } catch (err) {
        if (axios.isCancel(err)) {
          logger.info("Cancel request");
        } else {
          setUserDetailStatus("error");
          appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
        }
      }
    })();
  }, [
    evtName,
    searchText,
    getAccessTokenSilently,
    appId,
    roleId,
    appInsights,
    currentPage,
    refresh,
  ]);

  return (
    <>
      <TableRowStyled>
        <TableCell colspan={4}>
          <div className="content">
            <Grid container className="header" nomargin nopadding>
              <Grid item lg={8} sm={12} md={6}>
                <h4>Usuarios</h4>
              </Grid>
              <Grid item lg={4} sm={12} md={6}>
                <ArpLightButton
                  color="primary"
                  text="Asignar usuario"
                  icon="ticon-add"
                  onClick={() => {
                    setShowForm(!showForm);
                  }}
                />
              </Grid>
            </Grid>

            {displayNotification && (
              <NotiGridStyled container sm={12} md={12} lg={12} nopadding nomargin>
                <Grid item lg={12} nomargin nopadding>
                  <Notification
                    displayNotification={displayNotification}
                    variantNotification={variantNotification}
                    messageNotification={messageNotification}
                    setDisplayNotification={setDisplayNotification}
                  />
                </Grid>
              </NotiGridStyled>
            )}

            {showForm && (
              <CreateStyled container sm={12} md={12} lg={12} nopadding nomargin>
                <Grid item sm={6} lg={6} md={6} className="form">
                  <InputSearchUserForAddRole
                    appId={appId}
                    roleId={roleId}
                    setNotification={setNotification}
                    reload={reload}
                    setShowForm={setShowForm}
                    setCountUser={setCountUser}
                  />
                </Grid>
              </CreateStyled>
            )}
            <Grid container className="subheader" nomargin nopadding between>
              <Grid item lg={5} sm={12} md={12}>
                <SearchInput
                  id="input-search-usuarios"
                  placeholder="Buscar por nombre"
                  className="wrapper__search-btn"
                  onChange={(evt) => {
                    setSearchText(evt.currentTarget.value);
                    setCurrentPage(1);
                    setEvtName("writing");
                  }}
                  value={searchText}
                />
              </Grid>
              <Grid item lg={6} sm={12} md={12}>
                <ArpLightButton
                  color="primary"
                  text="Remover usuarios seleccionados"
                  disabled={selected.length === 0 || isRemoving}
                  icon={isRemoving ? "ticon-loading" : "ticon-error"}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowCancelAllModal(true);
                  }}
                />
              </Grid>
            </Grid>
            {userDetailStatus === "fetching" && <PermissionsUserRoleTableLoader />}
            {listUserDetail && userDetailStatus === "fetched" && (
              <Table borderless>
                <TableHead>
                  <TableRow>
                    <TableCell component="th">
                      <Checkbox
                        value="all"
                        label=""
                        onChange={handleToggleAll()}
                        checked={
                          numberOfChecked(selected, positions) === positions.length &&
                          positions.length !== 0
                        }
                        disabled={listUserDetail.Data.length === 0}
                      />
                    </TableCell>
                    <TableCell component="th">Seleccionar todos</TableCell>
                    <TableCell component="th" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listUserDetail?.Data.map((u, index) => (
                    <TableRow key={`user-${u.Id}`}>
                      <TableCell>
                        <Checkbox
                          value={u.Id}
                          label=""
                          checked={selected.indexOf(index) !== -1}
                          onChange={handleRowCheck(index, u.Id)}
                        />
                      </TableCell>
                      <TableCell>{u.Name}</TableCell>
                      <TableCell>{u.Email}</TableCell>
                      <TableCell>
                        <div
                          role="button"
                          tabIndex={-1}
                          onKeyDown={() => {}}
                          onClick={() => {
                            setUserToRemove({
                              id: u.Id,
                              name: u.Name,
                              rut: u.RUT,
                              rolId: roleId,
                              rolName: roleName,
                            });
                            setShowRemoveModal(true);
                          }}
                          className="table__cell-delete"
                        >
                          <span className="ticon-misuse" />
                          Remover
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  {listUserDetail.Data.length === 0 && (
                    <TableRow key={0}>
                      <TableCell />
                      <TableCell>No se encontraron usuarios.</TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
            {listUserDetail && userDetailStatus === "fetched" && (
              <PaginationStyled
                activePage={currentPage}
                totalPages={listUserDetail.TotalPages === 0 ? 1 : listUserDetail.TotalPages}
                clickAction={(data: paginationProps) => {
                  setCurrentPage(data.goTo ?? 1);
                  setEvtName("pagination");
                }}
                showFirstPage
                showLastPage
              />
            )}
          </div>
        </TableCell>
      </TableRowStyled>

      <RemoveUserToRoleModal
        user={userToRemove}
        appName={appName}
        open={showRemoveModal}
        setOpen={setShowRemoveModal}
        reload={reload}
        setNotification={setNotification}
        setCount={setCountUser}
      />

      <GenericConfirmationModal
        open={showCancelAllModal}
        setOpen={setShowCancelAllModal}
        processing={isRemoving}
        handleConfirmation={handleRemoveUserSelected}
        genericConfirmationModalProps={genericConfirmationModalProps}
      />
    </>
  );
};
export default RowUsersRoleApplication;
