import axios, { CancelTokenSource } from "axios";
import logger from "./logger";

// eslint-disable-next-line import/prefer-default-export
export const verifyCancelToken = (source: CancelTokenSource | undefined): CancelTokenSource => {
  if (typeof source !== typeof undefined) {
    (source as CancelTokenSource).cancel();
    logger.info({
      message: "Request canceled",
    });
  }
  return axios.CancelToken.source();
};
