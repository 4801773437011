import * as React from "react";
import DashboardPage from "../pages/Dashboard";
import ApplicationsPage from "../pages/Applications";
import { RoutesPath } from "../utils/enums";
import RolesPage from "../pages/Roles";
import PermissionsPage from "../pages/Permissions";
import UsersPage from "../pages/Users";
import ProfilePage from "../pages/Profile";
import NotFoundPage from "../pages/NotFound";

export type RouteConfig = {
  id: number;
  path: string[];
  component: React.FC;
  exact: boolean;
};

export const routesConfig: RouteConfig[] = [
  {
    id: 1,
    path: [RoutesPath.Home],
    component: DashboardPage,
    exact: true,
  },
  {
    id: 2,
    path: [RoutesPath.Applications],
    component: ApplicationsPage,
    exact: false,
  },
  {
    id: 3,
    path: [RoutesPath.Roles],
    component: RolesPage,
    exact: true,
  },
  {
    id: 4,
    path: [RoutesPath.Permissions],
    component: PermissionsPage,
    exact: true,
  },
  {
    id: 5,
    path: [RoutesPath.Users],
    component: UsersPage,
    exact: true,
  },
  {
    id: 6,
    path: [RoutesPath.Profile],
    component: ProfilePage,
    exact: true,
  },
  {
    id: 99,
    path: ["*"],
    component: NotFoundPage,
    exact: true,
  },
];
