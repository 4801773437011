import * as React from "react";
import styled from "styled-components";

import { Avatar } from "@tanner/icons";

import UserInfoCardLoader from "../../loaders/UserInfoCardLoader";
import { PieChartColors } from "../../../utils/enums";

type Props = {
  name: string;
  email: string;
  isLoading?: boolean;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 30px;
  overflow: hidden;

  svg {
    fill: ${(props) => props.color};
  }

  .item-created-right-content {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    margin-left: 15px !important;
    width: 100%;
  }

  .item-created-name {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
  }

  .item-created-email {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
  }
`;

const UserInfoCard: React.FC<Props> = ({ name, email, isLoading }) => {
  if (isLoading) {
    return <UserInfoCardLoader />;
  }

  return (
    <Wrapper color={PieChartColors.User}>
      <Avatar size={60} />
      <div className="item-created-right-content">
        <span className="item-created-name">{name}</span>
        <span className="item-created-email">{email}</span>
      </div>
    </Wrapper>
  );
};

export default UserInfoCard;
