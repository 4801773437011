import * as React from "react";
import { TableCell } from "@tanner/partner";
import styled from "styled-components";
import ContentRowRoleApplication from "./ContentRowRoleApplication";
import { RoleDetail, RoleToDelete } from "../../../types";

type TableRowStyledProp = {
  open: boolean;
};

type RowRoleApplicationProp = {
  roleFull: RoleDetail;
  appId: string;
  appName: string;
  setRoleToDelete: React.Dispatch<React.SetStateAction<RoleToDelete>>;
  setShowCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const TableRowStyled = styled.tr<TableRowStyledProp>`
  border: 1px solid #ebebeb !important;
  background-color: ${(props) => (props.open ? "#e6edf8" : "#f7f7f7")};
  height: 50px;
  border-radius: 4px;
  font-size: 14px;
  color: #595959;
  font-weight: 400 !important;

  span {
    margin-right: 5px;
    font-size: 18px;
  }

  div.box-center {
    display: flex;
    align-items: center;
  }

  .pointer {
    cursor: pointer;
  }

  td:first-child {
    padding-left: 15px !important;
  }

  td:nth-child(4) {
    width: 140px;
  }

  div.table__cell-delete {
    color: #3055bd;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 25px;
    cursor: pointer;

    span {
      margin-right: 10px;
    }
  }
`;

const RowRoleApplication: React.FC<RowRoleApplicationProp> = ({
  roleFull,
  appId,
  appName,
  setRoleToDelete,
  setShowCancelModal,
}) => {
  const [open, setOpen] = React.useState(false);
  const [countPerm, setCountPerm] = React.useState(roleFull?.Permissions.length);
  const [countUser, setCountUser] = React.useState(roleFull?.Users.length);
  return (
    <>
      <TableRowStyled open={open}>
        <TableCell>
          <div
            className="box-center pointer"
            role="button"
            tabIndex={-1}
            onKeyDown={() => {
              setOpen(!open);
            }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {!open ? (
              <span className="ticon-chevron-down" />
            ) : (
              <span className="ticon-chevron-up" />
            )}
            {roleFull?.Name}
          </div>
        </TableCell>
        <TableCell>+{countPerm}</TableCell>
        <TableCell>
          <div className="box-center">
            <span className="ticon-user" />+{countUser}
          </div>
        </TableCell>
        <TableCell>
          <div
            role="button"
            tabIndex={-1}
            onKeyDown={() => {}}
            onClick={() => {
              setRoleToDelete({ id: roleFull.Id, name: roleFull.Name });
              setShowCancelModal(true);
            }}
            className="table__cell-delete"
          >
            <span className="ticon-delete" />
            Eliminar rol
          </div>
        </TableCell>
      </TableRowStyled>
      {open && (
        <ContentRowRoleApplication
          appId={appId}
          appName={appName}
          roleId={roleFull.Id}
          roleName={roleFull.Name}
          setCountPerm={setCountPerm}
          setCountUser={setCountUser}
        />
      )}
    </>
  );
};

export default RowRoleApplication;
