import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@tanner/partner";
import styled from "styled-components";
import { paginationProps } from "@tanner/partner/dist/core/pagination/_types";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import axios, { CancelTokenSource } from "axios";
import ApplicationRightContent from "../../../layouts/ApplicationRightContent";
import HeaderApplications from "../../../components/applications/HeaderApplications";
import SearchInput from "../../../ui/SearchInput";
import CreatePermissionApplication from "../../../components/applications/CreatePermission";
import UploadFile from "../../../components/commons/UploadFile";
import CancelPermissionModal from "../../../components/applications/CancelPermissionModal";
import { PermissionToCancel, PermissionsApp, ApplicationDetail } from "../../../types";
import { MimeType } from "../../../utils/enums";
import SubheaderApp from "../../../components/applications/SubheaderApp";
import {
  getAllPermissionsByApp,
  getApplication,
  uploadAddPermissionFile,
  getAllPermissionsByAppByName,
} from "../../../api";
import * as api from "../../../api";
import useFetch from "../../../hooks/useFetch";
import { NotificationState } from "../../../hooks/useNotification";
import GenericTableLoader from "../../../components/loaders/GenericTableLoader";
import { verifyCancelToken } from "../../../utils/http";
import logger from "../../../utils/logger";

const MainStyled = styled.div`
  padding-bottom: 25px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Wrapper = styled(Grid)`
  padding: 0 25px 5px 25px;

  .wrapper__actions {
    display: flex;
    align-items: center;
    min-height: 90px;
    width: 100%;
    border-bottom: 1px solid #f7f7f7;

    .wrapper__actions-left {
      display: flex;
      justify-content: flex-start;

      input::-ms-input-placeholder {
        color: #afafaf !important;
      }

      input::placeholder {
        color: #afafaf !important;
      }
    }

    .wrapper__search-btn {
      width: 290px;
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0 10px;

    th {
      padding-bottom: 0 !important;
    }

    tbody {
      tr {
        border: 1px solid #ebebeb !important;
        background-color: #f7f7f7;
        height: 50px;
        border-radius: 4px;

        td:first-child {
          padding-left: 15px !important;
        }
      }
    }

    .table__cell-delete {
      color: #3055bd;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 25px;
      cursor: pointer;

      span {
        margin-right: 10px;
      }
    }
  }
`;

const PaginationStyled = styled(Pagination)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  setNotification: (message: string, type: NotificationState) => void;
} & RouteComponentProps;

let source: CancelTokenSource;

const PermissionsApplicationPage: React.FC<Props> = (props) => {
  const { match, setNotification } = props;
  const { appId } = match.params as { appId: string };
  const [evtName, setEvtName] = React.useState<"writing" | "pagination" | undefined>();
  const [showCreate, setShowCreate] = React.useState(false);
  const [showUpload, setShowUpload] = React.useState(false);
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [permissionToCancel, setPermissionToCancel] = React.useState<PermissionToCancel>({
    id: "",
    name: "",
  });

  const [currentPage, setCurrentPage] = React.useState(1);
  const [permissions, setPermissions] = React.useState<PermissionsApp | undefined>();
  const [permStatus, setPermStatus] = React.useState<string>("init");
  const [refresh, setRefresh] = React.useState(1);
  const [searchText, setSearchText] = React.useState<string>("");

  const appInsights = useAppInsightsContext();
  const { getAccessTokenSilently } = useAuth0();

  const reload = () => {
    setRefresh(refresh + 1);
  };

  React.useEffect(() => {
    let url = getAllPermissionsByApp(appId, currentPage, 10);
    if (searchText.trim().length > 0 && evtName === "writing") {
      url = getAllPermissionsByAppByName(appId, searchText.trim(), 1, 10);
    }

    if (searchText.trim().length > 0 && evtName === "pagination") {
      url = getAllPermissionsByAppByName(appId, searchText.trim(), currentPage, 10);
    }

    setPermStatus("fetching");
    source = verifyCancelToken(source);

    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.authorized(token).get(url, { cancelToken: source.token });

        setPermissions(response.data);
        setPermStatus("fetched");
      } catch (err) {
        if (axios.isCancel(err)) {
          logger.info("Cancel request");
        } else {
          setPermStatus("error");
          appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
          setNotification("Ocurrió un error obteniendo los permisos", "error");
        }
      }
    })();
  }, [
    evtName,
    getAccessTokenSilently,
    appId,
    appInsights,
    currentPage,
    refresh,
    setNotification,
    searchText,
  ]);

  const { data: app } = useFetch<ApplicationDetail | undefined>(getApplication(appId));

  return (
    <ApplicationRightContent>
      <MainStyled>
        <HeaderApplications active="permisos" appId={appId} />

        <SubheaderApp
          appName={app?.Name ?? ""}
          appId={appId}
          setShowUpload={setShowUpload}
          setShowCreate={setShowCreate}
          setNotification={setNotification}
        />

        {showUpload && (
          <UploadFile
            url={uploadAddPermissionFile(appId)}
            btnText="Carga lista de permisos"
            filesAccept={[MimeType.ApplicationExcel]}
            setShowUpload={setShowUpload}
            setNotification={setNotification}
          />
        )}

        {showCreate && (
          <CreatePermissionApplication
            setShowCreate={setShowCreate}
            reload={reload}
            appId={appId}
            setNotification={setNotification}
          />
        )}

        <Wrapper>
          <Grid container sm={12} md={12} lg={12} nopadding nomargin className="wrapper__actions">
            <Grid item md={6} nopadding className="wrapper__actions-left">
              <SearchInput
                id="input-search-permissions"
                placeholder="Buscar por nombre"
                className="wrapper__search-btn"
                onChange={(evt) => {
                  setSearchText(evt.currentTarget.value);
                  setCurrentPage(1);
                  setEvtName("writing");
                }}
                value={searchText}
              />
            </Grid>
          </Grid>

          {permStatus === "fetching" && <GenericTableLoader height={600} width={800} />}
          {permissions && permStatus === "fetched" && (
            <Table borderless>
              <TableHead>
                <TableRow>
                  <TableCell component="th">Lista de permisos</TableCell>
                  <TableCell component="th">Roles asignados</TableCell>
                  <TableCell component="th" />
                </TableRow>
              </TableHead>
              <TableBody>
                {permissions.Data.map((p) => (
                  <TableRow key={p.Id}>
                    <TableCell title="Lista de permisos">{p.Action}</TableCell>
                    <TableCell title="Roles asignados">
                      {p.UsedCounter > 0 ? `+${p.UsedCounter}` : p.UsedCounter}{" "}
                    </TableCell>
                    <TableCell>
                      <div
                        role="button"
                        tabIndex={-1}
                        onKeyDown={() => {
                          setPermissionToCancel({ id: p.Id, name: p.Action });
                          setShowCancelModal(true);
                        }}
                        className="table__cell-delete"
                        onClick={() => {
                          setPermissionToCancel({ id: p.Id, name: p.Action });
                          setShowCancelModal(true);
                        }}
                      >
                        <span className="ticon-error" />
                        Anular permiso
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
                {permissions && permStatus === "fetched" && permissions.Data.length === 0 && (
                  <TableRow key={0}>
                    <TableCell title="Lista de permisos">
                      No existen permisos para esta aplicación
                    </TableCell>
                    <TableCell title="Roles asignados" />
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </Wrapper>

        {permissions && permStatus === "fetched" && (
          <PaginationStyled
            activePage={currentPage}
            totalPages={permissions.TotalPages === 0 ? 1 : permissions.TotalPages}
            clickAction={(data: paginationProps) => {
              setCurrentPage(data.goTo ?? 1);
              setEvtName("pagination");
            }}
            showFirstPage
            showLastPage
          />
        )}
      </MainStyled>

      <CancelPermissionModal
        permission={permissionToCancel}
        open={showCancelModal}
        setOpen={setShowCancelModal}
        reload={reload}
        setNotification={setNotification}
      />
    </ApplicationRightContent>
  );
};

export default PermissionsApplicationPage;
