import React from "react";
import styled from "styled-components";
import { SecondaryButton } from "@tanner/partner";
import { FallbackProps } from "react-error-boundary";
import { useHistory } from "react-router-dom";
import ArpLightButton from "../../ui/ArpLightButton";
import broken from "../../assets/images/broken.svg";
import logo from "../../assets/images/logo.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 100vh;
  align-items: center;
  flex-direction: column;

  .image-box {
    margin: 1em;
    width: 250px;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 1.5em;
    background-color: #fff;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    height: 400px;
    width: 600px;

    img {
      object-fit: none;
      height: 170px;
    }

    h1 {
      color: #c9c9c9;
      font-size: 4.5em !important;
      margin: 0.1em;
    }

    h3 {
      color: #1569c4;
      font-size: 1.5em;
      margin: 0;
    }

    .text {
      display: flex;
      justify-content: center;
      padding: 1em 3em;
      p {
        margin: 0;
      }
    }

    .btn-group {
      display: flex;
      gap: 1em;

      button {
        width: 170px;
      }
    }
  }
`;

const ErrorPage: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const history = useHistory();

  return (
    <Wrapper>
      <div className="image-box">
        <img src={logo} alt="Tanner" />
      </div>
      <div className="box">
        <img src={broken} alt="Error" />
        <h3>Ups! Algo salió mal.</h3>
        <div className="text">
          <p>
            No pudimos cargar esta información, puedes volver a intentarlo o comunícate con soporte.
          </p>
        </div>
        <div className="btn-group">
          {error.message.includes("autenticación") ? (
            <p>sds</p>
          ) : (
            <>
              <SecondaryButton
                onClick={() => {
                  history.goBack();
                  resetErrorBoundary();
                }}
              >
                <span className="ticon-chevron-left" />
                Volver atrás
              </SecondaryButton>
              <ArpLightButton
                color="primary"
                text="Ir a inicio"
                icon="ticon-home"
                onClick={() => {
                  history.replace("/");
                  resetErrorBoundary();
                }}
              />
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default ErrorPage;
