import * as React from "react";
import { Grid } from "@tanner/partner";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { RoutesPath } from "../../../utils/enums";

const HeaderAppStyled = styled(Grid)`
  text-transform: uppercase;
  min-height: 72px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-left: 28px;
`;

const GridItemStyled = styled(Grid)<{ active: boolean }>`
  cursor: pointer;
  height: 50px;
  max-width: 186px;
  background-color: ${(props) => (props.active ? "#e6edf8" : "transparent")};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  color: #595959;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit !important; /* blue colors for links too */
  }
`;

type Props = {
  appId: string;
  active: "roles" | "permisos" | "usuarios" | "info";
};

const HeaderApplications: React.FC<Props> = ({ appId, active = "info" }) => {
  return (
    <HeaderAppStyled container sm={12} nopadding nomargin>
      <GridItemStyled item md={3} sm={6} active={active === "roles"}>
        <NavLink to={`${RoutesPath.Applications}/${appId}/roles`}>Roles</NavLink>
      </GridItemStyled>
      <GridItemStyled item md={3} sm={6} active={active === "permisos"}>
        <NavLink to={`${RoutesPath.Applications}/${appId}/permisos`}>Permisos</NavLink>
      </GridItemStyled>
      <GridItemStyled item md={3} sm={6} active={active === "usuarios"}>
        <NavLink to={`${RoutesPath.Applications}/${appId}/usuarios`}>Usuarios</NavLink>
      </GridItemStyled>
      <GridItemStyled item md={3} sm={6} active={active === "info"}>
        {active !== "info" ? (
          <NavLink to={`${RoutesPath.Applications}/${appId}`}>Info. General</NavLink>
        ) : (
          "Info. General"
        )}
      </GridItemStyled>
    </HeaderAppStyled>
  );
};

export default HeaderApplications;
