import * as React from "react";
import { Grid, Surface } from "@tanner/partner";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import styled from "styled-components";
import ApplicationsList from "../../../components/applications/ApplicationsList";
import ApplicationRightContent from "../../../layouts/ApplicationRightContent";
import { ApplicationsRoutePath } from "../../../utils/enums";
import CreateApplicationPage from "../Create";
import InfoApplicationPage from "../Info";
import UsersApplicationPage from "../Users";
import RolesApplicationPage from "../Roles";
import PermissionsApplicationPage from "../Permissions";

import Notification from "../../../components/commons/Notification";
import useNotification from "../../../hooks/useNotification";
import { ApplicationProvider } from "../../../contexts/ApplicationContext";

const Wrapper = styled(Surface)`
  padding: 0;
`;

const ApplicationMainPage: React.FC = () => {
  const {
    displayNotification,
    variantNotification,
    messageNotification,
    setNotification,
    setDisplayNotification,
  } = useNotification();
  return (
    <Wrapper shadow={1} radius="medium" nomargin>
      <ApplicationProvider>
        <Grid container nomargin nopadding>
          <Grid item lg={4} md={5} nopadding>
            <ApplicationsList />
          </Grid>
          <Grid item lg={8} md={7} nopadding>
            <ApplicationRightContent>
              <Notification
                displayNotification={displayNotification}
                variantNotification={variantNotification}
                messageNotification={messageNotification}
                setDisplayNotification={setDisplayNotification}
              />
              <Switch>
                <Route
                  path={ApplicationsRoutePath.Create}
                  component={(props: RouteComponentProps) => (
                    <CreateApplicationPage
                      match={props.match}
                      history={props.history}
                      location={props.location}
                      setNotification={setNotification}
                    />
                  )}
                  exact
                />
                <Route
                  path={ApplicationsRoutePath.Info}
                  component={(props: RouteComponentProps) => (
                    <InfoApplicationPage
                      match={props.match}
                      history={props.history}
                      location={props.location}
                      setNotification={setNotification}
                    />
                  )}
                  exact
                />
                <Route
                  path={ApplicationsRoutePath.Users}
                  component={(props: RouteComponentProps) => (
                    <UsersApplicationPage
                      match={props.match}
                      history={props.history}
                      location={props.location}
                      setNotification={setNotification}
                    />
                  )}
                  exact
                />
                <Route
                  path={ApplicationsRoutePath.Roles}
                  component={(props: RouteComponentProps) => (
                    <RolesApplicationPage
                      match={props.match}
                      history={props.history}
                      location={props.location}
                      setNotification={setNotification}
                    />
                  )}
                  exact
                />
                <Route
                  path={ApplicationsRoutePath.Permissions}
                  component={(props: RouteComponentProps) => (
                    <PermissionsApplicationPage
                      match={props.match}
                      history={props.history}
                      location={props.location}
                      setNotification={setNotification}
                    />
                  )}
                  exact
                />
              </Switch>
            </ApplicationRightContent>
          </Grid>
        </Grid>
      </ApplicationProvider>
    </Wrapper>
  );
};

export default ApplicationMainPage;
