const hostname = window && window.location && window.location.hostname;

type AuthConfig = {
  domain: string;
  clientId: string;
  audience: string;
};

type Configuration = {
  baseUrl: string;
  apiVersion: string;
  subscriptionKey: string;
  instrumentationKey: string;
  auth: AuthConfig;
};

const config: Configuration = {
  baseUrl: "https://aks-prod.tanner.cl/corporate/rolepermission/api/",
  apiVersion: "1.0",
  subscriptionKey: "",
  instrumentationKey: "c5848d8a-c20c-4ea8-8700-4fdf1fe29869",
  auth: {
    domain: "tanner-prod.us.auth0.com",
    clientId: "HzVlXV6XOjTWdKHBADmrXrqaM0RO5AgX",
    audience: "https://rolepermission.tanner.cl/api/",
  },
};

switch (true) {
  case hostname.includes("localhost"):
    config.baseUrl = "https://localhost:5001/corporate/rolepermission/api/";
    config.apiVersion = "1.0";
    config.subscriptionKey = "";
    config.instrumentationKey = "370cd63b-f06f-4f17-8b0d-148a8dffd612";
    config.auth.domain = "tanner-dev.us.auth0.com";
    config.auth.clientId = "C3cEuKs93syu9xh9LXV0JgQOikJ0LkgC";
    config.auth.audience = "https://rolepermission-dev.tanner.cl/api/";
    break;
  case hostname.includes("dev"):
    config.baseUrl = "https://apimtanner-dev.azure-api.net/arp/";
    config.apiVersion = "1.0";
    config.subscriptionKey = "f5437f38d1494e568d63222addb653ea";
    config.instrumentationKey = "370cd63b-f06f-4f17-8b0d-148a8dffd612";
    config.auth.domain = "tanner-dev.us.auth0.com";
    config.auth.clientId = "C3cEuKs93syu9xh9LXV0JgQOikJ0LkgC";
    config.auth.audience = "https://rolepermission-dev.tanner.cl/api/";
    break;
  case hostname.includes("qa"):
    config.baseUrl = "https://apimtanner-qa.azure-api.net/arp/";
    config.apiVersion = "1.0";
    config.subscriptionKey = "e58290578e0b4bfe82f0dec68895ba2a";
    config.instrumentationKey = "b435863f-0536-418e-999e-90201a7859c8";
    config.auth.domain = "tanner-qa.us.auth0.com";
    config.auth.clientId = "g6g22Ac7X9D3HIIX4vaFZV9DpZ3D3vL5";
    config.auth.audience = "https://rolepermission-qa.tanner.cl/api/";
    break;
  default:
    break;
}

export default config;
