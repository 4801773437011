import * as React from "react";
import styled from "styled-components";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Grid, PrimaryButton, TableCell, TextField } from "@tanner/partner";
import { PermissionDetail } from "../../../types";
import { api, updatePermission } from "../../../api";
import { NotificationState } from "../../../hooks/useNotification";

const TableRowStyled = styled.tr`
  background-color: #f8f9fd;
  .title {
    font-size: 16px;
  }

  .header-box-btn {
    flex-direction: row;

    button {
      height: 44px;
      width: 180px;
      justify-content: center;
      margin: 0 25px 0 0;
    }

    .btn-cancel {
      color: #005cb9;
      background-color: transparent;
      border: 1px solid #005cb9 !important;
    }
  }

  .grid-center {
    display: flex;
    justify-content: flex-start;
  }

  .body {
    max-height: 350px;
    padding: 0.8em;

    .form-user {
      padding-right: 1.5em;

      label {
        font-size: 14px;
      }

      input,
      textarea {
        width: 386px;
        padding: 11px 20px 11px 15px !important;
        margin: 0 !important;

        ::placeholder {
          color: #bcbcbc;
        }

        ::-ms-input-placeholder {
          color: #bcbcbc;
        }
      }
    }
  }
`;

type PropsRowEdit = {
  permission: PermissionDetail;
  appId: string;
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setNotification: (message: string, type: NotificationState) => void;
  reload: () => void;
};

type ErrorApi = {
  id: string;
  message: string;
};

const PermissionEditRowForm: React.FC<PropsRowEdit> = ({
  permission,
  appId,
  setShowEdit,
  setNotification,
  reload,
}) => {
  const errorInit = {
    status: false,
    message: "",
  };
  const [errorPermission, setErrorPermission] = React.useState(errorInit);
  const [errorDescription, setErrorDescription] = React.useState(errorInit);
  const [isSaving, setIsSaving] = React.useState(false);
  const [permissionName, setPermissionName] = React.useState("");
  const [permissionDescription, setPermissionDescription] = React.useState("");

  const appInsights = useAppInsightsContext();

  const handleEditPermission = async () => {
    setErrorPermission(errorInit);
    setErrorDescription(errorInit);

    if (permissionName.trim().length < 5) {
      setErrorPermission({
        status: true,
        message: "Ingresar un nombre para el permiso",
      });
      return;
    }

    if (permissionName.trim().length > 30) {
      setErrorPermission({
        status: true,
        message: "El permiso puede tener un máximo de 30 caracteres.",
      });
      return;
    }

    if (permissionDescription.trim().length > 50) {
      setErrorDescription({
        status: true,
        message: "La descripción puede tener un máximo de 50 caracteres.",
      });
      return;
    }

    setIsSaving(true);

    try {
      const request = {
        action: permissionName.trim(),
        description: permissionDescription.trim(),
      };
      await api.put(updatePermission(appId, permission.Id), request);
      reload();

      setNotification("Permiso actualizado correctamente.", "success");
    } catch (err) {
      if (err.response.status === 409) {
        const errorApi = err.response.data as ErrorApi;
        setNotification(errorApi.message, "error");
      } else {
        setNotification("Ocurrió un problema actualizado el permiso.", "error");
        appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
      }
    }
    setIsSaving(false);
    setShowEdit(false);
  };

  React.useEffect(() => {
    setPermissionName(permission.Action);
    setPermissionDescription(permission.Description == null ? "" : permission.Description);
  }, [permission]);

  return (
    <TableRowStyled>
      <TableCell colspan={6}>
        <div className="content">
          <Grid container nomargin nopadding lg={12} md={12} sm={12} className="body">
            <Grid item sm={12} md={12} lg={12}>
              <p className="title">Información relacionada al permiso</p>
            </Grid>
            <Grid item sm={12} md={12} lg={12} className="form-user">
              <label htmlFor="namePermissionInput">
                Nombre permiso
                <TextField
                  disabled={isSaving}
                  error={errorPermission.status}
                  errorText={errorPermission.message}
                  value={permissionName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPermissionName(e.currentTarget.value)
                  }
                />
              </label>
            </Grid>
            <Grid item sm={12} md={12} lg={12} className="form-user">
              <label htmlFor="nameDescriptionInput">
                Descripción
                <TextField
                  disabled={isSaving}
                  error={errorDescription.status}
                  errorText={errorDescription.message}
                  value={permissionDescription}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPermissionDescription(e.currentTarget.value)
                  }
                />
              </label>
            </Grid>
            <Grid item sm={12} md={12} lg={12} className="grid-center header-box-btn">
              <PrimaryButton
                className="btn-cancel"
                disabled={isSaving}
                onClick={(e) => {
                  e.preventDefault();
                  setShowEdit(false);
                }}
              >
                Cancelar
              </PrimaryButton>
              <PrimaryButton disabled={isSaving} onClick={handleEditPermission}>
                {isSaving && <span className="ticon-loading" />}
                Guardar
              </PrimaryButton>
            </Grid>
          </Grid>
        </div>
      </TableCell>
    </TableRowStyled>
  );
};

export default PermissionEditRowForm;
