import * as React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { ErrorBoundary } from "react-error-boundary";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { routesConfig } from "./routesConfig";
import ErrorPage from "../pages/Error";

const Routes = () => {
  const appInsights = useAppInsightsContext();
  const myErrorHandler = (err: Error, info: { componentStack: string }) => {
    appInsights.trackException({ exception: err, properties: { info: info.componentStack } });
  };

  return (
    <Router>
      <ErrorBoundary FallbackComponent={ErrorPage} onError={myErrorHandler}>
        <Switch>
          {routesConfig.map((route) => (
            <Route
              key={route.id}
              path={route.path}
              component={route.component}
              exact={route.exact}
            />
          ))}
        </Switch>
      </ErrorBoundary>
    </Router>
  );
};

export default Routes;
