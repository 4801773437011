import * as React from "react";
import { SecondaryButton } from "@tanner/partner";
import { useHistory } from "react-router-dom";
import { DocumentAdd } from "@tanner/icons";
import FullContainer from "../../layouts/FullContainer";

import { ApplicationsRoutePath } from "../../utils/enums";

import ApplicationMainPage from "./Main";

const AppUtilitiesBox: React.FC = () => {
  const history = useHistory();
  return (
    <>
      <SecondaryButton onClick={() => history.push(ApplicationsRoutePath.Create)}>
        <DocumentAdd />
        Crear aplicación
      </SecondaryButton>
    </>
  );
};

const ApplicationsPage: React.FC = () => {
  return (
    <FullContainer title="Asignación de roles y permisos" utilitiesBox={<AppUtilitiesBox />}>
      <ApplicationMainPage />
    </FullContainer>
  );
};

export default ApplicationsPage;
