import React from "react";
import { PermissionProvider } from "../../contexts/PermissionContext/PermissionContext";

import PermissionsMainPage from "./Main/PermissionsPageMain";

const PermissionsPage: React.FC = () => {
  return (
    <PermissionProvider>
      <PermissionsMainPage />
    </PermissionProvider>
  );
};

export default PermissionsPage;
