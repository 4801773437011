import * as React from "react";
import { AlertError, AlertSuccess, AlertWarning } from "@tanner/partner";
import { NotificationState } from "../../../hooks/useNotification";

type Props = {
  displayNotification: boolean;
  variantNotification: NotificationState;
  messageNotification: string;
  setDisplayNotification: React.Dispatch<React.SetStateAction<boolean>>;
};

const Notification: React.FC<Props> = (props) => {
  const {
    displayNotification,
    variantNotification,
    messageNotification,
    setDisplayNotification,
  } = props;

  React.useEffect(() => {
    if (displayNotification) {
      setTimeout(() => {
        setDisplayNotification(!displayNotification);
      }, 2000);
    }
  }, [setDisplayNotification, displayNotification]);

  return (
    <>
      {variantNotification === "success" && displayNotification && (
        <AlertSuccess close autoclose={1500}>
          {messageNotification}
        </AlertSuccess>
      )}
      {variantNotification === "error" && displayNotification && (
        <AlertError close autoclose={1500}>
          {messageNotification}
        </AlertError>
      )}
      {variantNotification === "warning" && displayNotification && (
        <AlertWarning close autoclose={1500}>
          {messageNotification}
        </AlertWarning>
      )}
    </>
  );
};

export default Notification;
