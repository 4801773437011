import * as React from "react";
import styled from "styled-components";
import { SecondaryButton } from "@tanner/partner";
import { useHistory } from "react-router-dom";
import ArpLightButton from "../../ui/ArpLightButton";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 1.5em;
    background-color: #fff;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    height: 400px;
    width: 600px;
    position: absolute;
    top: 100px;

    h1 {
      color: #c9c9c9;
      font-size: 4.5em !important;
      margin: 0.1em;
    }

    h3 {
      color: #1569c4;
      font-size: 1.5em;
      margin: 0;
    }

    p {
      margin: 0;
    }

    .btn-group {
      display: flex;
      gap: 1em;

      button {
        width: 170px;
      }
    }
  }
`;

const NotFoundPage: React.FC = () => {
  const history = useHistory();
  return (
    <Wrapper>
      <div className="box">
        <h1>404</h1>
        <h3>Ups! Esta página no existe.</h3>
        <p>La página que Ud está solicitando no existe, por favor verifique la URL.</p>
        <div className="btn-group">
          <SecondaryButton onClick={() => history.goBack()}>
            <span className="ticon-chevron-left" />
            Volver atrás
          </SecondaryButton>
          <ArpLightButton
            color="primary"
            text="Ir a inicio"
            icon="ticon-home"
            onClick={() => history.replace("/")}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default NotFoundPage;
