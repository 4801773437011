import * as React from "react";
import { Grid, PrimaryButton, PrimaryText, Surface, TextField } from "@tanner/partner";
import styled from "styled-components";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";

import { api, addNewUserAuth0 } from "../../../api";
import { CreateUser, ErrorApi } from "../../../types";
import { NotificationState } from "../../../hooks/useNotification";

const WrapperSurface = styled(Surface)`
  height: 426px;
  justify-content: center;
  display: flex;
  margin: 0 0 15px 0;

  .grid-no-padding {
    padding: 0;
  }
  .grid-center {
    justify-content: center;
    display: flex;
  }
  .grid-container {
    width: 400px;
  }
  .header-box-btn {
    flex-direction: row;
    justify-content: space-between;

    button {
      height: 44px;
      width: 180px;
      justify-content: center;
    }

    .btn-cancel {
      color: #005cb9;
      background-color: transparent;
      border: 1px solid #005cb9 !important;
    }
  }

  .input-w {
    height: 49px;
    width: 100%;
    border-radius: 4px;
  }
  .label-w {
    text-align: left;
    width: 100%;
  }
`;

type FieldStyledProps = {
  error?: boolean;
};

const FieldStyled = styled(Field)<FieldStyledProps>`
  padding: 0;
  margin-bottom: ${(props) => (props.error ? "10px" : "25px")};
  margin-top: 4px;

  span {
    margin: 0;
    white-space: pre;
    height: 15px;
  }
`;

type EditUser = {
  rut: string;
  name: string;
  email: string;
};

interface Props {
  clickHandlerCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  setShowForm: (state: boolean) => void;
  setNotification: (message: string, type: NotificationState) => void;
  reload: () => void;
}

const FormUserAdd: React.FC<Props> = ({ setShowForm, setNotification, reload }) => {
  const appInsights = useAppInsightsContext();
  const [isSaving, setIsSaving] = React.useState(false);

  const handleEditUser = (values: CreateUser, action: FormikHelpers<CreateUser>) => {
    const { setSubmitting } = action;
    setSubmitting(true);
    setIsSaving(true);
    const request: EditUser = {
      rut: values.rut,
      name: values.name,
      email: values.email,
    };

    api
      .post(addNewUserAuth0(), request)
      .then(() => {
        setNotification("Usuario creado correctamente", "success");
      })
      .catch((err) => {
        if (err.response.status === 409) {
          const errorApi = err.response.data as ErrorApi;
          setNotification(errorApi.message, "error");
        } else {
          setNotification("Ocurrió un problema creando el usuario.", "error");
          appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
        }
      })
      .finally(() => {
        setIsSaving(false);
        setSubmitting(false);
        setShowForm(false);
        reload();
      });
  };

  return (
    <WrapperSurface shadow={1} radius="medium">
      <Grid container sm={12} md={12} lg={12} center className="grid-center grid-container">
        <Grid item sm={12} md={12} lg={12} className="grid-no-padding">
          <PrimaryText type="h2">Ingresa información del nuevo Usuario</PrimaryText>
        </Grid>
        <Grid item sm={12} md={12} lg={12} className="grid-center">
          <Formik
            enableReinitialize
            initialValues={{ name: "", rut: "", email: "" } as CreateUser}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("El nombre del usuario es requerido."),
              email: Yup.string()
                .required("El correo electrónico del usuario es requerido.")
                .email("El correo electrónico del usuario tiene un formato inválido"),
              rut: Yup.string()
                .required("El RUT del usuario es requerido")
                .max(10, "Largo máximo 10 caracteres.")
                .rut("Debe ingresar un RUT válido."),
            })}
            onSubmit={handleEditUser}
          >
            {(formikProp: FormikProps<CreateUser>) => {
              const { isSubmitting, values, touched, handleChange, errors, resetForm } = formikProp;
              return (
                <Form className="content">
                  <Grid container nomargin nopadding sm={12} md={12} lg={12} className="body">
                    <Grid
                      item
                      sm={12}
                      md={12}
                      lg={12}
                      nomargin
                      nopadding
                      className="grid-center form-user"
                    >
                      <label htmlFor="rut" className="label-w">
                        <span>RUT del usuario</span>
                        <FieldStyled
                          as={TextField}
                          value={values.rut}
                          name="rut"
                          readOnly={isSaving}
                          onChange={handleChange("rut")}
                          error={touched.rut && errors.rut}
                          errorText={errors.rut}
                          placeholder="Ingresa el RUT del usuario. (ej. 15896325-1)"
                        />
                      </label>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      md={12}
                      lg={12}
                      nomargin
                      nopadding
                      className="grid-center form-user"
                    >
                      <label htmlFor="name" className="label-w">
                        <span>Nombre usuario</span>
                        <FieldStyled
                          as={TextField}
                          value={values.name}
                          readOnly={isSaving}
                          name="name"
                          onChange={handleChange("name")}
                          error={touched.name && errors.name}
                          errorText={errors.name}
                          placeholder="Ingresa el nombre de usuario"
                        />
                      </label>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      md={12}
                      lg={12}
                      nomargin
                      nopadding
                      className="grid-center form-user"
                    >
                      <label htmlFor="email" className="label-w">
                        <span>Correo electrónico usuario</span>
                        <FieldStyled
                          as={TextField}
                          value={values.email}
                          name="email"
                          readOnly={isSaving}
                          onChange={handleChange("email")}
                          error={touched.email && errors.email}
                          errorText={errors.email}
                          placeholder="Ingresa el correo electrónico"
                        />
                      </label>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      md={12}
                      lg={12}
                      nomargin
                      nopadding
                      className="grid-center header-box-btn"
                    >
                      <PrimaryButton
                        className="btn-cancel"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsSaving(false);
                          setShowForm(false);
                          resetForm();
                        }}
                      >
                        Cancelar
                      </PrimaryButton>
                      <PrimaryButton disabled={isSubmitting}>
                        {isSubmitting && <span className="ticon-loading" />}
                        Guardar
                      </PrimaryButton>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </WrapperSurface>
  );
};

export default FormUserAdd;
