import * as React from "react";
import styled from "styled-components";
import {
  Grid,
  PrimaryButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@tanner/partner";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import * as Yup from "yup";
import { CreateUser, User, UserToRemove } from "../../../types";
import { api, getRolesFromUser, updateUserAuth0 } from "../../../api";
import useNotification, { NotificationState } from "../../../hooks/useNotification";
import RemoveUserToRoleModal from "../RemoveUserToRoleModal/RemoveUserToRoleModal";
import Notification from "../../commons/Notification";
import GenericTableLoader from "../../loaders/GenericTableLoader";

const TableRowStyled = styled.tr`
  .content {
    border: 1px solid #ebebeb;
    border-radius: 4px;
  }

  .header {
    background-color: #fcfbfb;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: pre-wrap;
    word-break: break-word;
    padding-right: 1em;

    h5 {
      margin: 0;
      color: #005cb9;
      font-size: 14px;
      font-weight: 400;
    }

    span.ticon-edit {
      margin-right: 9px;
    }

    .header-box-btn {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 10px;

      button {
        width: 140px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }

      .btn-cancel {
        color: #005cb9;
        background-color: transparent;
        border: 1px solid #005cb9 !important;
      }
    }
  }

  .body {
    max-height: 350px;
    padding: 0.8em;

    .form-user {
      padding-right: 1.5em;

      input,
      textarea {
        padding: 11px 20px 11px 15px !important;
        margin: 0 !important;

        ::placeholder {
          color: #bcbcbc;
        }

        ::-ms-input-placeholder {
          color: #bcbcbc;
        }
      }
    }

    .roles-user {
      padding: 1em 1.5em 1.5em 1.5em;
      background-color: #fdfdfd;
      border-radius: 4px;
      border: 1px solid #ebebeb;
      max-height: 270px;

      .table-container {
        overflow-y: scroll;
        width: 100%;
        height: 90%;
        margin-top: 10px;
        padding-right: 5px;
      }

      h4 {
        margin: 0;
      }

      div.table__cell-delete {
        color: #3055bd;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 25px;
        cursor: pointer;

        span {
          margin-right: 10px;
        }
      }

      table {
        border-collapse: separate;
        border-spacing: 0 5px;
        overflow-y: scroll;

        th {
          padding-bottom: 0 !important;
          padding-top: 0 !important;
        }

        th:first-child {
          padding-left: 15px;
        }

        tbody tr {
          height: 30px !important;
          background-color: #fcfcfc;
        }

        tbody tr td {
          border-top: 1px solid #ebebeb;
          border-bottom: 1px solid #ebebeb;
        }

        tbody tr td:first-child {
          border-left: 1px solid #ebebeb;
          border-top: 1px solid #ebebeb;
          border-bottom: 1px solid #ebebeb;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        tbody tr td:last-child {
          border-right: 1px solid #ebebeb;
          border-top: 1px solid #ebebeb;
          border-bottom: 1px solid #ebebeb;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
`;

type ContentRowUserApplicationProp = {
  appId: string;
  appName: string;
  userData: User;
  setNotificationGlobal: (message: string, type: NotificationState) => void;
  reload: () => void;
  setRoleCount: React.Dispatch<React.SetStateAction<number>>;
};
type FieldStyledProps = {
  error?: boolean;
};

const FieldStyled = styled(Field)<FieldStyledProps>`
  padding: 0;
  margin-bottom: ${(props) => (props.error ? "10px" : "25px")};
  margin-top: 4px;

  span {
    margin: 0;
    white-space: pre;
    height: 15px;
  }
`;

type RolesUser = {
  Id: string;
  Name: string;
  RolePermissionCounter: number;
};

type EditUser = {
  rut: string;
  name: string;
  email: string;
};

const ContentRowUserApplication: React.FC<ContentRowUserApplicationProp> = ({
  userData,
  appId,
  appName,
  setNotificationGlobal,
  reload,
  setRoleCount,
}) => {
  const appInsights = useAppInsightsContext();

  const [editing, setEditing] = React.useState(false);

  const [rolesUserStatus, setRolesUserStatus] = React.useState<string>("init");
  const [listRolesUser, setListRolesUser] = React.useState<RolesUser[] | undefined>();
  const [userToRemove, setUserToRemove] = React.useState<UserToRemove>({
    id: "",
    name: "",
    rut: "",
    rolId: "",
    rolName: "",
  });
  const [showRemoveModal, setShowRemoveModal] = React.useState(false);
  const [refreshRole, setRefreshRole] = React.useState(1);

  const {
    displayNotification,
    variantNotification,
    messageNotification,
    setNotification,
    setDisplayNotification,
  } = useNotification();

  const handleEditUser = (values: CreateUser, action: FormikHelpers<CreateUser>) => {
    const { setSubmitting } = action;
    setSubmitting(true);

    const request: EditUser = {
      rut: values.rut,
      name: values.name,
      email: values.email,
    };

    api
      .put(updateUserAuth0(userData.Id), request)
      .then(() => {
        setNotificationGlobal("Usuario actualizado correctamente", "success");
        reload();
      })
      .catch((err) => {
        setNotificationGlobal("Ocurrió un problema actualizando el usuario.", "error");
        appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
      })
      .finally(() => {
        setEditing(false);
        setSubmitting(false);
      });
  };

  const reloadRole = () => {
    setRefreshRole(refreshRole + 1);
  };

  React.useEffect(() => {
    setRolesUserStatus("fetching");
    const url = getRolesFromUser(appId, userData.Id, 1, 99);
    api
      .get(url)
      .then((response) => {
        setListRolesUser(response.data?.Data);
        setRolesUserStatus("fetched");
        // response.data.then(d => cache.current[url] =dd dd);
      })
      .catch(() => {
        setRolesUserStatus("error");
        // appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
        // setNotification("Ocurrió un error obteniendo los roles del usuario", "error");
      });
  }, [appId, userData.Id, refreshRole]);

  return (
    <>
      <TableRowStyled key={`user-form-${userData.Id}`}>
        <TableCell colspan={4}>
          <Formik
            enableReinitialize
            initialValues={
              { name: userData.Name, rut: userData.RUT, email: userData.Email } as CreateUser
            }
            validationSchema={Yup.object().shape({
              name: Yup.string().required("El nombre del usuario es requerido."),
              email: Yup.string()
                .required("El correo electrónico del usuario es requerido.")
                .email("El correo electrónico del usuario tiene un formato inválido"),
              rut: Yup.string().required("El RUT del usuario es requerido"),
            })}
            onSubmit={handleEditUser}
          >
            {(formikProp: FormikProps<CreateUser>) => {
              const { isSubmitting, values, touched, handleChange, errors, resetForm } = formikProp;
              return (
                <Form className="content">
                  <Grid container className="header" nomargin nopadding>
                    <Grid item lg={editing ? 6 : 9} sm={12} md={6}>
                      <h5>A continuación, puedes ver o editar la información del usuario</h5>
                    </Grid>
                    {editing ? (
                      <Grid item lg={6} md={6} sm={12} className="header-box-btn" nopadding>
                        <PrimaryButton
                          className="btn-cancel"
                          onClick={(e) => {
                            e.preventDefault();
                            setEditing(false);
                            resetForm();
                          }}
                        >
                          Cancelar
                        </PrimaryButton>
                        <PrimaryButton disabled={isSubmitting}>
                          {isSubmitting && <span className="ticon-loading" />}
                          Guardar
                        </PrimaryButton>
                      </Grid>
                    ) : (
                      <Grid item lg={3} sm={12} md={6}>
                        <PrimaryButton
                          size="md"
                          onClick={(e) => {
                            e.preventDefault();
                            setEditing(true);
                          }}
                        >
                          <span className="ticon-edit" />
                          Editar
                        </PrimaryButton>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container nomargin nopadding lg={12} md={12} sm={12} className="body">
                    <Grid item lg={12} md={12} sm={12} nomargin nopadding className="form-user">
                      <Notification
                        displayNotification={displayNotification}
                        variantNotification={variantNotification}
                        messageNotification={messageNotification}
                        setDisplayNotification={setDisplayNotification}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} nomargin nopadding className="form-user">
                      <label htmlFor="rut">
                        <span>RUT del usuario</span>
                        <FieldStyled
                          as={TextField}
                          value={values.rut}
                          name="rut"
                          readOnly={!editing}
                          onChange={handleChange("rut")}
                          error={touched.rut && errors.rut}
                          errorText={errors.rut}
                        />
                      </label>

                      <label htmlFor="name">
                        <span>Nombre usuario</span>
                        <FieldStyled
                          as={TextField}
                          value={values.name}
                          readOnly={!editing}
                          name="name"
                          onChange={handleChange("name")}
                          error={touched.name && errors.name}
                          errorText={errors.name}
                        />
                      </label>

                      <label htmlFor="email">
                        <span>Correo electrónico usuario</span>
                        <FieldStyled
                          as={TextField}
                          value={values.email}
                          name="email"
                          readOnly={!editing}
                          onChange={handleChange("email")}
                          error={touched.email && errors.email}
                          errorText={errors.email}
                        />
                      </label>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} nomargin nopadding className="roles-user">
                      <h4>Roles asignados a este usuario</h4>
                      <div className="table-container">
                        {rolesUserStatus === "fetching" && (
                          <GenericTableLoader height={200} width={300} />
                        )}
                        {listRolesUser && rolesUserStatus === "fetched" && (
                          <Table borderless>
                            <TableHead>
                              <TableRow>
                                <TableCell component="th">Lista de roles</TableCell>
                                <TableCell component="th">Permisos</TableCell>
                                <TableCell component="th" />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {listRolesUser.map((r) => (
                                <TableRow key={`ro-${r.Id}`}>
                                  <TableCell>{r.Name}</TableCell>
                                  <TableCell>+{r.RolePermissionCounter}</TableCell>
                                  <TableCell>
                                    <div
                                      role="button"
                                      tabIndex={-1}
                                      className="table__cell-delete"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setUserToRemove({
                                          id: userData.Id,
                                          name: userData.Name,
                                          rut: userData.RUT,
                                          rolId: r.Id,
                                          rolName: r.Name,
                                        });
                                        setShowRemoveModal(true);
                                      }}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                        setUserToRemove({
                                          id: userData.Id,
                                          name: userData.Name,
                                          rut: userData.RUT,
                                          rolId: r.Id,
                                          rolName: r.Name,
                                        });
                                        setShowRemoveModal(true);
                                      }}
                                    >
                                      <span className="ticon-misuse" />
                                      Remover
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))}
                              {listRolesUser.length === 0 && (
                                <TableRow key={0}>
                                  <TableCell>No se encontraron roles.</TableCell>
                                  <TableCell />
                                  <TableCell />
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </TableCell>
      </TableRowStyled>

      <RemoveUserToRoleModal
        appName={appName}
        user={userToRemove}
        open={showRemoveModal}
        setOpen={setShowRemoveModal}
        reload={reloadRole}
        setNotification={setNotification}
        setCount={setRoleCount}
      />
    </>
  );
};

export default ContentRowUserApplication;
