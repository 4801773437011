import React from "react";
import ContentLoader from "react-content-loader";

type GenericTableLoaderProps = {
  width: number;
  height: number;
};

const GenericTableLoader: React.FC<GenericTableLoaderProps> = ({ width, height }) => {
  const viewBox = `0 0 ${width} ${height}`;
  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={viewBox}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="14" y="23" rx="3" ry="3" width="88" height="17" />
      <rect x="14" y="53" rx="3" ry="3" width={width} height="50" />
      <rect x="14" y="114" rx="3" ry="3" width={width} height="50" />
      <rect x="14" y="172" rx="3" ry="3" width={width} height="50" />
      <rect x="14" y="231" rx="3" ry="3" width={width} height="50" />
      <rect x="14" y="291" rx="3" ry="3" width={width} height="50" />
      <rect x="189" y="21" rx="3" ry="3" width="88" height="17" />
      <rect x="312" y="22" rx="3" ry="3" width="88" height="17" />
    </ContentLoader>
  );
};

export default GenericTableLoader;
