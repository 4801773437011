import React from "react";
import ContentLoader from "react-content-loader";

const ActivityResumeLoader: React.FC = () => (
  <ContentLoader
    speed={2}
    width={500}
    height={300}
    viewBox="0 0 500 300"
    backgroundColor="#f7f7f7"
    foregroundColor="#ecebeb"
  >
    <rect x="318" y="108" rx="3" ry="3" width="116" height="13" />
    <circle cx="152" cy="157" r="90" />
    <circle cx="292" cy="115" r="9" />
    <rect x="319" y="135" rx="3" ry="3" width="116" height="13" />
    <circle cx="293" cy="143" r="9" />
    <rect x="319" y="162" rx="3" ry="3" width="116" height="13" />
    <circle cx="293" cy="169" r="9" />
    <rect x="319" y="189" rx="3" ry="3" width="116" height="13" />
    <circle cx="293" cy="196" r="9" />
    <rect x="22" y="17" rx="0" ry="0" width="256" height="25" />
  </ContentLoader>
);

export default ActivityResumeLoader;
