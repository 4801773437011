import * as React from "react";
import { TextField } from "@tanner/partner";
import styled from "styled-components";
import { TextFieldProps } from "@tanner/partner/dist/core/common/TextField/types";

type Props = {
  className?: string;
} & TextFieldProps;

const TextFieldStyled = styled.div`
  border-radius: 4px;
  box-sizing: border-box !important;
  background: #fff !important;
  z-index: 0;
  position: relative;

  .ticon-search {
    position: absolute;
    z-index: 1;
    height: 42px;
    width: 45px;
    justify-content: center;
    align-items: center;
    display: flex;

    &::before {
      font-size: 28px;
    }
  }

  div {
    padding-top: 0;
    margin: 0;
  }

  input {
    padding-left: 45px;
    margin-bottom: 0 !important;
    border: 1px solid #dfdfdf;
    height: 42px !important;
  }
`;

const SearchInput: React.FC<Props> = (props) => {
  const { placeholder, className, value, onChange, id, disabled } = props;

  return (
    <TextFieldStyled>
      <span className="ticon-search" />
      <TextField
        id={id}
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </TextFieldStyled>
  );
};

export default SearchInput;
