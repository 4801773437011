import * as React from "react";
import { ErrorButton, Modal, SecondaryButton } from "@tanner/partner";
import styled from "styled-components";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useParams } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { PermissionToRemove } from "../../../types";
import { api, revokePermissionRol } from "../../../api";
import { NotificationState } from "../../../hooks/useNotification";

const ModalStyled = styled(Modal)`
  padding-top: 25vh;

  div:first-child {
    margin-left: 0;
    margin-right: 0;
  }

  div[class*="modal-title"] {
    height: 0 !important;
    margin: 0 !important;
  }

  .modal-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .ticon-warning {
      font-size: 74px;
      color: #f48f00;
    }

    h4 {
      margin: 15px 0 10px 0;
      color: #005cb9;
    }

    p {
      color: #333333;
      text-align: center;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    gap: 80px;
    width: 100%;
    margin-bottom: 10px;

    button {
      color: #005cb9;
    }

    button:first-child {
      background-color: transparent;
    }

    button:nth-child(2) {
      color: #fff;
      background-color: #f36963;
    }
  }
`;

type Props = {
  permission: PermissionToRemove;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reload: () => void;
  setNotification: (message: string, type: NotificationState) => void;
  appId?: string;
  appName: string;
  setCountPerm: React.Dispatch<React.SetStateAction<number>>;
};

type BodyProps = Pick<Props, "permission" | "appName">;

const Body: React.FC<BodyProps> = (props) => {
  const { permission, appName } = props;
  return (
    <div className="modal-body">
      <span className="ticon-warning" />
      <h4>Anular permiso</h4>
      <p>
        Se removerá el permiso: {permission.name} ({permission.id}) de la aplicación {appName} y ya
        no podrá ser utilizado.
      </p>
      <p>¿Estás seguro de que deseas continuar?</p>
    </div>
  );
};

type FooterProps = {
  deleting: boolean;
  handleRemovePermission: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
} & Pick<Props, "setOpen">;

const Footer: React.FC<FooterProps> = (props) => {
  const { setOpen, handleRemovePermission, deleting } = props;
  return (
    <div className="modal-footer">
      <ErrorButton className="btn-cancel" onClick={() => setOpen(false)}>
        Cancelar
      </ErrorButton>
      <SecondaryButton onClick={handleRemovePermission}>
        {deleting && <span className="ticon-loading" />}
        Anular permiso
      </SecondaryButton>
    </div>
  );
};

const RemovePermissionToRoleModal: React.FC<Props> = (props) => {
  const { open, setOpen, permission, reload, setNotification, setCountPerm, appName } = props;
  const { appId } = useParams<{ appId: string }>();
  const [deleting, setDeleting] = React.useState(false);

  const appInsights = useAppInsightsContext();

  const handleRemovePermission = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setDeleting(true);
    e.preventDefault();

    api
      .delete(revokePermissionRol(appId, permission.rolId, permission.id))
      .then(() => {
        setNotification("Permiso anulado correctamente", "success");
        setCountPerm((p) => p - 1);
      })
      .catch((err) => {
        appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
        setNotification("Ocurrió un error al anular el permiso", "error");
      })
      .finally(() => {
        setDeleting(false);
        setOpen(false);
        reload();
      });
  };

  return (
    <ModalStyled
      isOpen={open}
      title=""
      size="md"
      content={<Body permission={permission} appName={appName} />}
      actions={
        <Footer
          setOpen={setOpen}
          handleRemovePermission={handleRemovePermission}
          deleting={deleting}
        />
      }
    />
  );
};

export default RemovePermissionToRoleModal;
