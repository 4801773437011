import * as React from "react";

import RowUsersRoleApplication from "./RowUsersRoleApplication";
import RowPermissionsRoleApplication from "./RowPermissionsRoleApplication";

type ContentRowRoleApplicationProp = {
  appId: string;
  appName: string;
  roleId: string;
  roleName: string;
  setCountPerm: React.Dispatch<React.SetStateAction<number>>;
  setCountUser: React.Dispatch<React.SetStateAction<number>>;
};

const ContentRowRoleApplication: React.FC<ContentRowRoleApplicationProp> = ({
  appId,
  roleId,
  appName,
  roleName,
  setCountPerm,
  setCountUser,
}) => {
  return (
    <>
      <RowPermissionsRoleApplication
        key={`rowperm-${roleId}`}
        appId={appId}
        appName={appName}
        roleId={roleId}
        roleName={roleName}
        setCountPerm={setCountPerm}
      />

      <RowUsersRoleApplication
        key={`rowuser-${roleId}`}
        appId={appId}
        appName={appName}
        roleId={roleId}
        roleName={roleName}
        setCountUser={setCountUser}
      />
    </>
  );
};

export default ContentRowRoleApplication;
