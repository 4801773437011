import * as React from "react";
import styled from "styled-components";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Grid, PrimaryButton, TableCell, TextField, Select } from "@tanner/partner";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { MetadataUser, Option, User } from "../../../types";
import { api, getUser, updateMetadataUserAuth0 } from "../../../api";
import { NotificationState } from "../../../hooks/useNotification";
import {
  optionsCommunesByProvince,
  // optionsCommunesAll,
  optionsRegionsAll,
  optionsProvincesByRegion,
  // optionsProvincesAll,
  optionsCountriesAll,
  regionByName,
  provinceByName,
  communeByName,
  countrieByName,
} from "../../../utils/geography";
import { optionsTypeAccount, optionsCivilStatus, optionsGender } from "../../../utils/enums";
import GenericTableLoader from "../../loaders/GenericTableLoader";

const TableRowStyledMetadata = styled.tr`
  .header-metadata {
    background-color: #f0f6fb;
  }
  .margin-row {
    margin: 25px 0px 0px 0px !important;
  }
  .header-box-btn-metadata {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 8px 0px 0px 0px !important;

    button {
      height: 44px;
      width: 160px;
      justify-content: center;
      margin: 0 25px 0 0;
    }

    .btn-cancel {
      color: #005cb9;
      background-color: transparent;
      border: 1px solid #005cb9 !important;
    }
  }
  .select-style {
    width: 350px;
    margin: 0px 10px 0px 10px !important;
    .label-select {
      font-size: 16px !important;
      text-align: left;

      height: 22px;
      width: 110px;
    }
  }
  .form-row-metadata {
    display: flex;
    padding-right: 1.5em;

    label,
    span {
      margin: 5px !important;
    }

    input,
    textarea,
    select {
      width: 350px;

      margin: 5px !important;
    }
  }
  .body-metadata {
    max-height: 1520px;
    background-color: #f8f9fd !important;
    padding: 0.8em;

    .form-user {
      padding-right: 1.5em;
      display: flex;

      label,
      span {
        margin: 5px !important;
      }

      input,
      textarea {
        width: 350px;
        // padding: 11px 20px 11px 15px !important;
        margin: 5px !important;
import provinces from './../../../utils/provinces';

        ::placeholder {
          color: #bcbcbc;
        }

        ::-ms-input-placeholder {
          color: #bcbcbc;
        }
      }
    }
  }
`;

type FieldStyledProps = {
  error?: boolean;
};

const FieldStyled = styled(Field)<FieldStyledProps>`
  padding: 0;
  margin-bottom: ${(props) => (props.error ? "10px" : "25px")};
  margin-top: 4px;

  span {
    margin: 0;
    white-space: pre;
    height: 15px;
  }
`;

type EditMetadataUser = {
  name: string;
  surname: string;
  executivename: string;
  executiveemail: string;
  executivephone: string;
  country: string;
  nationality: string;
  birthdate: string;
  civilstate: string;
  commune: string;
  gender: string;
  province: string;
  region: string;
  bank: string;
  bankaccounttype: string;
  currentaccount: string;
  homeaddress: string;
  phonoparticular: string;
  businessaddress: string;
  phonocomercial: string;
  usertype: string;
};

type PropsRowEditMetadata = {
  userDataRow: User;
  appId: string;
  setShowEditMetadata: React.Dispatch<React.SetStateAction<boolean>>;
  setNotification: (message: string, type: NotificationState) => void;
  reload: () => void;
};

const UserMetadataEditRowForm: React.FC<PropsRowEditMetadata> = ({
  userDataRow,
  setShowEditMetadata,
  setNotification,
  reload,
}) => {
  const [userMetadata, setUserMetadata] = React.useState<MetadataUser>();
  const [statusUserMetadata, setStatusUserMetadata] = React.useState<string>("init");
  const [optionsProvinces, setOptionsProvinces] = React.useState<Option[]>([]);
  const [optionsCommunes, setOptionsCommunes] = React.useState<Option[]>([]);
  const appInsights = useAppInsightsContext();

  const handleEditMetadataUser = (values: MetadataUser, action: FormikHelpers<MetadataUser>) => {
    const { setSubmitting } = action;
    setSubmitting(true);
    const request: EditMetadataUser = {
      name: values.name,
      surname: values.surname,
      executivename: values.executivename,
      executiveemail: values.executiveemail,
      executivephone: values.executivephone,
      country: values.country,
      nationality: values.nationality,
      birthdate: values.birthdate,
      civilstate: values.civilstateOption.value,
      commune: values.commune,
      gender: values.genderOption.value,
      province: values.province,
      region: values.region,
      bank: values.bank,
      bankaccounttype: values.bankaccounttype,
      currentaccount: values.currentaccount,
      homeaddress: values.homeaddress,
      phonoparticular: values.phonoparticular,
      businessaddress: values.businessaddress,
      phonocomercial: values.phonocomercial,
      usertype: values.usertype,
    };
    api
      .put(updateMetadataUserAuth0(userDataRow.Id), request)
      .then(() => {
        setNotification("Metadata de usuario actualizada correctamente", "success");
        reload();
      })
      .catch((err) => {
        setNotification("Ocurrió un problema actualizando la metadata del usuario.", "error");
        appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleChangeRegion = (value: string) => {
    const prov = optionsProvincesByRegion(value);
    setOptionsProvinces(prov);
  };
  const handleChangeProvince = (value: string) => {
    const communes = optionsCommunesByProvince(value);
    setOptionsCommunes(communes);
  };

  React.useEffect(() => {
    const url = getUser(userDataRow.Id);
    setStatusUserMetadata("fetching");
    api
      .get(url)
      .then((response) => {
        if (response.data?.statuscode === 200) {
          const dataUser = response.data.data;
          try {
            const data = {
              user_id: dataUser.user_id,
              rut: dataUser.user_metadata.rut,
              name: dataUser.name,
              email: dataUser.email,
              surname:
                dataUser.user_metadata.surname !== undefined ||
                dataUser.user_metadata.surname !== null
                  ? dataUser.user_metadata.surname
                  : "",
              executivename:
                dataUser.user_metadata.executivename !== undefined ||
                dataUser.user_metadata.executivename !== null
                  ? dataUser.user_metadata.executivename
                  : "",
              executiveemail:
                dataUser.user_metadata.executiveemail !== undefined ||
                dataUser.user_metadata.executiveemail !== null
                  ? dataUser.user_metadata.executiveemail
                  : "",
              executivephone:
                dataUser.user_metadata.executivephone !== undefined ||
                dataUser.user_metadata.executivephone !== null
                  ? dataUser.user_metadata.executivephone
                  : "",
              country:
                dataUser.user_metadata.country !== undefined ||
                dataUser.user_metadata.country !== null
                  ? dataUser.user_metadata.country
                  : "",
              nationality:
                dataUser.user_metadata.nationality !== undefined ||
                dataUser.user_metadata.nationality !== null
                  ? dataUser.user_metadata.nationality
                  : "",
              birthdate:
                dataUser.user_metadata.birthdate !== undefined ||
                dataUser.user_metadata.birthdate !== null
                  ? dataUser.user_metadata.birthdate
                  : "",
              civilstate:
                dataUser.user_metadata.civilstate !== undefined ||
                dataUser.user_metadata.civilstate !== null
                  ? dataUser.user_metadata.civilstate
                  : "",
              commune:
                dataUser.user_metadata.commune !== undefined ||
                dataUser.user_metadata.commune !== null
                  ? dataUser.user_metadata.commune
                  : "",
              gender:
                dataUser.user_metadata.gender !== undefined ||
                dataUser.user_metadata.gender !== null
                  ? dataUser.user_metadata.gender
                  : "",
              province:
                dataUser.user_metadata.province !== undefined ||
                dataUser.user_metadata.province !== null
                  ? dataUser.user_metadata.province
                  : "",
              region:
                dataUser.user_metadata.region !== undefined ||
                dataUser.user_metadata.region !== null
                  ? dataUser.user_metadata.region
                  : "",
              bank:
                dataUser.user_metadata.bank !== undefined || dataUser.user_metadata.bank !== null
                  ? dataUser.user_metadata.bank
                  : "",
              bankaccounttype:
                dataUser.user_metadata.bankaccounttype !== undefined ||
                dataUser.user_metadata.bankaccounttype !== null
                  ? dataUser.user_metadata.bankaccounttype
                  : "",
              currentaccount:
                dataUser.user_metadata.currentaccount !== undefined ||
                dataUser.user_metadata.currentaccount !== null
                  ? dataUser.user_metadata.currentaccount
                  : "",
              homeaddress:
                dataUser.user_metadata.homeaddress !== undefined ||
                dataUser.user_metadata.homeaddress !== null
                  ? dataUser.user_metadata.homeaddress
                  : "",
              phonoparticular:
                dataUser.user_metadata.phonoparticular !== undefined ||
                dataUser.user_metadata.phonoparticular !== null
                  ? dataUser.user_metadata.phonoparticular
                  : "",
              businessaddress:
                dataUser.user_metadata.businessaddress !== undefined ||
                dataUser.user_metadata.businessaddress !== null
                  ? dataUser.user_metadata.businessaddress
                  : "",
              phonocomercial:
                dataUser.user_metadata.phonocomercial !== undefined ||
                dataUser.user_metadata.phonocomercial !== null
                  ? dataUser.user_metadata.phonocomercial
                  : "",
              usertype:
                dataUser.user_metadata.usertype !== undefined ||
                dataUser.user_metadata.usertype !== null
                  ? dataUser.user_metadata.usertype
                  : "",
            } as MetadataUser;

            const coun = countrieByName(data.country);
            data.countryOption = coun;

            const reg = regionByName(data.region);
            data.regionOption = reg;
            const regprov = optionsProvincesByRegion(reg.value);
            setOptionsProvinces(regprov);

            const prov = provinceByName(data.province);
            data.provinceOption = prov;
            const communes = optionsCommunesByProvince(prov.value);
            setOptionsCommunes(communes);

            const comm = communeByName(data.commune);
            data.communeOption = comm;

            setUserMetadata(data);
          } catch (error) {
            setNotification("Ocurrió un problema obteniendo la información del usuario.", "error");
          }
        }
        setStatusUserMetadata("fetched");
      })
      .catch((err) => {
        setNotification("Ocurrió un problema obteniendo la información del usuario.", "error");
        appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
      });
  }, [userDataRow.Id, setNotification, appInsights]);

  return (
    <TableRowStyledMetadata>
      <TableCell colspan={9}>
        {statusUserMetadata === "fetching" && <GenericTableLoader width={1208} height={680} />}
        {statusUserMetadata === "fetched" && (
          <Formik
            enableReinitialize
            initialValues={
              {
                user_id: userMetadata?.user_id,
                rut: userMetadata?.rut,
                name: userMetadata?.name,
                email: userMetadata?.email,
                surname: userMetadata?.surname,
                executivename: userMetadata?.executivename,
                executiveemail: userMetadata?.executiveemail,
                executivephone: userMetadata?.executivephone,
                country: userMetadata?.country,
                countryOption: userMetadata?.countryOption,
                nationality: userMetadata?.nationality,
                birthdate: userMetadata?.birthdate,
                civilstateOption: {
                  label: userMetadata?.civilstate,
                  value: userMetadata?.civilstate,
                },
                commune: userMetadata?.commune,
                communeOption: userMetadata?.communeOption,
                genderOption: {
                  label: userMetadata?.gender,
                  value: userMetadata?.gender,
                },
                province: userMetadata?.province,
                provinceOption: userMetadata?.provinceOption,
                region: userMetadata?.region,
                regionOption: userMetadata?.regionOption,
                bank: userMetadata?.bank,
                currentaccount: userMetadata?.currentaccount,
                homeaddress: userMetadata?.homeaddress,
                phonoparticular: userMetadata?.phonoparticular,
                businessaddress: userMetadata?.businessaddress,
                phonocomercial: userMetadata?.phonocomercial,
                usertype: userMetadata?.usertype,
                bankaccounttypeOption: {
                  label: userMetadata?.bankaccounttype,
                  value: userMetadata?.bankaccounttype,
                },
                bankaccounttype: userMetadata?.bankaccounttype,
              } as MetadataUser
            }
            validationSchema={Yup.object().shape({
              name: Yup.string().required("El nombre del usuario es requerido."),
              email: Yup.string()
                .required("El correo electrónico del usuario es requerido.")
                .email("El correo electrónico del usuario tiene un formato inválido"),
              rut: Yup.string().required("El RUT del usuario es requerido"),
            })}
            onSubmit={handleEditMetadataUser}
          >
            {(formikProp: FormikProps<MetadataUser>) => {
              const {
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                values,
                touched,
                handleChange,
                errors,
                resetForm,
              } = formikProp;
              return (
                <Form className="content">
                  <Grid container className="header-metadata" nomargin nopadding>
                    <Grid item lg={8} sm={12} md={6}>
                      <h4>Informacion del Cliente</h4>
                    </Grid>
                    <Grid item lg={4} sm={12} md={6}>
                      <div className="grid-center header-box-btn-metadata">
                        <PrimaryButton
                          className="btn-cancel"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowEditMetadata(false);
                            resetForm();
                          }}
                        >
                          Cancelar
                        </PrimaryButton>
                        <PrimaryButton disabled={isSubmitting}>
                          {isSubmitting && <span className="ticon-loading" />}
                          Guardar
                        </PrimaryButton>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    nomargin
                    nopadding
                    lg={12}
                    md={12}
                    sm={12}
                    className="body-metadata"
                  >
                    <Grid item lg={12} md={12} sm={12} nopadding>
                      <span>
                        <strong>Datos Personales</strong>
                      </span>
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      nomargin
                      nopadding
                      className="form-user margin-row"
                    >
                      <label htmlFor="rut">
                        <span>RUT</span>
                        <FieldStyled as={TextField} value={values.rut} name="rut" disabled />
                      </label>

                      <label htmlFor="email">
                        <span>Email</span>
                        <FieldStyled as={TextField} value={values.email} name="email" disabled />
                      </label>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} nomargin nopadding className="form-user">
                      <label htmlFor="nombres">
                        <span>Nombre</span>
                        <FieldStyled
                          as={TextField}
                          value={values.name}
                          name="nombres"
                          placeholder="Ingresa tus nombres"
                          onChange={handleChange("name")}
                          error={touched.name && errors.name}
                          errorText={errors.name}
                          disabled
                        />
                      </label>

                      <label htmlFor="apellidos">
                        <span>Apellidos</span>
                        <FieldStyled
                          as={TextField}
                          value={values.surname}
                          name="surname"
                          placeholder="Ingresa tus apellidos"
                          onChange={handleChange("surname")}
                          error={touched.surname && errors.surname}
                          errorText={errors.surname}
                        />
                      </label>

                      <label htmlFor="fechaNacimiento">
                        <span>Fecha Nacimiento</span>
                        <FieldStyled
                          as={TextField}
                          value={values.birthdate}
                          name="fechaNacimiento"
                          placeholder="DD/MM/AAAA"
                          onChange={handleChange("birthdate")}
                          error={touched.birthdate && errors.birthdate}
                          errorText={errors.birthdate}
                        />
                      </label>
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      nomargin
                      nopadding
                      className="form-row-metadata"
                    >
                      <Select
                        className="select-style"
                        label="Genero"
                        value={values.genderOption}
                        onChange={(option: Option) => setFieldValue("genderOption", option)}
                        options={optionsGender}
                        errorText="Seleccione una opción a continuación"
                        error={values.genderOption ? values.genderOption.value === "" : false}
                      />

                      <Select
                        className="select-style"
                        value={values.civilstateOption}
                        label="Estado Civil"
                        onChange={(option: Option) => setFieldValue("civilstateOption", option)}
                        options={optionsCivilStatus}
                        errorText="Seleccione una opción a continuación"
                        error={
                          values.civilstateOption ? values.civilstateOption.value === "" : false
                        }
                      />

                      <label htmlFor="nacionalidad">
                        <span>Nacionalidad</span>
                        <FieldStyled
                          as={TextField}
                          value={values.nationality}
                          name="nacionalidad"
                          placeholder="Ingresa tu Nacionalidad"
                          onChange={handleChange("nationality")}
                          error={touched.nationality && errors.nationality}
                          errorText={errors.nationality}
                        />
                      </label>
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      nomargin
                      nopadding
                      className="form-row-metadata"
                    >
                      <label htmlFor="telefonoParticular">
                        <span>Teléfono Particular</span>
                        <FieldStyled
                          as={TextField}
                          value={values.phonoparticular}
                          name="telefonoParticular"
                          placeholder="Ingresa tu teléfono particular"
                          onChange={handleChange("phonoparticular")}
                          error={touched.phonoparticular && errors.phonoparticular}
                          errorText={errors.phonoparticular}
                        />
                      </label>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} nopadding>
                      <span>
                        <strong>Dirección</strong>
                      </span>
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      nomargin
                      nopadding
                      className="form-row-metadata margin-row"
                    >
                      <Select
                        className="select-style"
                        label="País"
                        value={values.countryOption}
                        onChange={(option: Option) => {
                          setFieldValue("country", option.label);
                          setFieldValue("countryOption", option);
                        }}
                        options={optionsCountriesAll()}
                        errorText="Selecciona tu país"
                        error={values.countryOption ? values.countryOption.value === "" : false}
                      />

                      <Select
                        className="select-style"
                        value={values.regionOption}
                        label="Región"
                        onChange={(option: Option) => {
                          setFieldValue("region", option.label);
                          setFieldValue("regionOption", option);
                          setFieldTouched("regionOption");
                          setFieldValue("province", "");
                          setFieldValue("provinceOption", "");
                          setFieldValue("commune", "");
                          setFieldValue("communeOption", "");
                          handleChangeRegion(option.value);
                        }}
                        options={optionsRegionsAll()}
                        errorText="Seleccione tu región"
                        error={values.regionOption ? values.regionOption.value === "" : false}
                      />

                      <Select
                        className="select-style"
                        value={values.provinceOption}
                        label="Provincia"
                        onChange={(option: Option) => {
                          setFieldValue("province", option.label);
                          setFieldValue("provinceOption", option);
                          setFieldValue("commune", "");
                          setFieldValue("communeOption", "");
                          handleChangeProvince(option.value);
                        }}
                        options={optionsProvinces}
                        errorText="Seleccione tu provincia"
                        error={values.provinceOption ? values.provinceOption.value === "" : false}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      nomargin
                      nopadding
                      className="form-row-metadata margin-row"
                    >
                      <Select
                        className="select-style"
                        value={values.communeOption}
                        label="Comuna"
                        onChange={(option: Option) => {
                          setFieldValue("commune", option.label);
                          setFieldValue("communeOption", option);
                        }}
                        options={optionsCommunes}
                        errorText="Seleccione tu comuna"
                        error={values.communeOption ? values.communeOption.value === "" : false}
                      />
                      <label htmlFor="direccionParticular">
                        <span>Dirección Particular</span>
                        <FieldStyled
                          as={TextField}
                          value={values.homeaddress}
                          name="direccionParticular"
                          placeholder="Ingresa tu dirección particular"
                          onChange={handleChange("homeaddress")}
                          error={touched.homeaddress && errors.homeaddress}
                          errorText={errors.homeaddress}
                        />
                      </label>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} nopadding>
                      <span>
                        <strong>Datos bancarios</strong>
                      </span>
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      nomargin
                      nopadding
                      className="form-row-metadata margin-row"
                    >
                      <label htmlFor="banco">
                        <span>Banco</span>
                        <FieldStyled
                          as={TextField}
                          value={values.bank}
                          name="banco"
                          placeholder="Ingresa tu banco"
                          onChange={handleChange("bank")}
                          error={touched.bank && errors.bank}
                          errorText={errors.bank}
                        />
                      </label>
                      <Select
                        className="select-style"
                        value={values.bankaccounttypeOption}
                        label="Tipo de cuenta"
                        onChange={(option: Option) => {
                          setFieldValue("bankaccounttype", option.label);
                          setFieldValue("bankaccounttypeOption", option);
                        }}
                        options={optionsTypeAccount}
                        errorText="Seleccione tipo de cuenta"
                        error={
                          values.bankaccounttypeOption
                            ? values.bankaccounttypeOption.value === ""
                            : false
                        }
                      />
                      <label htmlFor="numeroCuenta">
                        <span>Número de cuenta</span>
                        <FieldStyled
                          as={TextField}
                          value={values.currentaccount}
                          name="numeroCuenta"
                          placeholder="Ingresa tu número de cuenta"
                          onChange={handleChange("currentaccount")}
                          error={touched.currentaccount && errors.currentaccount}
                          errorText={errors.currentaccount}
                        />
                      </label>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} nomargin nopadding className="form-user">
                      <label htmlFor="direccionComercial">
                        <span>Dirección Comercial</span>
                        <FieldStyled
                          as={TextField}
                          value={values.businessaddress}
                          name="direccionComercial"
                          placeholder="Ingresar dirección comercial"
                          onChange={handleChange("businessaddress")}
                          error={touched.businessaddress && errors.businessaddress}
                          errorText={errors.businessaddress}
                        />
                      </label>
                      <label htmlFor="telefonoComercial">
                        <span>Teléfono Comercial</span>
                        <FieldStyled
                          as={TextField}
                          value={values.phonocomercial}
                          name="telefonoComercial"
                          placeholder="Ingresa tu teléfono comercial"
                          onChange={handleChange("phonocomercial")}
                          error={touched.phonocomercial && errors.phonocomercial}
                          errorText={errors.phonocomercial}
                        />
                      </label>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} nopadding>
                      <span>
                        <strong>Información de Ejecutivo</strong>
                      </span>
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      nomargin
                      nopadding
                      className="form-user margin-row"
                    >
                      <label htmlFor="ejecutivo">
                        <span>Ejecutivo</span>
                        <FieldStyled
                          as={TextField}
                          value={values.executivename}
                          name="ejecutivo"
                          placeholder="Ingresar nombre de ejecutivo"
                          onChange={handleChange("executivename")}
                          error={touched.executivename && errors.executivename}
                          errorText={errors.executivename}
                        />
                      </label>
                      <label htmlFor="emailEjecutivo">
                        <span>Email Ejecutivo</span>
                        <FieldStyled
                          as={TextField}
                          value={values.executiveemail}
                          name="emailEjecutivo"
                          placeholder="Ingresar email de ejecutivo"
                          onChange={handleChange("executiveemail")}
                          error={touched.executiveemail && errors.executiveemail}
                          errorText={errors.executiveemail}
                        />
                      </label>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        )}
      </TableCell>
    </TableRowStyledMetadata>
  );
};

export default UserMetadataEditRowForm;
