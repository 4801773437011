import axios from "axios";
import config from "../config";

export const api = axios.create({
  baseURL: config.baseUrl,
  headers: {
    "Ocp-Apim-Subscription-Key": config.subscriptionKey,
    "Api-Version": config.apiVersion,
    "Cache-Control": "max-age=60",
  },
});

export const authorized = (token: string) => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
  return api;
};
