import { useState } from "react";

export type NotificationState = "success" | "error" | "warning" | "";

const useNotification = () => {
  const [displayNotification, setDisplayNotification] = useState<boolean>(false);
  const [messageNotification, setMessageNotification] = useState<string>("");
  const [variantNotification, setVariantNotification] = useState<NotificationState>("");

  const setNotification = (message: string, type: NotificationState) => {
    setMessageNotification(message);
    setVariantNotification(type);
    setDisplayNotification(true);
  };

  return {
    displayNotification,
    messageNotification,
    variantNotification,
    setDisplayNotification,
    setNotification,
  };
};

export default useNotification;
