import { Option } from "../types";

const regions = [
  {
    codigo: "15",
    tipo: "region",
    nombre: "Arica y Parinacota",
    lat: -18.5075,
    lng: -69.6451,
    url: "",
    codigo_padre: "00",
  },
  {
    codigo: "01",
    tipo: "region",
    nombre: "Tarapac\u00e1",
    lat: -20.164,
    lng: -69.5541,
    url: "",
    codigo_padre: "00",
  },
  {
    codigo: "02",
    tipo: "region",
    nombre: "Antofagasta",
    lat: -23.7503,
    lng: -69.6,
    url: "",
    codigo_padre: "00",
  },
  {
    codigo: "03",
    tipo: "region",
    nombre: "Atacama",
    lat: -27.5276,
    lng: -70.2494,
    url: "",
    codigo_padre: "00",
  },
  {
    codigo: "04",
    tipo: "region",
    nombre: "Coquimbo",
    lat: -30.8301,
    lng: -70.9816,
    url: "",
    codigo_padre: "00",
  },
  {
    codigo: "05",
    tipo: "region",
    nombre: "Valpara\u00edso",
    lat: -32.9039,
    lng: -71.0262,
    url: "",
    codigo_padre: "00",
  },
  {
    codigo: "13",
    tipo: "region",
    nombre: "Metropolitana de Santiago",
    lat: -33.4417,
    lng: -70.6541,
    url: "",
    codigo_padre: "00",
  },
  {
    codigo: "06",
    tipo: "region",
    nombre: "Del Libertador Gral. Bernardo O\u2019Higgins",
    lat: -34.4294,
    lng: -71.0393,
    url: "",
    codigo_padre: "00",
  },
  {
    codigo: "07",
    tipo: "region",
    nombre: "Del Maule",
    lat: -35.5892,
    lng: -71.5007,
    url: "",
    codigo_padre: "00",
  },
  {
    codigo: "08",
    tipo: "region",
    nombre: "Del Biob\u00edo",
    lat: -37.2442,
    lng: -72.4661,
    url: "",
    codigo_padre: "00",
  },
  {
    codigo: "09",
    tipo: "region",
    nombre: "De la Araucan\u00eda",
    lat: -38.5505,
    lng: -72.4382,
    url: "",
    codigo_padre: "00",
  },
  {
    codigo: "14",
    tipo: "region",
    nombre: "De los R\u00edos",
    lat: -39.9086,
    lng: -72.7034,
    url: "",
    codigo_padre: "00",
  },
  {
    codigo: "10",
    tipo: "region",
    nombre: "De los Lagos",
    lat: -42.1071,
    lng: -72.6425,
    url: "",
    codigo_padre: "00",
  },
  {
    codigo: "11",
    tipo: "region",
    nombre: "Ays\u00e9n del Gral. Carlos Ib\u00e1\u00f1ez del Campo",
    lat: -46.2772,
    lng: -73.6628,
    url: "",
    codigo_padre: "00",
  },
  {
    codigo: "12",
    tipo: "region",
    nombre: "Magallanes y de la Ant\u00e1rtica Chilena",
    lat: -54.3551,
    lng: -70.5284,
    url: "",
    codigo_padre: "00",
  },
  {
    codigo: "16",
    tipo: "region",
    nombre: "\u00d1uble",
    lat: -366.191,
    lng: -720.182,
    url: "",
    codigo_padre: "00",
  },
];

export const optionsRegionsAll = (): Option[] => {
  const options = [{}] as Option[];

  regions.forEach((region) => {
    options.push({
      label: region.nombre,
      value: region.codigo,
    } as Option);
  });

  return options;
};

export const regionByName = (name: string): Option => {
  const options = {} as Option;

  const regionsFiltered = regions.filter(function (region) {
    return region.nombre === name;
  });

  regionsFiltered.forEach((region) => {
    options.label = region.nombre;
    options.value = region.codigo;
  });

  return options;
};

export const regionByCode = (code: string): Option => {
  const options = {} as Option;

  const regionsFiltered = regions.filter(function (region) {
    return region.codigo === code;
  });

  regionsFiltered.forEach((region) => {
    options.label = region.nombre;
    options.value = region.codigo;
  });

  return options;
};

export default regions;
