import * as React from "react";
import styled from "styled-components";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import {
  Grid,
  PrimaryButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TextField,
} from "@tanner/partner";

import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { AppResume, CreateUser, ErrorApi, User } from "../../../types";
import { api, listApplicationsByUser, updateUserAuth0 } from "../../../api";
import { NotificationState } from "../../../hooks/useNotification";
import GenericTableLoader from "../../loaders/GenericTableLoader";

const TableRowStyled = styled.tr`
  .body {
    max-height: 420px;
    background-color: #f8f9fd !important;
    padding: 0.8em;

    .form-user {
      padding-right: 1.5em;

      input,
      textarea {
        width: 386px;
        padding: 11px 20px 11px 15px !important;
        margin: 0 !important;

        ::placeholder {
          color: #bcbcbc;
        }

        ::-ms-input-placeholder {
          color: #bcbcbc;
        }
      }
    }

    .header-box-btn {
      flex-direction: row;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: flex-start;

      button {
        height: 44px;
        width: 180px;
        justify-content: center;
        margin: 0 25px 0 0;
      }

      .btn-cancel {
        color: #005cb9;
        background-color: transparent;
        border: 1px solid #005cb9 !important;
      }
    }

    .roles-user {
      padding: 1em 1.5em 1.5em 1.5em;
      background-color: #fafafae8;
      border-radius: 4px;
      border: 1px solid #ebebeb;
      max-height: 270px;

      .table-container {
        overflow-y: scroll;
        width: 100%;
        height: 90%;
        margin-top: 10px;
        padding-right: 5px;
      }

      h4 {
        margin: 0;
      }

      div.table__cell-delete {
        color: #3055bd;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 25px;
        cursor: pointer;

        span {
          margin-right: 10px;
        }
      }

      table {
        border-collapse: separate;
        border-spacing: 0 5px;
        overflow-y: scroll;

        th {
          padding-bottom: 0 !important;
          padding-top: 0 !important;
        }

        th:first-child {
          padding-left: 15px;
        }

        tbody tr {
          height: 30px !important;
          background-color: #fcfcfc;
        }

        tbody tr td {
          border-top: 1px solid #ebebeb;
          border-bottom: 1px solid #ebebeb;
        }

        tbody tr td:first-child {
          border-left: 1px solid #ebebeb;
          border-top: 1px solid #ebebeb;
          border-bottom: 1px solid #ebebeb;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        tbody tr td:last-child {
          border-right: 1px solid #ebebeb;
          border-top: 1px solid #ebebeb;
          border-bottom: 1px solid #ebebeb;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
`;

type FieldStyledProps = {
  error?: boolean;
};

const FieldStyled = styled(Field)<FieldStyledProps>`
  padding: 0;
  margin-bottom: ${(props) => (props.error ? "10px" : "25px")};
  margin-top: 4px;

  span {
    margin: 0;
    white-space: pre;
    height: 15px;
  }
`;

type EditUser = {
  rut: string;
  name: string;
  email: string;
};

type PropsRowEdit = {
  userDataRow: User;
  appId: string;
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setNotification: (message: string, type: NotificationState) => void;
  reload: () => void;
};

const UserEditRowForm: React.FC<PropsRowEdit> = ({
  userDataRow,
  setShowEdit,
  setNotification,
  reload,
}) => {
  const [appUserStatus, setAppUserStatus] = React.useState<string>("init");
  const [listAppsUser, setListAppsUser] = React.useState<AppResume[] | undefined>();

  const appInsights = useAppInsightsContext();

  const handleEditUser = (values: CreateUser, action: FormikHelpers<CreateUser>) => {
    const { setSubmitting } = action;
    setSubmitting(true);

    const request: EditUser = {
      rut: values.rut,
      name: values.name,
      email: values.email,
    };

    api
      .put(updateUserAuth0(userDataRow.Id), request)
      .then(() => {
        setNotification("Usuario actualizado correctamente", "success");
        reload();
      })
      .catch((err) => {
        if (err.response.status === 409) {
          const errorApi = err.response.data as ErrorApi;
          setNotification(errorApi.message, "error");
        } else {
          setNotification("Ocurrió un problema actualizado el usuario.", "error");
          appInsights.trackException({
            exception: err,
            severityLevel: SeverityLevel.Error,
          });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  React.useEffect(() => {
    setAppUserStatus("fetching");
    const url = listApplicationsByUser(userDataRow.Id, 1, 99);
    api
      .get(url)
      .then((response) => {
        setListAppsUser(response.data?.Data);
        setAppUserStatus("fetched");
      })
      .catch(() => {
        setAppUserStatus("error");
      });
  }, [userDataRow.Id]);

  return (
    <TableRowStyled>
      <TableCell colspan={9}>
        <Grid container nomargin nopadding lg={12} md={12} sm={12} className="body">
          <Grid item sm={12} md={12} lg={12}>
            <p className="title">Información relacionada al usuario</p>
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
            <Formik
              enableReinitialize
              initialValues={
                {
                  name: userDataRow.Name,
                  rut: userDataRow.RUT,
                  email: userDataRow.Email,
                } as CreateUser
              }
              validationSchema={Yup.object().shape({
                name: Yup.string().required("El nombre del usuario es requerido."),
                email: Yup.string()
                  .required("El correo electrónico del usuario es requerido.")
                  .email("El correo electrónico del usuario tiene un formato inválido"),
                rut: Yup.string()
                  .required("El RUT del usuario es requerido.")
                  .max(10, "Largo máximo 10 caracteres.")
                  .matches(/^[0-9]{8,9}[-|‐]{1}[0-9kK]{1}$/, "Formato RUT no válido.")
                  .rut("Debe ingresar un RUT válido."),
              })}
              onSubmit={handleEditUser}
            >
              {(formikProp: FormikProps<CreateUser>) => {
                const {
                  isSubmitting,
                  values,
                  touched,
                  handleChange,
                  errors,
                  resetForm,
                } = formikProp;
                return (
                  <Form className="content">
                    <Grid container nomargin nopadding lg={12} md={12} sm={12} className="body">
                      <Grid item lg={6} md={6} sm={12} nomargin nopadding className="form-user">
                        <label htmlFor="rut">
                          <span>RUT del usuario</span>
                          <FieldStyled
                            as={TextField}
                            value={values.rut}
                            name="rut"
                            onChange={handleChange("rut")}
                            error={touched.rut && errors.rut}
                            errorText={errors.rut}
                            placeholder="Ingresa el RUT del usuario. (ej. 15896325-1)"
                          />
                        </label>

                        <label htmlFor="name">
                          <span>Nombre usuario</span>
                          <FieldStyled
                            as={TextField}
                            value={values.name}
                            name="name"
                            onChange={handleChange("name")}
                            error={touched.name && errors.name}
                            errorText={errors.name}
                          />
                        </label>

                        <label htmlFor="email">
                          <span>Correo electrónico usuario</span>
                          <FieldStyled
                            as={TextField}
                            value={values.email}
                            name="email"
                            onChange={handleChange("email")}
                            error={touched.email && errors.email}
                            errorText={errors.email}
                          />
                        </label>

                        <div className="grid-center header-box-btn">
                          <PrimaryButton
                            className="btn-cancel"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowEdit(false);
                              resetForm();
                            }}
                          >
                            Cancelar
                          </PrimaryButton>
                          <PrimaryButton disabled={isSubmitting}>
                            {isSubmitting && <span className="ticon-loading" />}
                            Guardar
                          </PrimaryButton>
                        </div>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} nomargin nopadding className="roles-user">
                        <h4>Aplicaciones y roles asignados a este usuario</h4>
                        <div className="table-container">
                          {appUserStatus === "fetching" && (
                            <GenericTableLoader height={200} width={490} />
                          )}
                          {listAppsUser && appUserStatus === "fetched" && (
                            <Table borderless>
                              <TableHead>
                                <TableRow>
                                  <TableCell component="th">Lista de Aplicaciones</TableCell>
                                  <TableCell component="th">Roles</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {listAppsUser.map((a) => (
                                  <TableRow key={`app-user-${a.id}`}>
                                    <TableCell>{a.name}</TableCell>
                                    <TableCell>+{a.rolecount}</TableCell>
                                  </TableRow>
                                ))}
                                {listAppsUser.length === 0 && (
                                  <TableRow key={0}>
                                    <TableCell colspan={2}>No se encontraron datos.</TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </TableCell>
    </TableRowStyled>
  );
};

export default UserEditRowForm;
