import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";

import Router from "../../../routes/Router";
import Loading from "../Loading";
import ErrorLoginPage from "../../../pages/ErrorLogin/ErrorLoginPage";

const App = () => {
  const { isLoading, error, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  React.useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, error, logout]);

  if (error) {
    return <ErrorLoginPage />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return isAuthenticated ? (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  ) : null;
};

export default App;
