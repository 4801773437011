import * as yup from "yup";

import { validate } from "rut.js";

function rutValidation(this: yup.StringSchema, errorMessage: string) {
  return this.test("test-rut", errorMessage, (value: string | undefined) => {
    if (value) return validate(value);

    return false;
  });
}

yup.addMethod(yup.string, "rut", rutValidation);
