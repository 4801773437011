enum StorageKeys {
  Token = "token",
}

enum RoutesPath {
  Applications = "/aplicaciones",
  Application = "/aplicacion",
  Home = "/",
  Roles = "/roles",
  Role = "/rol",
  Permissions = "/permisos",
  Permission = "/permiso",
  Users = "/usuarios",
  Profile = "/perfil",
}

enum ApplicationsRoutePath {
  Create = `/aplicaciones/crear`,
  Info = `/aplicaciones/:appId`,
  Users = `/aplicaciones/:appId/usuarios`,
  Roles = `/aplicaciones/:appId/roles`,
  Permissions = `/aplicaciones/:appId/permisos`,
}

enum PieChartColors {
  Applications = "#68bce7",
  Users = "#8987c7",
  User = "#4DBFE5",
  Roles = "#f48799",
  Permissions = "#adf1d5",
}

enum AppType {
  RegularWeb = "RegularWeb",
  SPA = "SPA",
  NonInteractive = "NonInteractive",
  Native = "Native",
}

enum Order {
  ASC = "ASC",
  DESC = "DESC",
}

enum MimeType {
  ApplicationExcel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

const optionsTypeAccount = [
  {
    label: "Cuenta Corriente",
    value: "Cuenta Corriente",
  },
  {
    label: "Cuenta Vista",
    value: "Cuenta Vista",
  },
  {
    label: "Cuenta de Ahorro",
    value: "Cuenta de Ahorro",
  },
];

const optionsGender = [
  {
    label: "Masculino",
    value: "Masculino",
  },
  {
    label: "Femenino",
    value: "Femenino",
  },
];

const optionsCivilStatus = [
  {
    label: "Soltero",
    value: "Soltero",
  },
  {
    label: "Casado",
    value: "Casado",
  },
];

export {
  StorageKeys,
  RoutesPath,
  ApplicationsRoutePath,
  PieChartColors,
  AppType,
  Order,
  MimeType,
  optionsTypeAccount,
  optionsCivilStatus,
  optionsGender,
};
