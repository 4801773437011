import * as React from "react";
import styled from "styled-components";

import { AppBar } from "@tanner/partner";

import NavigationDrawer from "../../components/commons/NavigationDrawer";
import Header from "../Header";
import ProfileBar from "../../components/commons/ProfileBar";
import Footer from "./Footer";

type Props = {
  title?: string;
  subtitle?: string;
  utilitiesBox?: React.ReactNode;
};

const AppBarStyled = styled(AppBar)`
  margin: 0 0 0 60px !important;
  width: calc(100% - 60px) !important;

  .logo-appbar {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  min-height: calc(100vh - 75px);
  display: flex;
  padding: 26px 24px 0 calc(60px + 22px);
  flex-direction: column;

  .main-content {
    margin: 15px 0 0 0;
    padding-bottom: 0;
    flex: 1;
  }

  footer {
    min-height: 30px;
    position: relative;
    bottom: 0;
    margin: 40px 0 0 0 !important;

    & span {
      font-size: 0.75em;
      font-weight: 300;
      color: #595959;
    }
  }
`;

const FullContainer: React.FC<Props> = ({ children, title = "Prueba", subtitle, utilitiesBox }) => {
  return (
    <>
      <AppBarStyled drawer={<NavigationDrawer />} utilities={<ProfileBar />} />
      <Wrapper>
        <Header title={title} subtitle={subtitle} utilitiesBox={utilitiesBox} />
        <div className="main-content">{children}</div>
        <Footer />
      </Wrapper>
    </>
  );
};

export default FullContainer;
