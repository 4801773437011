import React from "react";
import ContentLoader from "react-content-loader";

const UserGridDataLoader = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={300}
    viewBox="0 0 500 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="70" y="20" rx="0" ry="0" width="400" height="30" />
    <rect x="100" y="60" rx="0" ry="0" width="400" height="30" />
    <rect x="100" y="100" rx="0" ry="0" width="400" height="30" />
    <rect x="100" y="140" rx="0" ry="0" width="400" height="30" />
    <rect x="100" y="180" rx="0" ry="0" width="400" height="30" />
    <rect x="100" y="220" rx="0" ry="0" width="400" height="30" />
    <rect x="100" y="260" rx="0" ry="0" width="400" height="30" />
    <rect x="100" y="300" rx="0" ry="0" width="400" height="30" />
  </ContentLoader>
);

export default UserGridDataLoader;
