/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { Grid, SecondaryButton } from "@tanner/partner";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import fileDownload from "js-file-download";
import { MimeType } from "../../../utils/enums";
import { api } from "../../../api";
import { NotificationState } from "../../../hooks/useNotification";

type UploadStyledProps = {
  backgroundWhite?: boolean;
};

const UploadStyled = styled(Grid)<UploadStyledProps>`
  width: 100%;
  height: 245px;
  background-color: ${(props) => (props.backgroundWhite ? "#FFFFFF" : "#f8f9fd")};
  padding: 22px 46px;

  .upload-box {
    border: 2px dashed #adadad;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    padding: 22px;

    h2 {
      margin: 0 0 0.5em 0;
      color: #666666;
      font-size: 20px;
      font-weight: 700;
    }

    button {
      font-size: 1em;
      font-weight: normal;

      span + span {
        margin-left: 10px;
      }
    }

    .ticon-export {
      color: #7ebc00;
      font-size: 40px;
      margin-bottom: 0.6rem;
    }

    .text-blue {
      color: #3055bd;
    }

    p {
      margin: 0;
      color: #808080;
    }

    span.loading-bar {
      width: 330px;
      height: 12px;
      background-color: #e6edf8;
      margin: 1em 0.5em;
      border-radius: 50px;
      display: block;
      position: relative;
      padding: 1px;

      &:before {
        width: 130px;
        height: 10px;
        background-color: #244396;
        position: absolute;
        content: "";
        white-space: pre;
        display: inline;
        border-radius: 50px;
        animation: infiniteBar 1.8s linear infinite;
      }

      @keyframes infiniteBar {
        0% {
          left: 0;
          right: 100%;
          width: 0;
        }
        10% {
          left: 0;
          right: 50%;
          width: 50%;
        }
        90% {
          right: 0;
          left: 50%;
          width: 50%;
        }
        100% {
          left: 100%;
          right: 0;
          width: 0;
        }
      }
    }
  }
`;

type Props = {
  url: string;
  btnText: string;
  filesAccept: MimeType[];
  setShowUpload: React.Dispatch<React.SetStateAction<boolean>>;
  setNotification: (message: string, type: NotificationState) => void;
  backgroundWhite?: boolean;
  reload?: () => void;
};

const UploadFile: React.FC<Props> = (props) => {
  const {
    btnText,
    filesAccept,
    url,
    setShowUpload,
    setNotification,
    backgroundWhite = false,
    reload = () => {},
  } = props;

  const [uploading, setUploading] = React.useState(false);

  const appInsights = useAppInsightsContext();

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setUploading(true);

      const data = new FormData();
      data.append("file", acceptedFiles[0]);

      api
        .post(url, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob",
        })
        .then((response) => {
          fileDownload(response.data, "Detalle de la carga.xlsx");
          reload();
          setNotification(
            "Archivo cargado correctamente. Para más detalle, revise el archivo descargado.",
            "success"
          );
        })
        .catch((err) => {
          setNotification("Ocurrió un error cargando el archivo.", "error");
          appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
        })
        .finally(() => {
          setUploading(false);
          setShowUpload(false);
        });
    },
    [url, setNotification, appInsights, setShowUpload, reload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: filesAccept.join(", "),
    onDrop,
  });

  return (
    <UploadStyled
      backgroundWhite={backgroundWhite}
      container
      sm={12}
      md={12}
      lg={12}
      nopadding
      nomargin
    >
      <Grid item sm={12} md={12} lg={12} className="upload-box" {...getRootProps()}>
        {uploading ? (
          <>
            <span className="ticon-export" />
            <h2 className="text-blue">Importando archivo</h2>
            <p>La carga de este archivo puede tardar unos minutos</p>
            <span className="loading-bar" />
          </>
        ) : (
          <>
            <input {...getInputProps()} />
            <h2>Arrastra el archivo</h2>
            <h2>o</h2>
            <SecondaryButton>
              <span className="ticon-upload1" />
              <span>{btnText}</span>
            </SecondaryButton>
          </>
        )}
      </Grid>
    </UploadStyled>
  );
};

export default UploadFile;
