import * as React from "react";
import styled from "styled-components";

import { Surface } from "@tanner/partner";

import FullContainer from "../../layouts/FullContainer";
import ActivityResume from "../../components/dashboard/ActivityResume";
import LatestAppsCreated from "../../components/dashboard/LatestAppsCreated";
import TotalCreatedGrid from "../../components/dashboard/TotalCreatedGrid";
import { AllPermissionSummary, ApplicationsResume } from "../../types";
import { getAllPermissionSummary, getApplications } from "../../api";
import { Order } from "../../utils/enums";
import useFetch from "../../hooks/useFetch";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 24px;
  align-content: space-between !important;
  row-gap: 33px;
  min-width: 100% !important;
  min-height: 100% !important;

  .dashboard-surfaces {
    width: 660px;
    height: 293px;
    margin: 0 !important;
  }

  @media only screen and (max-width: 1240px) {
    grid-template-columns: 1fr;
    column-gap: 5px;

    .dashboard-surfaces {
      width: 660px;
    }
  }
  @media only screen and (min-width: 1240px) and (max-width: 1440px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;

    .dashboard-surfaces {
      width: 660px;
    }
  }
  @media only screen and (min-width: 1620px) {
    place-items: center;
    column-gap: 10px;
    row-gap: 20px;

    .dashboard-surfaces {
      width: 800px;
      height: 360px;
    }
  }
`;

const DashboardPage: React.FC = () => {
  const { status: appStatus, data: apps } = useFetch<ApplicationsResume>(
    getApplications(1, 3, Order.DESC)
  );
  const { status: permissionStatus, data: permission } = useFetch<AllPermissionSummary>(
    getAllPermissionSummary(1, 3, Order.DESC)
  );
  return (
    <FullContainer title="Sistema de administración de roles y permisos de aplicaciones Tanner">
      <Wrapper>
        <Surface shadow={1} radius="medium" className="dashboard-surfaces">
          <ActivityResume />
        </Surface>
        <div className="dashboard-surfaces">
          <TotalCreatedGrid />
        </div>
        <Surface shadow={1} radius="medium" className="dashboard-surfaces">
          <LatestAppsCreated
            mainTitle="Últimas aplicaciones agregadas"
            headerTitle1="Nombre Aplicación"
            headerTitle2="ID"
            data={apps?.Data}
            isLoading={appStatus === "fetching"}
          />
        </Surface>
        <Surface shadow={1} radius="medium" className="dashboard-surfaces">
          <LatestAppsCreated
            mainTitle="Últimos permisos creados"
            headerTitle1="Nombre Aplicación"
            headerTitle2="Acción"
            data={permission?.Data}
            isLoading={permissionStatus === "fetching"}
          />
        </Surface>
      </Wrapper>
    </FullContainer>
  );
};

export default DashboardPage;
