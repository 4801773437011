import React from "react";
import { AppState, Auth0Provider } from "@auth0/auth0-react";

import config from "../../../config";

const AuthProvider: React.FC = ({ children }) => {
  const onRedirectCallback = (appState: AppState) => {
    window.history.pushState(appState, "Inicio", "/");
  };

  return (
    <Auth0Provider
      domain={config.auth.domain}
      clientId={config.auth.clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={config.auth.audience}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
