import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import {
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@tanner/partner";
import axios, { CancelTokenSource } from "axios";
import { paginationProps } from "@tanner/partner/dist/core/pagination/_types";
import { useAuth0 } from "@auth0/auth0-react";
import ApplicationRightContent from "../../../layouts/ApplicationRightContent";
import HeaderApplications from "../../../components/applications/HeaderApplications";
import SubheaderApp from "../../../components/applications/SubheaderApp";
import UploadFile from "../../../components/commons/UploadFile";
import { getApplication, uploadAddRolesFile, getRolesFull, searchRoleFull } from "../../../api";
import * as api from "../../../api";
import { MimeType } from "../../../utils/enums";
import useFetch from "../../../hooks/useFetch";
import { ApplicationDetail, ListRolesFull, RoleToDelete } from "../../../types";
import { NotificationState } from "../../../hooks/useNotification";
import SearchInput from "../../../ui/SearchInput";
import RowRoleApplication from "../../../components/applications/RowRoleApplication";
import CreateRoleApplication from "../../../components/applications/CreateRole";
import DeleteRoleModal from "../../../components/applications/DeleteRoleModal/DeleteRoleModal";
import RolesAppsTableLoader from "../../../components/loaders/RolesAppsTableLoader";
import { verifyCancelToken } from "../../../utils/http";
import logger from "../../../utils/logger";

type Props = {
  setNotification: (message: string, type: NotificationState) => void;
} & RouteComponentProps;

const MainStyled = styled.div`
  padding-bottom: 25px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PaginationStyled = styled(Pagination)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled(Grid)`
  padding: 0 25px 5px 25px;

  .wrapper__actions {
    display: flex;
    align-items: center;
    min-height: 90px;
    width: 100%;
    border-bottom: 1px solid #f7f7f7;

    .wrapper__actions-left {
      display: flex;
      justify-content: flex-start;

      input::-ms-input-placeholder {
        color: #afafaf !important;
      }

      input::placeholder {
        color: #afafaf !important;
      }
    }

    .wrapper__search-btn {
      width: 290px;
    }

    .wrapper__actions-right {
      display: flex;
      justify-content: flex-end;

      button {
        margin-right: 10px;
        width: 156px;
        height: 42px;
      }
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0 10px;

    th {
      padding-bottom: 0 !important;
    }
  }
`;

let source: CancelTokenSource;

const RolesApplicationPage: React.FC<Props> = (props) => {
  const { match, setNotification } = props;

  const [evtName, setEvtName] = React.useState<"writing" | "pagination" | undefined>();
  const { appId } = match.params as { appId: string };
  const [showCreate, setShowCreate] = React.useState(false);
  const [showUpload, setShowUpload] = React.useState(false);
  const [refresh, setRefresh] = React.useState(1);
  const [roleStatus, setRoleStatus] = React.useState<string>("init");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [listRoles, setListRoles] = React.useState<ListRolesFull | undefined>();
  const appInsights = useAppInsightsContext();
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [roleToDelete, setRoleToDelete] = React.useState<RoleToDelete>({
    id: "",
    name: "",
  });

  const [searchText, setSearchText] = React.useState<string>("");

  const { data: app } = useFetch<ApplicationDetail | undefined>(getApplication(appId));

  const { getAccessTokenSilently } = useAuth0();

  const reloadRoles = () => {
    setRefresh(refresh + 1);
  };

  React.useEffect(() => {
    let url = getRolesFull(appId, currentPage, 10);

    if (searchText.trim().length > 0 && evtName === "writing") {
      url = searchRoleFull(appId, searchText.trim(), 1, 10);
    }

    if (searchText.trim().length > 0 && evtName === "pagination") {
      url = searchRoleFull(appId, searchText.trim(), currentPage, 10);
    }

    source = verifyCancelToken(source);
    setRoleStatus("fetching");

    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.authorized(token).get(url, { cancelToken: source.token });

        setListRoles(response.data);
        setRoleStatus("fetched");
      } catch (err) {
        if (axios.isCancel(err)) {
          logger.info("Cancel request");
        } else {
          setRoleStatus("error");
          appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
          setNotification("Ocurrió un error obteniendo los roles.", "error");
        }
      }
    })();
  }, [
    evtName,
    getAccessTokenSilently,
    appId,
    appInsights,
    currentPage,
    refresh,
    setNotification,
    searchText,
  ]);

  return (
    <ApplicationRightContent>
      <MainStyled>
        <HeaderApplications active="roles" appId={appId} />

        <SubheaderApp
          appName={app?.Name ?? ""}
          appId={appId}
          setShowUpload={setShowUpload}
          setShowCreate={setShowCreate}
          setNotification={setNotification}
          pageType="role"
        />

        {showUpload && (
          <UploadFile
            url={uploadAddRolesFile(appId)}
            btnText="Carga lista de roles"
            filesAccept={[MimeType.ApplicationExcel]}
            setShowUpload={setShowUpload}
            setNotification={setNotification}
          />
        )}

        {showCreate && (
          <CreateRoleApplication
            setShowCreate={setShowCreate}
            reload={reloadRoles}
            appId={appId}
            setNotification={setNotification}
          />
        )}

        <Wrapper>
          <Grid container sm={12} md={12} lg={12} nopadding nomargin className="wrapper__actions">
            <Grid item md={6} nopadding className="wrapper__actions-left">
              <SearchInput
                id="input-search-permissions"
                placeholder="Buscar por nombre"
                className="wrapper__search-btn"
                onChange={(evt) => {
                  setSearchText(evt.currentTarget.value);
                  setCurrentPage(1);
                  setEvtName("writing");
                }}
                value={searchText}
              />
            </Grid>
          </Grid>

          {roleStatus === "fetching" && <RolesAppsTableLoader />}
          {listRoles && roleStatus === "fetched" && (
            <Table borderless>
              <TableHead>
                <TableRow>
                  <TableCell component="th">Lista de roles</TableCell>
                  <TableCell component="th">Permisos</TableCell>
                  <TableCell component="th">Usuarios</TableCell>
                  <TableCell component="th" />
                </TableRow>
              </TableHead>
              <TableBody>
                {roleStatus === "fetched" &&
                  listRoles?.Data.map((r) => (
                    <RowRoleApplication
                      key={`rowrole-${r.Id}`}
                      roleFull={r}
                      appId={appId}
                      appName={app?.Name ?? ""}
                      setShowCancelModal={setShowCancelModal}
                      setRoleToDelete={setRoleToDelete}
                    />
                  ))}
                {listRoles.Data.length === 0 && (
                  <TableRow key={0}>
                    <TableCell>No se encontraron roles.</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </Wrapper>
        {listRoles && roleStatus === "fetched" && (
          <PaginationStyled
            activePage={currentPage}
            totalPages={listRoles.TotalPages === 0 ? 1 : listRoles.TotalPages}
            clickAction={(data: paginationProps) => {
              setCurrentPage(data.goTo ?? 1);
              setEvtName("pagination");
            }}
            showFirstPage
            showLastPage
          />
        )}
      </MainStyled>

      <DeleteRoleModal
        role={roleToDelete}
        open={showCancelModal}
        setOpen={setShowCancelModal}
        reload={reloadRoles}
        setNotification={setNotification}
      />
    </ApplicationRightContent>
  );
};
export default RolesApplicationPage;
