import * as React from "react";
import { Grid, Surface } from "@tanner/partner";
import { useAuth0 } from "@auth0/auth0-react";
import FullContainer from "../../layouts/FullContainer";
import UserInfoCard from "../../components/profile/UserInfoCard";
import UserDataGrid from "../../components/profile/UserDataGrid";
import useFetch from "../../hooks/useFetch";
import { getAppsExistsUser, getAllRolesSummaryByUser } from "../../api";
import { UserApplication, UserApplicationRoles, GenericResponse } from "../../types";

const ProfilePage = () => {
  const { user } = useAuth0();
  const { status: appStatus, data: apps } = useFetch<GenericResponse<UserApplication[]>>(
    getAppsExistsUser(user.sub)
  );
  const { status: rolesStatus, data: roles } = useFetch<UserApplicationRoles>(
    getAllRolesSummaryByUser(user.sub, 1, 999)
  );
  return (
    <FullContainer title="Mi Perfil" subtitle="Información asociada a tu perfil de usuario">
      <Grid container nomargin nopadding>
        <Grid item lg={6} md={12} nomargin nopadding>
          <Surface shadow={2} radius="medium">
            <UserInfoCard name={user.name} email={user.email} />
          </Surface>
        </Grid>
        <Grid item xs={12} sm={12} nomargin nopadding>
          <Grid container nomargin nopadding>
            <Grid item lg={6} md={12} nomargin nopadding>
              <Surface shadow={2} radius="medium">
                <UserDataGrid
                  mainTitle="Mis aplicaciones asignadas"
                  headerTitle1="Nombre Aplicación"
                  headerTitle2="ID"
                  data={apps?.data}
                  isLoading={appStatus === "fetching"}
                />
              </Surface>
            </Grid>
            <Grid item lg={6} md={12} nomargin nopadding>
              <Surface shadow={2} radius="medium">
                <UserDataGrid
                  mainTitle="Mis roles asignados"
                  headerTitle1="Nombre Aplicación"
                  headerTitle2="Rol"
                  data={roles?.Data}
                  isLoading={rolesStatus === "fetching"}
                />
              </Surface>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FullContainer>
  );
};

export default ProfilePage;
