import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@tanner/partner";
import styled from "styled-components";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAuth0 } from "@auth0/auth0-react";
import { paginationProps } from "@tanner/partner/dist/core/pagination/_types";
import axios, { CancelTokenSource } from "axios";
import { NotificationState } from "../../../hooks/useNotification";

import ApplicationRightContent from "../../../layouts/ApplicationRightContent";

import HeaderApplications from "../../../components/applications/HeaderApplications";
import SubheaderApp from "../../../components/applications/SubheaderApp";
import useFetch from "../../../hooks/useFetch";
import { ApplicationDetail, UserList, UserToRemove } from "../../../types";
import { getApplication, getAllUsersByApplication, searchUserInApplication } from "../../../api";
import * as api from "../../../api";
import SearchInput from "../../../ui/SearchInput";
import RowUserApplication from "../../../components/applications/RowUserApplication";
import GenericTableLoader from "../../../components/loaders/GenericTableLoader";
import RemoveUserToApplicationModal from "../../../components/applications/RemoveUserToApplicationModal/RemoveUserToApplicationModal";
import { verifyCancelToken } from "../../../utils/http";
import logger from "../../../utils/logger";

const MainStyled = styled.div`
  padding-bottom: 25px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  padding: 0 25px 5px 25px;

  .wrapper__actions {
    display: flex;
    align-items: center;
    min-height: 90px;
    width: 100%;
    border-bottom: 1px solid #f7f7f7;

    .wrapper__actions-left {
      display: flex;
      justify-content: flex-start;

      input::-ms-input-placeholder {
        color: #afafaf !important;
      }

      input::placeholder {
        color: #afafaf !important;
      }
    }

    .wrapper__search-btn {
      width: 290px;
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0 10px;

    th {
      padding-bottom: 0 !important;
    }

    tbody {
      tr {
        td:first-child {
          padding-left: 15px !important;
        }
      }
    }

    .table__cell-delete {
      color: #3055bd;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 25px;
      cursor: pointer;

      span {
        margin-right: 10px;
      }
    }
  }
`;

const PaginationStyled = styled(Pagination)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  setNotification: (message: string, type: NotificationState) => void;
} & RouteComponentProps;

let source: CancelTokenSource;

const UsersApplicationPage: React.FC<Props> = (props) => {
  const { match, setNotification } = props;

  const { appId } = match.params as { appId: string };

  const appInsights = useAppInsightsContext();
  const { getAccessTokenSilently } = useAuth0();

  const [evtName, setEvtName] = React.useState<"writing" | "pagination" | undefined>();
  const [refresh, setRefresh] = React.useState(1);
  const [usersAppStatus, setUsersAppStatus] = React.useState<string>("init");
  const [listUsersApp, setListUsersApp] = React.useState<UserList | undefined>();
  const [currentPage, setCurrentPage] = React.useState(1);

  const [userToRemove, setUserToRemove] = React.useState<UserToRemove>({
    id: "",
    name: "",
    rut: "",
    rolId: "",
    rolName: "",
  });
  const [showRemoveModal, setShowRemoveModal] = React.useState(false);
  const [searchText, setSearchText] = React.useState<string>("");

  const { data: app } = useFetch<ApplicationDetail | undefined>(getApplication(appId));

  const reload = () => {
    setRefresh(refresh + 1);
  };

  const removeUser = (userId: string, userName: string | null) => {
    setUserToRemove({
      id: userId,
      name: userName,
      rut: "",
      rolId: "",
      rolName: "",
    });
    setShowRemoveModal(true);
  };

  React.useEffect(() => {
    let url = getAllUsersByApplication(appId, currentPage, 10);

    if (searchText.trim().length > 0 && evtName === "writing") {
      url = searchUserInApplication(appId, searchText.trim(), 1, 10);
    }

    if (searchText.trim().length > 0 && evtName === "pagination") {
      url = searchUserInApplication(appId, searchText.trim(), currentPage, 10);
    }

    source = verifyCancelToken(source);
    setUsersAppStatus("fetching");

    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.authorized(token).get(url, { cancelToken: source.token });

        setListUsersApp(response.data);
        setUsersAppStatus("fetched");
      } catch (err) {
        if (axios.isCancel(err)) {
          logger.info("Cancel request");
        } else {
          setUsersAppStatus("error");
          appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
          setNotification("Ocurrió un error obteniendo los usuarios", "error");
        }
      }
    })();
  }, [
    evtName,
    getAccessTokenSilently,
    appId,
    appInsights,
    currentPage,
    refresh,
    setNotification,
    searchText,
  ]);

  return (
    <>
      <ApplicationRightContent>
        <MainStyled>
          <HeaderApplications active="usuarios" appId={appId} />

          <SubheaderApp appName={app?.Name ?? ""} appId={appId} pageType="user" />

          <Wrapper>
            <Grid container sm={12} md={12} lg={12} nopadding nomargin className="wrapper__actions">
              <Grid item md={6} lg={6} nopadding className="wrapper__actions-left">
                <SearchInput
                  id="input-search-permissions"
                  placeholder="Buscar por nombre"
                  className="wrapper__search-btn"
                  onChange={(evt) => {
                    setSearchText(evt.currentTarget.value);
                    setCurrentPage(1);
                    setEvtName("writing");
                  }}
                  value={searchText}
                />
              </Grid>
            </Grid>

            {usersAppStatus === "fetching" && <GenericTableLoader height={600} width={800} />}
            {listUsersApp && usersAppStatus === "fetched" && (
              <Table borderless>
                <TableHead>
                  <TableRow>
                    <TableCell component="th">Lista de usuarios</TableCell>
                    <TableCell component="th">Roles asignados</TableCell>
                    <TableCell component="th" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listUsersApp?.Data.map((u) => (
                    <RowUserApplication
                      key={`rua-${u.Id}-${Math.random()}`}
                      userDataRow={u}
                      appId={appId}
                      appName={app?.Name ?? ""}
                      setNotification={setNotification}
                      reload={reload}
                      removeUser={removeUser}
                    />
                  ))}
                  {listUsersApp?.Data.length === 0 && (
                    <TableRow key={0}>
                      <TableCell>No se encontraron usuarios.</TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </Wrapper>

          {listUsersApp && usersAppStatus === "fetched" && (
            <PaginationStyled
              activePage={currentPage}
              totalPages={listUsersApp.TotalPages === 0 ? 1 : listUsersApp.TotalPages}
              clickAction={(data: paginationProps) => {
                setCurrentPage(data.goTo ?? 1);
                setEvtName("pagination");
              }}
              showFirstPage
              showLastPage
            />
          )}
        </MainStyled>
      </ApplicationRightContent>

      <RemoveUserToApplicationModal
        user={userToRemove}
        open={showRemoveModal}
        setOpen={setShowRemoveModal}
        reload={reload}
        setNotification={setNotification}
      />
    </>
  );
};

export default UsersApplicationPage;
