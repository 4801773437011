import * as React from "react";
import { TableCell } from "@tanner/partner";
import styled from "styled-components";
import ContentRowUserApplication from "./ContentRowUserApplication";
import { User } from "../../../types";
import { NotificationState } from "../../../hooks/useNotification";

type RowUserApplicationProp = {
  appId: string;
  appName: string;
  userDataRow: User;
  setNotification: (message: string, type: NotificationState) => void;
  reload: () => void;
  removeUser: (userId: string, userName: string | null) => void;
};

type TableRowStyledProp = {
  open: boolean;
};

const TableRowStyled = styled.tr<TableRowStyledProp>`
  background-color: ${(props) => (props.open ? "#e6edf8" : "#f7f7f7")};
  border: 1px solid #ebebeb !important;
  height: 50px;
  border-radius: 4px;
  font-size: 14px;
  color: #595959;
  font-weight: 400 !important;

  span {
    margin-right: 5px;
    font-size: 18px;
  }

  div.box-center {
    display: flex;
    align-items: center;
  }

  div.user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;

    span {
      font-size: 12px;
      font-weight: 300;
    }

    span:first-child {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .pointer {
    cursor: pointer;
  }

  td:first-child {
    padding-left: 15px !important;
  }

  div.table__cell-delete {
    color: #3055bd;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 25px;
    cursor: pointer;

    span {
      margin-right: 10px;
    }
  }
`;

const RowUserApplication: React.FC<RowUserApplicationProp> = ({
  appId,
  appName,
  userDataRow,
  setNotification,
  reload,
  removeUser,
}) => {
  const [open, setOpen] = React.useState(false);
  const [roleCount, setRoleCount] = React.useState(userDataRow.UserRoleCounter ?? 0);
  return (
    <>
      <TableRowStyled key={`row-user-${userDataRow.Id}`} open={open}>
        <TableCell>
          <div
            className="box-center pointer"
            role="button"
            tabIndex={-1}
            onKeyDown={() => {
              setOpen(!open);
            }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {!open ? (
              <span className="ticon-chevron-down" />
            ) : (
              <span className="ticon-chevron-up" />
            )}
            <div className="user-info">
              <span>{userDataRow.Name}</span>
              <span>{userDataRow.Email}</span>
            </div>
          </div>
        </TableCell>
        <TableCell>+{roleCount}</TableCell>
        <TableCell>
          <div
            role="button"
            tabIndex={-1}
            className="table__cell-delete"
            onClick={(e) => {
              e.preventDefault();
              removeUser(userDataRow.Id, userDataRow.Name);
            }}
            onKeyDown={(e) => {
              e.preventDefault();
              removeUser(userDataRow.Id, userDataRow.Name);
            }}
          >
            <span className="ticon-misuse" />
            Remover usuario
          </div>
        </TableCell>
      </TableRowStyled>
      {open && (
        <ContentRowUserApplication
          key={`contentrowuserapplication-${userDataRow.Id}`}
          userData={userDataRow}
          appId={appId}
          appName={appName}
          setNotificationGlobal={setNotification}
          reload={reload}
          setRoleCount={setRoleCount}
        />
      )}
    </>
  );
};

export default RowUserApplication;
