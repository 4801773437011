import * as React from "react";
import { PrimaryText, Table, TableBody, TableCell, TableHead, TableRow } from "@tanner/partner";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { RoutesPath } from "../../../utils/enums";
import { UserApplication, ApplicationRoles } from "../../../types";
import GenericTableLoader from "../../loaders/GenericTableLoader";

type Props = {
  mainTitle: string;
  headerTitle1: string;
  headerTitle2: string;
  data?: UserApplication[] | ApplicationRoles[];
  isLoading?: boolean;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 590px;
  height: 590px;
  overflow: auto;

  .cell-view {
    color: #3055bd;
    display: flex;
    justify-content: flex-end;
    padding-right: 25px;
    cursor: pointer;
    span {
      margin-right: 10px;
    }
  }
`;

const UserDataGrid: React.FC<Props> = ({
  mainTitle,
  headerTitle1,
  headerTitle2,
  data,
  isLoading,
}) => {
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  function isUserApplication(
    dataApps: UserApplication | ApplicationRoles
  ): dataApps is UserApplication {
    return (dataApps as UserApplication).id !== undefined;
  }

  return (
    <Wrapper ref={wrapperRef}>
      <PrimaryText type="h4">{mainTitle}</PrimaryText>
      {isLoading ? (
        <GenericTableLoader height={600} width={(wrapperRef?.current?.offsetWidth ?? 500) - 10} />
      ) : (
        <Table hover>
          <TableHead>
            <TableRow>
              <TableCell component="th">{headerTitle1}</TableCell>
              <TableCell component="th">{headerTitle2}</TableCell>
              <TableCell component="th">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((app: UserApplication | ApplicationRoles) => {
              if (isUserApplication(app) && (app as UserApplication)) {
                return (
                  <TableRow key={app.id}>
                    <TableCell title={headerTitle1}>{app.name}</TableCell>
                    <TableCell title={headerTitle2}>{app.id}</TableCell>
                    <TableCell>
                      <div role="button" tabIndex={-1} className="cell-view">
                        <NavLink key={app.id} to={`${RoutesPath.Applications}/:${app.id}`}>
                          <span className="ticon-view" /> Revisar
                        </NavLink>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              }

              return (
                <TableRow key={(app as ApplicationRoles).roleid}>
                  <TableCell title={headerTitle1}>
                    {(app as ApplicationRoles).applicationname}
                  </TableCell>
                  <TableCell title={headerTitle2}>{(app as ApplicationRoles).rolename}</TableCell>
                  <TableCell>
                    <div role="button" tabIndex={-1} className="cell-view">
                      <NavLink
                        key={(app as ApplicationRoles).roleid}
                        to={`${RoutesPath.Applications}/:${
                          (app as ApplicationRoles).applicationid
                        }${RoutesPath.Roles}`}
                      >
                        <span className="ticon-view" />
                        Revisar
                      </NavLink>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </Wrapper>
  );
};

export default UserDataGrid;
