import * as React from "react";
import { ErrorButton, Modal, SecondaryButton } from "@tanner/partner";
import styled from "styled-components";

const ModalStyled = styled(Modal)`
  padding-top: 25vh;

  div:first-child {
    margin-left: 0;
    margin-right: 0;
  }

  .modal-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .ticon-warning {
      font-size: 74px;
      color: #f48f00;
    }

    h4 {
      margin: 15px 0 10px 0;
      color: #005cb9;
    }

    p {
      color: #333333;
    }
  }

  div[class*="modal-title"] {
    height: 0 !important;
    margin: 0 !important;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    gap: 80px;
    width: 100%;
    margin-bottom: 10px;

    button {
      color: #005cb9;
    }

    button:first-child {
      background-color: transparent;
    }

    button:nth-child(2) {
      color: #fff;
      background-color: #f36963;
    }
  }
`;

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteUsers: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
  deleting: boolean;
};

const Body: React.FC = () => {
  return (
    <div className="modal-body">
      <span className="ticon-warning" />
      <h4>Eliminar Usuarios</h4>
      <p>Se eliminarán los usuarios seleccionados en el listado</p>
      <p>¿Estás seguro de que deseas continuar?</p>
    </div>
  );
};

type FooterProps = {
  deleting: boolean;
  handleDeleteUsers: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
} & Pick<Props, "setOpen">;

const Footer: React.FC<FooterProps> = (props) => {
  const { setOpen, handleDeleteUsers, deleting } = props;
  return (
    <div className="modal-footer">
      <ErrorButton className="btn-cancel" onClick={() => setOpen(false)}>
        Cancelar
      </ErrorButton>
      <SecondaryButton onClick={handleDeleteUsers}>
        {deleting && <span className="ticon-loading" />}
        Eliminar usuarios
      </SecondaryButton>
    </div>
  );
};

const ModalRemoveMultipleUsers: React.FC<Props> = (props) => {
  const { open, setOpen, handleDeleteUsers, deleting } = props;
  return (
    <ModalStyled
      isOpen={open}
      title=""
      size="md"
      content={<Body />}
      actions={
        <Footer setOpen={setOpen} handleDeleteUsers={handleDeleteUsers} deleting={deleting} />
      }
    />
  );
};

export default ModalRemoveMultipleUsers;
