import winston from "winston";

const logger = winston.createLogger({
  format: winston.format.combine(
    winston.format.timestamp({ format: "YYYY-MM-DD HH:mm:ss" }),
    winston.format.simple()
  ),
  transports: [new winston.transports.Console({ format: winston.format.simple() })],
});

export default logger;
