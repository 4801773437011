import React from "react";
import ContentLoader from "react-content-loader";

const UserInfoCardLoader = () => (
  <ContentLoader
    speed={2}
    width={300}
    height={60}
    viewBox="0 0 400 80"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="35" y="20" rx="0" ry="0" width="70" height="75" />
    <rect x="120" y="20" rx="0" ry="0" width="250" height="25" />
    <rect x="120" y="50" rx="0" ry="0" width="200" height="25" />
  </ContentLoader>
);

export default UserInfoCardLoader;
