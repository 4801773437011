import { Grid, PrimaryText, Select } from "@tanner/partner";
import React from "react";
import styled from "styled-components";
import { AppResume, Option } from "../../../types";
import SelectLoader from "../../loaders/SelectLoader";

const WrapperContainer = styled.div`
  justify-content: center;
  display: flex;

  .grid-no-padding {
    padding: 0;
  }
  .grid-left {
    text-align: left;
  }
  .grid-container {
    width: 656px;
  }
  .label-select {
    font-size: 16px !important;
    text-align: left;

    height: 22px;
    width: 74px;
  }
`;

interface ToolbarSelectAppProps {
  onSelect: (opt: Option) => void;
  isLoading: boolean;
  apps?: AppResume[];
}

const ToolbarSelectApp: React.FC<ToolbarSelectAppProps> = ({ onSelect, apps, isLoading }) => {
  const [select, setSelect] = React.useState({} as Option);
  const options = apps?.map((app: AppResume) => ({
    label: app.name,
    value: app.id,
  }));

  const handleChange = (opt: Option): void => {
    setSelect(opt);
    onSelect(opt);
  };

  if (isLoading) {
    return (
      <WrapperContainer>
        <Grid container sm={12} md={12} lg={12} center className="grid-container">
          <SelectLoader />
        </Grid>
      </WrapperContainer>
    );
  }

  return (
    <WrapperContainer>
      <Grid container sm={12} md={12} lg={12} center className="grid-container">
        <Grid item sm={12} md={12} lg={12} className="grid-no-padding">
          <PrimaryText type="h2">Selecciona la aplicación que deseas verificar</PrimaryText>
        </Grid>
        <Grid item sm={12} md={12} lg={12} className="grid-left ">
          <Select
            value={select}
            onChange={handleChange}
            label="Aplicación"
            options={options}
            errorText="Seleccione una aplicación"
            error={select ? select.value === "" : false}
          />
        </Grid>
      </Grid>
    </WrapperContainer>
  );
};

export default ToolbarSelectApp;
