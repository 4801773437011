import * as React from "react";
import styled from "styled-components";
import { Grid } from "@tanner/partner";
import { NavLink } from "react-router-dom";
import SearchInput from "../../../ui/SearchInput";
import ApplicationsListLoader from "../../loaders/ApplicationsListLoader";
import { RoutesPath } from "../../../utils/enums";
import { ApplicationContext } from "../../../contexts/ApplicationContext";

const ApplicationsListStyled = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dfdfdf;
  padding: 31px 0 16px 0;
  height: 100%;

  h2 {
    margin: 0 0 27px;
    color: #3055bd;
  }

  .pl-20 {
    padding-left: 20px !important;
  }

  ul {
    list-style: none;
    padding: 0;
    //overflow-y: scroll;

    a {
      text-decoration: none !important;
      color: inherit;
    }

    li {
      border: 0.5px solid #ebebeb;
      font-size: 16px;
      line-height: 22px;
      height: 50px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      cursor: pointer;
      //font-weight: 600;
    }

    .active {
      background-color: #e7eef9;
      position: relative;

      &:before {
        content: "";
        width: 6px;
        position: absolute;
        height: 50px;
        background-color: #3055bc;
        left: 0;
      }
    }

    li:hover {
      background-color: #e7eef9;
    }
  }
`;

const ApplicationsList: React.FC = () => {
  const { appsStatus, apps } = React.useContext(ApplicationContext);

  const [searchQuery, setSearchQuery] = React.useState("");

  return (
    <ApplicationsListStyled>
      <h2 className="pl-20">Lista de aplicaciones</h2>
      <Grid container className="pl-20" lg={12} nomargin nopadding>
        <Grid item lg={7} nopadding>
          <SearchInput
            id="searchByNameInput"
            placeholder="Buscar por nombre"
            onChange={(evt) => setSearchQuery(evt.currentTarget.value)}
            value={searchQuery}
          />
        </Grid>
      </Grid>
      {appsStatus === "fetching" && <ApplicationsListLoader />}
      <ul>
        {appsStatus === "fetched" &&
          apps &&
          apps.Data.filter((a) =>
            a.name.toLowerCase().trim().includes(searchQuery.toLowerCase().trim())
          ).map((a) => {
            return (
              <NavLink key={a.id} to={`${RoutesPath.Applications}/:${a.id}`}>
                <li key={a.id} className={window.location.pathname.includes(a.id) ? "active" : ""}>
                  {a.name}
                </li>
              </NavLink>
            );
          })}
      </ul>
    </ApplicationsListStyled>
  );
};

export default ApplicationsList;
