import * as React from "react";
import { VictoryPie } from "victory";

import { PrimaryText } from "@tanner/partner";
import styled from "styled-components";
import ActivityResumeLoader from "../../loaders/ActivityResumeLoader";
import { PieChartColors } from "../../../utils/enums";
import useFetch from "../../../hooks/useFetch";
import {
  AllPermissionSummary,
  ApplicationsResume,
  UserApplicationRoles,
  ListUserAuth0,
} from "../../../types";
import {
  getAllPermissionSummary,
  getAllRolesSummary,
  getAllUsersAuth0,
  getApplications,
} from "../../../api";

import { getPercentageOfTotalIsAnAmount } from "../../../utils/math";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  * {
    max-height: 90% !important;
  }

  h4 {
    margin: 0 !important;
  }

  .activity-resume-chart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    top: -10px;
  }

  .activity-resume-chart .chart {
    overflow: hidden;
  }

  .activity-resume-chart .labels {
    display: flex;
    flex-direction: column;
    align-items: start;
    flex: 1;
    margin-left: 30px;
  }

  .activity-resume-chart .labels span {
    margin-bottom: 0.8em;
    font-weight: 300;
    line-height: 1.3em;
    color: #595959;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .activity-resume-chart .labels span:before {
    content: " ";
    display: inline-block;
    background-color: ${PieChartColors.Applications};
    -moz-border-radius: 9.5px;
    -webkit-border-radius: 9.5px;
    border-radius: 9.5px;
    min-width: 19px;
    min-height: 19px;
    margin-right: 18px;
  }

  .activity-resume-chart .labels span:first-child:before {
    background-color: ${PieChartColors.Applications};
  }

  .activity-resume-chart .labels span:nth-child(2):before {
    background-color: ${PieChartColors.Users};
  }

  .activity-resume-chart .labels span:nth-child(3):before {
    background-color: ${PieChartColors.Roles};
  }

  .activity-resume-chart .labels span:nth-child(4):before {
    background-color: ${PieChartColors.Permissions};
  }
`;

const ActivityResume: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const { status: appStatus, data: apps } = useFetch<ApplicationsResume>(getApplications(1, 1));
  const { status: permissionStatus, data: permission } = useFetch<AllPermissionSummary>(
    getAllPermissionSummary(1, 1)
  );
  const { status: rolesStatus, data: roles } = useFetch<UserApplicationRoles>(
    getAllRolesSummary(1, 1)
  );
  const { status: userStatus, data: users } = useFetch<ListUserAuth0>(getAllUsersAuth0(1, 1));

  const [percentageApplications, setPercentageApplications] = React.useState(0);
  const [percentageUsers, setPercentageUsers] = React.useState(0);
  const [rolesPorcentuales, setRolesPorcentuales] = React.useState(0);
  const [permissionsPercentage, setPermissionsPercentage] = React.useState(0);
  const [dataPie, setDataPie] = React.useState([
    { x: 1, y: 35, label: "" },
    { x: 2, y: 25, label: "" },
    { x: 3, y: 35, label: "" },
    { x: 4, y: 25, label: "" },
  ]);

  React.useEffect(() => {
    const total =
      (apps?.TotalRecords ?? 0) +
      (permission?.TotalRecords ?? 0) +
      (roles?.TotalRecords ?? 0) +
      (users?.TotalRecords ?? 0);

    setPercentageApplications(getPercentageOfTotalIsAnAmount(apps?.TotalRecords ?? 0, total));
    setPercentageUsers(getPercentageOfTotalIsAnAmount(users?.TotalRecords ?? 0, total));
    setRolesPorcentuales(getPercentageOfTotalIsAnAmount(roles?.TotalRecords ?? 0, total));
    setPermissionsPercentage(getPercentageOfTotalIsAnAmount(permission?.TotalRecords ?? 0, total));

    setDataPie([
      { x: 1, y: percentageApplications, label: "" },
      { x: 2, y: percentageUsers, label: "" },
      { x: 3, y: rolesPorcentuales, label: "" },
      { x: 4, y: permissionsPercentage, label: "" },
    ]);

    if (
      appStatus === "fetched" &&
      permissionStatus === "fetched" &&
      rolesStatus === "fetched" &&
      userStatus === "fetched"
    ) {
      setLoading(false);
    }
  }, [
    appStatus,
    permissionStatus,
    rolesStatus,
    userStatus,
    apps?.TotalRecords,
    permission?.TotalRecords,
    roles?.TotalRecords,
    users?.TotalRecords,
    percentageApplications,
    percentageUsers,
    rolesPorcentuales,
    permissionsPercentage,
  ]);

  if (loading) {
    return <ActivityResumeLoader />;
  }

  return (
    <Wrapper>
      <PrimaryText type="h4">Resumen de actividad</PrimaryText>
      <div className="activity-resume-chart">
        <div className="chart">
          <VictoryPie
            width={270}
            height={270}
            style={{ labels: { display: "none" } }}
            colorScale={[
              PieChartColors.Applications,
              PieChartColors.Users,
              PieChartColors.Roles,
              PieChartColors.Permissions,
            ]}
            data={dataPie}
          />
        </div>
        <div className="labels">
          <span>{percentageApplications}% Aplicaciones</span>
          <span>{percentageUsers}% Usuarios</span>
          <span>{rolesPorcentuales}% Roles</span>
          <span>{permissionsPercentage}% Permisos</span>
        </div>
      </div>
    </Wrapper>
  );
};

export default ActivityResume;
