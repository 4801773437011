import * as React from "react";
import { Grid, PrimaryButton, PrimaryText, Surface, TextField } from "@tanner/partner";
import styled from "styled-components";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { api, createPermission } from "../../../api";
import { useForm } from "../../../hooks/useForm";
import { CreatePermission, Option } from "../../../types";
import { NotificationState } from "../../../hooks/useNotification";

const WrapperSurface = styled(Surface)<{ error: boolean }>`
  height: 350px;
  justify-content: center;
  display: flex;
  margin: 0 0 15px 0;

  h2 {
    margin-bottom: 16px;
  }

  .grid-center {
    justify-content: center;
    display: flex;
  }
  .grid-container {
    width: 400px;
  }
  .header-box-btn {
    flex-direction: row;
    justify-content: space-between;

    button {
      height: 44px;
      width: 180px;
      justify-content: center;
    }

    .btn-cancel {
      color: #005cb9;
      background-color: transparent;
      border: 1px solid #005cb9 !important;
    }
  }

  .input-w {
    height: 49px;
    width: 100%;
    border-radius: 4px;
  }
  .label-w {
    margin-bottom: ${(props) => (props.error ? "35px" : "0")};
    text-align: left;
    width: 100%;
  }
`;

interface Props {
  clickHandlerCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  setShowNewPermit: (state: boolean) => void;
  selectApp: Option;
  setNotification: (message: string, type: NotificationState) => void;
  reload: () => void;
}

const initialForm = {
  permission: "",
  description: "",
};

const PermissionAdd: React.FC<Props> = ({
  clickHandlerCancel,
  setShowNewPermit,
  selectApp,
  setNotification,
  reload,
}) => {
  const { value } = selectApp;
  const appInsights = useAppInsightsContext();
  const errorInit = {
    status: false,
    message: "",
  };
  const [errorPermission, setErrorPermission] = React.useState(errorInit);
  const [errorDescription, setErrorDescription] = React.useState(errorInit);

  const [isSubmitting, setSubmitting] = React.useState(false);

  const { permission, description, onChange } = useForm(initialForm);

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorPermission(errorInit);
    setErrorDescription(errorInit);

    if (permission.trim().length < 5) {
      setErrorPermission({
        status: true,
        message: "Ingresar un nombre para el permiso",
      });
      return;
    }

    if (permission.trim().length > 30) {
      setErrorPermission({
        status: true,
        message: "El permiso puede tener un máximo de 30 caracteres.",
      });
      return;
    }

    if (description.trim().length > 50) {
      setErrorDescription({
        status: true,
        message: "La descripción puede tener un máximo de 50 caracteres.",
      });
      return;
    }

    setSubmitting(true);
    const request: CreatePermission = {
      action: permission.trim(),
      description: description.trim(),
    };

    try {
      await api.post(createPermission(value), request);
      setSubmitting(false);
      setShowNewPermit(false);
      setNotification("Permiso creado correctamente", "success");
      reload();
    } catch (err) {
      setNotification("Ocurrió un problema creando el permiso.", "error");
      setShowNewPermit(false);
      setSubmitting(false);

      appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
    }
  };

  return (
    <WrapperSurface
      shadow={1}
      radius="medium"
      error={errorPermission.status || errorDescription.status}
    >
      <Grid
        container
        sm={12}
        md={12}
        lg={12}
        center
        nomargin
        nopadding
        className="grid-center grid-container"
      >
        <Grid item sm={12} md={12} lg={12} nopadding>
          <PrimaryText type="h2">Ingresa información del nuevo Permisos</PrimaryText>
        </Grid>
        <Grid item sm={12} md={12} lg={12} className="grid-center" nopadding>
          <label htmlFor="permissionName" className="label-w">
            Crear nuevo Permiso
            <TextField
              className="input-w"
              id="permission"
              placeholder="Ingresa el nombre del nuevo Permiso"
              value={permission}
              onChange={({ target }) => onChange(target.value, "permission")}
              error={errorPermission.status}
              errorText={errorPermission.message}
            />
          </label>
        </Grid>
        <Grid item sm={12} md={12} lg={12} className="grid-center" nopadding>
          <label htmlFor="descriptionName" className="label-w">
            Descripción
            <TextField
              className="input-w"
              id="description"
              placeholder="Ingresa el descripción del nuevo Permiso"
              value={description}
              onChange={({ target }) => onChange(target.value, "description")}
              error={errorDescription.status}
              errorText={errorDescription.message}
            />
          </label>
        </Grid>
        <Grid item sm={12} md={12} lg={12} className="grid-center header-box-btn" nopadding>
          <PrimaryButton onClick={clickHandlerCancel} className="btn-cancel">
            Cancelar
          </PrimaryButton>
          <PrimaryButton onClick={handleSubmitForm} disabled={isSubmitting}>
            {isSubmitting && <span className="ticon-loading" />}
            Guardar
          </PrimaryButton>
        </Grid>
      </Grid>
    </WrapperSurface>
  );
};

export default PermissionAdd;
