import * as React from "react";
import styled from "styled-components";
import { Avatar, Exit } from "@tanner/icons";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { RoutesPath } from "../../../utils/enums";
import SearchInput from "../../../ui/SearchInput";
import { AuthUserApplication } from "../../../types";
import { getApplicationsFromUserAuth } from "../../../utils/user";

const ProfileSelect = styled.div``;

type WrapperProps = {
  open: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 318px;
  height: 55px;
  background-color: #3e6aec;
  border-radius: 4px 4px ${(props) => (props.open ? 0 : "4px")}
    ${(props) => (props.open ? 0 : "4px")};
  padding: 11px 18px 11px 15px;
  color: #fff;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 18px -1px rgba(0, 0, 0, 0.37) !important;
    -webkit-box-shadow: 0 0 18px -1px rgba(0, 0, 0, 0.37) !important;
    -moz-box-shadow: 0 0 18 -1px rgba(0, 0, 0, 0.37) !important;
  }

  .profile-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  svg {
    fill: #fff;
  }

  .profile-bar-info-user {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 19px;
  }

  .profile-bar-info-user span:first-child {
    font-weight: 300;
  }

  .profile-bar-info-user span:nth-child(2) {
    font-weight: 600;
  }
`;

const ProfileBarSelect = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  transition: 0.6s;
  overflow: hidden;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16), 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 1px 3px 0 rgba(0, 0, 0, 0.16);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: ${(props) => (props.open ? "330px" : 0)};

  .list-apps {
    overflow-y: scroll;
    flex: 1;

    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .search-input {
    padding: 5px 15px;

    .ticon-search {
      color: #646464;
    }
  }

  header {
    padding: 0 25px 0 16px;
    color: #727171;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    min-height: 30px;
    display: flex;
    align-items: flex-end;
  }
`;

const Option = styled.a`
  padding: 0 10px 0 16px;
  border-bottom: 0.5px solid #ededed;
  cursor: pointer;
  min-height: 43px;
  display: flex;
  align-items: center;
  color: #727171;
  line-height: 19px;

  :hover {
    background-color: #e7eef9;
  }

  p {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
  }

  span[class^="ticon"] {
    font-size: 22px;
    color: #3055bd;
  }

  span.ticon-chevron-right {
    color: #727171;
    position: relative;
    right: 0;
  }

  &:last-child {
    border-style: none !important;
  }
`;

const Footer = styled.footer`
  background-color: #0073e8;
  padding: 0 16px 0 16px;
  min-height: 57px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #fff;

  position: relative;
  bottom: 0;
`;

const FooterAction = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  cursor: pointer;

  svg {
    fill: #fff;
  }
  span {
    margin-left: 10px;
  }
`;

const FooterProfileBar: React.FC = () => {
  const history = useHistory();
  const { logout } = useAuth0();
  return (
    <Footer>
      <FooterAction
        onClick={() => history.push(RoutesPath.Profile)}
        onKeyDown={() => history.push(RoutesPath.Profile)}
      >
        <Avatar />
        <span tabIndex={0} role="button">
          Mi perfil
        </span>
      </FooterAction>
      <FooterAction onClick={() => logout()}>
        <Exit />
        <span>Cerrar sesión</span>
      </FooterAction>
    </Footer>
  );
};

const ProfileBar: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [apps, setApps] = React.useState<AuthUserApplication[]>([] as AuthUserApplication[]);

  const { user } = useAuth0();

  React.useEffect(() => {
    const appsUser = getApplicationsFromUserAuth(user);
    setApps(appsUser);
  }, [user]);

  return (
    <ProfileSelect>
      <Wrapper
        onClick={() => setOpen(!open)}
        open={open}
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key !== "9") setOpen(!open);
        }}
      >
        <div className="profile-bar">
          <div className="profile-bar-info-user">
            <span>Hola!</span>
            <span>{user.email}</span>
          </div>
          <Avatar />
        </div>
      </Wrapper>
      <ProfileBarSelect open={open}>
        <header>Acceso aplicaciones del usuario</header>
        <div className="search-input">
          <SearchInput
            placeholder="Buscar aplicaciones"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
          />
        </div>
        <div className="list-apps">
          {apps
            ?.filter((a) => a.name.toLowerCase().trim().includes(searchTerm.toLowerCase().trim()))
            .map((a) => (
              <Option key={a.id} target="_blank" href={a.initiateloginuri}>
                <p>{a.name}</p>
                <span className="ticon-chevron-right" />
              </Option>
            ))}
        </div>
        <FooterProfileBar />
      </ProfileBarSelect>
    </ProfileSelect>
  );
};

export default ProfileBar;
