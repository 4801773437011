import * as React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Grid, PrimaryButton, Select, TextArea, TextField } from "@tanner/partner";
import styled from "styled-components";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ApplicationRightContent from "../../../layouts/ApplicationRightContent";
import HeaderApplications from "../../../components/applications/HeaderApplications";
import ArpLightButton from "../../../ui/ArpLightButton";
import { editApplication, deleteApplication, getApplication } from "../../../api";
import * as api from "../../../api";
import {
  ApplicationDetail,
  ApplicationFormValues,
  EditApplicationRequest,
  optAppTypes,
  optAppTypesFromNumber,
  Option,
  ApplicationToDelete,
  ErrorApi,
  GenericResponse,
} from "../../../types";
import { RoutesPath } from "../../../utils/enums";
import { sanitizeIdForQuery } from "../../../utils/string";
import useFetch from "../../../hooks/useFetch";
import { NotificationState } from "../../../hooks/useNotification";
import DeleteApplicationModal from "../../../components/applications/DeleteApplicationModal/DeleteApplicationModal";
import { ApplicationContext } from "../../../contexts/ApplicationContext";

const HeaderStyled = styled(Grid)`
  width: 100%;
  min-height: 86px;
  background-color: #f1e2f5;
  color: #3055bd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 31px 25px;

  .header-box-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;

    button {
      width: 180px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }

    button + button {
      margin-left: 20px;
    }

    .btn-cancel {
      color: #005cb9;
      background-color: transparent;
      border: 1px solid #005cb9 !important;
    }
  }

  h2 {
    flex: 1;
    margin: 5px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .body__selector {
    margin-bottom: 25px;
  }

  span[class*="indicatorSeparator"] {
    visibility: hidden;
  }

  div[class*="ValueContainer"] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 100%;
    display: flex !important;
    align-items: center !important;
    position: center;
  }

  div[class*="singleValue"] {
    color: #595959;
    padding-top: 4px;
    padding-left: 5px;
  }

  .header {
    width: 100%;
    min-height: 89px;
    background-color: #f1e2f5;
    color: #3055bd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 31px 25px;

    .header-box-btn {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex: 1;

      button {
        width: 180px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }

      button + button {
        margin-left: 20px;
      }

      .btn-cancel {
        color: #005cb9;
        background-color: transparent;
        border: 1px solid #005cb9 !important;
      }
    }

    h2 {
      flex: 1;
      margin: 5px;
    }
  }

  .body {
    padding: 29px 26px;
    color: #afafaf;
    font-weight: 300;
    line-height: 22px;

    .body__checkbox {
      display: flex;
      align-items: flex-start;

      span {
        margin-left: 10px;
      }
    }

    p {
      margin: 0 0 25px;
      color: #595959;
    }

    .body__labelTitle {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-weight: 400;
      line-height: 21px;
      flex-wrap: wrap;

      span:nth-child(2) {
        color: #a3a3a3;
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
      }
    }

    textarea {
      resize: none;
    }

    input,
    textarea {
      padding: 11px 20px 11px 15px !important;
      margin: 0 !important;

      ::placeholder {
        color: #bcbcbc;
      }

      ::-ms-input-placeholder {
        color: #bcbcbc;
      }
    }
  }
`;

type FieldStyledProps = {
  error?: boolean;
};

const FieldStyled = styled(Field)<FieldStyledProps>`
  padding: 0;
  margin-bottom: ${(props) => (props.error ? "10px" : "25px")};
  margin-top: 4px;

  span {
    margin: 0;
    white-space: pre;
    height: 15px;
  }
`;

type InfoAppFormValues = {
  appId: string;
  clientId: string;
  clientSecret: string;
} & ApplicationFormValues;

type Props = {
  setNotification: (message: string, type: NotificationState) => void;
} & RouteComponentProps;

const InfoApplicationPage: React.FC<Props> = (props) => {
  const { match, setNotification } = props;
  const { appId } = match.params as { appId: string };
  const history = useHistory();

  const [select, setSelect] = React.useState({} as Option);
  const [editing, setEditing] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const { data: app } = useFetch<GenericResponse<ApplicationDetail> | undefined>(
    getApplication(appId)
  );

  const [applicationToDelete, setApplicationToDelete] = React.useState<ApplicationToDelete>({
    id: "",
    name: "",
  });
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const { getAccessTokenSilently } = useAuth0();

  React.useEffect(() => {
    setSelect(optAppTypesFromNumber[app?.data?.AppType ?? ""]);
  }, [app]);

  const appInsights = useAppInsightsContext();

  const { reload } = useContext(ApplicationContext);

  const handleDeleteApp = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setDeleting(true);
    e.preventDefault();

    const token = await getAccessTokenSilently();

    api
      .authorized(token)
      .delete(deleteApplication(appId))
      .then(() => {
        history.push(`${RoutesPath.Applications}`);
        setNotification("Aplicación eliminada correctamente", "success");
        reload();
      })
      .catch((err) => {
        setNotification("Ocurrió un problema eliminando la aplicación.", "error");
        appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const handleEditApp = async (
    values: InfoAppFormValues,
    actions: FormikHelpers<InfoAppFormValues>
  ) => {
    const { setSubmitting } = actions;
    setSubmitting(true);
    const request: EditApplicationRequest = {
      Id: sanitizeIdForQuery(appId),
      Name: values.name,
      Description: values.description,
      Callbacks: values.callbacks.split(","),
      InitiateLoginUri: values.initiateloginuri,
      AllowedOrigins: values.allowedorigins.split(","),
      AllowedLogoutUrls: values.allowedlogouturls.split(","),
      AllowedClients: values.allowedclients.split(","),
      AppType: values.apptype,
      ShowInUserAppList: values.showinuserapplist,
      ClientId: values.clientId,
      ClientSecret: values.clientSecret,
    };

    const token = await getAccessTokenSilently();

    api
      .authorized(token)
      .put(editApplication(appId), request)
      .then(() => {
        setNotification("Aplicación actualizada correctamente", "success");
        reload();
        history.push(`${RoutesPath.Applications}/${appId}`);
      })
      .catch((err) => {
        const errorApi = err.response.data as ErrorApi;
        setNotification(`${errorApi.message} (ID:${errorApi.traceid})`, "error");
        appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
      })
      .finally(() => {
        setEditing(false);
        setSubmitting(false);
      });
  };

  return (
    <ApplicationRightContent>
      <Formik
        enableReinitialize
        initialValues={
          {
            appId: app?.data?.Id ?? "",
            name: app?.data?.Name ?? "",
            description: app?.data?.Description ?? "",
            callbacks: app?.data?.Callbacks?.join(", ") ?? "",
            initiateloginuri: app?.data?.InitiateLoginUri ?? "",
            allowedorigins: app?.data?.AllowedOrigins?.join(", ") ?? "",
            allowedlogouturls: app?.data?.AllowedLogoutUrls?.join(", ") ?? "",
            allowedclients: app?.data?.AllowedClients?.join(", ") ?? "",
            showinuserapplist: app?.data?.ShowInUserAppList ?? false,
            clientId: app?.data?.ClientId ?? "",
            clientSecret: app?.data?.ClientSecret ?? "",
            apptype: app?.data?.AppType ?? "",
          } as InfoAppFormValues
        }
        onSubmit={handleEditApp}
      >
        {(formikProp: FormikProps<InfoAppFormValues>) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            setFieldValue,
            resetForm,
            isSubmitting,
          } = formikProp;

          return (
            <Form>
              <HeaderApplications active="info" appId={appId} />
              <HeaderStyled container md={12} nopadding nomargin>
                <Grid item md={6} sm={12} nopadding>
                  <h2>{values?.name}</h2>
                </Grid>
                {!editing ? (
                  <Grid item md={6} sm={12} className="header-box-btn" nopadding>
                    <ArpLightButton
                      color="primary"
                      text={deleting ? "Eliminando aplicación" : "Eliminar aplicación"}
                      icon={deleting ? "ticon-loading" : "ticon-delete"}
                      onClick={(e) => {
                        e.preventDefault();
                        setApplicationToDelete({
                          id: sanitizeIdForQuery(appId),
                          name: values?.name,
                        });
                        setShowDeleteModal(true);
                      }}
                    />
                    <ArpLightButton
                      color="primary"
                      text="Editar información"
                      icon="ticon-edit"
                      onClick={(e) => {
                        e.preventDefault();
                        setEditing(true);
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid item md={6} sm={12} className="header-box-btn" nopadding>
                    <PrimaryButton
                      className="btn-cancel"
                      onClick={(e) => {
                        e.preventDefault();
                        setEditing(false);
                        resetForm();
                        setSelect(optAppTypesFromNumber[app?.data?.AppType ?? ""]);
                      }}
                    >
                      Cancelar
                    </PrimaryButton>
                    <PrimaryButton disabled={isSubmitting}>
                      {isSubmitting && <span className="ticon-loading" />}
                      Guardar
                    </PrimaryButton>
                  </Grid>
                )}
              </HeaderStyled>
              <Wrapper>
                <Grid className="body" container sm={12}>
                  <Grid md={6} sm={12} item>
                    <p>Detalle de información de la aplicación</p>

                    <label htmlFor="appId">
                      <span>Id aplicación</span>
                      <FieldStyled disabled as={TextField} value={values.appId} name="appId" />
                    </label>

                    <label htmlFor="appName">
                      <span>Nombre aplicación</span>
                      <FieldStyled
                        as={TextField}
                        readOnly={!editing}
                        error={touched.name && errors.name}
                        value={values.name}
                        onChange={handleChange("name")}
                        name="appName"
                        placeholder="Ingresa el nombre de la aplicación"
                        errorText={errors.name}
                        maxLength={30}
                      />
                    </label>

                    <label htmlFor="description">
                      <div className="body__labelTitle">
                        <span>Descripción</span>
                        <span>Máximo 200 carácteres</span>
                      </div>
                      <FieldStyled
                        as={TextArea}
                        maxLength={200}
                        rows={4}
                        cols={50}
                        readOnly={!editing}
                        error={touched.description && errors.description}
                        value={values.description}
                        onChange={handleChange("description")}
                        name="description"
                        placeholder="Ingresa una descripción para la aplicación"
                        errorText={errors.description}
                      />
                    </label>

                    <label htmlFor="clientId">
                      <span>Id cliente</span>
                      <FieldStyled
                        as={TextField}
                        readOnly={!editing}
                        error={touched.clientId && errors.clientId}
                        value={values.clientId}
                        onChange={handleChange("clientId")}
                        name="clientId"
                        placeholder="Ingresa el id del cliente"
                        errorText={errors.clientId}
                        disabled
                      />
                    </label>

                    <label htmlFor="clientSecret">
                      <span>Secreto cliente</span>
                      <FieldStyled
                        as={TextField}
                        readOnly={!editing}
                        error={touched.clientSecret && errors.clientSecret}
                        value={values.clientSecret}
                        onChange={handleChange("clientSecret")}
                        name="clientSecret"
                        placeholder="Ingresa el secreto del cliente"
                        errorText={errors.clientSecret}
                        disabled
                      />
                    </label>

                    <div className="body__selector">
                      <label htmlFor="appType">
                        <span>Tipo de aplicación</span>
                        <FieldStyled
                          as={Select}
                          disabled={!editing}
                          error={touched.apptype && errors.apptype}
                          errorText={errors.apptype}
                          name="appType"
                          placeholder="Selecciona Tipo de Aplicación"
                          value={select}
                          options={optAppTypes}
                          onChange={(opt: Option) => {
                            setFieldValue("apptype", opt.value);
                            setSelect(opt);
                          }}
                        />
                      </label>
                    </div>

                    <label htmlFor="urlLogin">
                      <div className="body__labelTitle">
                        <span>URL iniciar sesión (https)</span>
                        <span>Ej: https://example.com</span>
                      </div>
                      <FieldStyled
                        as={TextField}
                        readOnly={!editing}
                        error={touched.initiateloginuri && errors.initiateloginuri}
                        value={values.initiateloginuri}
                        onChange={handleChange("initiateloginuri")}
                        name="urlLogin"
                        placeholder="Ingresa URL de la aplicación"
                        errorText={errors.initiateloginuri}
                      />
                    </label>

                    <label htmlFor="favoriteCheck" className="body__checkbox">
                      <FieldStyled
                        type="checkbox"
                        disabled={!editing}
                        name="showinuserapplist"
                        checked={values.showinuserapplist}
                      />
                      <span>
                        ¿Deseas agregar esta aplicación en el listado de accesos del menú de
                        usuario?
                      </span>
                    </label>
                  </Grid>
                  <Grid md={6} sm={12} item>
                    <p>URL&apos;s para los diferentes accesos</p>

                    <label htmlFor="callbacks">
                      <div className="body__labelTitle">
                        <span>URL callbacks</span>
                        <span>Ej: https://example.com</span>
                      </div>
                      <FieldStyled
                        as={TextField}
                        readOnly={!editing}
                        error={touched.callbacks && errors.callbacks}
                        value={values.callbacks}
                        onChange={handleChange("callbacks")}
                        name="callbacks"
                        placeholder="Ingresa URLs para callbacks"
                        errorText={errors.callbacks}
                      />
                    </label>

                    <label htmlFor="urlAllowedOrigins">
                      <div className="body__labelTitle">
                        <span>URL orígenes permitidos</span>
                        <span>Ej: https://example.com</span>
                      </div>
                      <FieldStyled
                        as={TextField}
                        readOnly={!editing}
                        error={touched.allowedorigins && errors.allowedorigins}
                        value={values.allowedorigins}
                        onChange={handleChange("allowedorigins")}
                        name="urlAllowedOrigins"
                        placeholder="Ingresa URLs para orígenes permitidos"
                        errorText={errors.allowedorigins}
                      />
                    </label>

                    <label htmlFor="urlAllowedClients">
                      <div className="body__labelTitle">
                        <span>URL clientes permitidos</span>
                        <span>Ej: https://example.com</span>
                      </div>
                      <FieldStyled
                        as={TextField}
                        readOnly={!editing}
                        error={touched.allowedclients && errors.allowedclients}
                        value={values.allowedclients}
                        onChange={handleChange("allowedclients")}
                        name="urlAllowedClients"
                        placeholder="Ingresa URL para clientes permitidos"
                        errorText={errors.allowedclients}
                      />
                    </label>

                    <label htmlFor="urlAllowedLogouts">
                      <div className="body__labelTitle">
                        <span>URL cierre de sesión permitidas</span>
                        <span>Ej: https://example.com</span>
                      </div>
                      <FieldStyled
                        as={TextField}
                        readOnly={!editing}
                        error={touched.allowedlogouturls && errors.allowedlogouturls}
                        value={values.allowedlogouturls}
                        onChange={handleChange("allowedlogouturls")}
                        name="urlAllowedLogouts"
                        placeholder="Ingresa URL para cierre de sesión permitidas"
                        errorText={errors.allowedlogouturls}
                      />
                    </label>
                  </Grid>
                </Grid>
              </Wrapper>
            </Form>
          );
        }}
      </Formik>

      <DeleteApplicationModal
        app={applicationToDelete}
        handleDeleteApp={handleDeleteApp}
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        deleting={deleting}
      />
    </ApplicationRightContent>
  );
};

export default InfoApplicationPage;
