import * as React from "react";

import {
  Checkbox,
  Pagination,
  PrimaryText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
} from "@tanner/partner";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { paginationProps } from "@tanner/partner/dist/core/pagination/_types";
import axios, { CancelTokenSource } from "axios";

import { CheckAppType, UserList, UserToRemove } from "../../../types";
import { deleteUseraAth0, listUsersSummary, listSearchUsersSummary } from "../../../api";
import * as api from "../../../api";

import { NotificationState } from "../../../hooks/useNotification";
import RowUserItem from "../UserRowItem/UserRowItem";
import GenericTableLoader from "../../loaders/GenericTableLoader";
import ModalRemoveUser from "../ModalRemoveUser/ModalRemoveUser";
import ModalRemoveMultipleUsers from "../ModalRemoveMultipleUsers/ModalRemoveMultipleUsers";
import SearchInput from "../../../ui/SearchInput/SearchInput";
import ArpLightButton from "../../../ui/ArpLightButton/ArpLightButton";
import { not, numberOfChecked, union } from "../../../utils/math";
import { verifyCancelToken } from "../../../utils/http";
import logger from "../../../utils/logger";

const Wrapper = styled.div`
  padding: 0 4px 5px 4px;
`;

const WrapperTable = styled.div`
  .header {
    background-color: #f1fae5;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8em;
  }

  .subheader {
    border-bottom: 1px solid #f7f7f7;
    padding: 0.8em;
  }

  .wrapper__actions {
    display: flex;
    align-items: center;
    min-height: 90px;
    width: 100%;
    border-bottom: 1px solid #f7f7f7;

    .wrapper__actions-left {
      display: flex;
      justify-content: flex-start;

      input::-ms-input-placeholder {
        color: #afafaf !important;
      }

      input::placeholder {
        color: #afafaf !important;
      }
    }

    .wrapper__search-btn {
      width: 290px;
    }

    .wrapper__actions-right {
      display: flex;
      justify-content: flex-end;

      button {
        margin-right: 10px;
        width: 290px;
        height: 42px;
      }
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0 10px;

    th {
      padding-bottom: 0 !important;
    }

    thead tr {
      color: #afafaf;
      font-size: 14px;
      font-weight: 600;

      th:first-child {
        padding-left: 15px !important;
      }
    }

    tbody {
      tr {
        border: 1px solid #ebebeb !important;
        // background-color: #EBEBEB;
        height: 50px;
        border-radius: 4px;

        td:first-child {
          padding-left: 15px !important;
        }

        td:nth-child(7) {
          width: 110px;
        }

        td:nth-child(8) {
          width: 200px;
        }

        td:nth-child(9) {
          width: 120px;
        }
      }
    }

    .table__cell-delete {
      color: #3055bd;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 25px;
      cursor: pointer;

      span {
        margin-right: 10px;
      }
    }
  }
`;

const PaginationStyled = styled(Pagination)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Toolbar = styled(Grid)`
  padding-bottom: 20px;
  border-bottom: 1px solid #f7f7f7;
  margin-left: 0;
  margin-right: 0;

  & .left-toolbar {
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    padding: 0 !important;
    margin: 0 !important;

    p {
      margin-top: 0;
    }

    .input-search-users {
      margin-right: 10px;
      width: 298px;
      height: 42px;
    }

    .btn-filters {
      margin-right: 10px;
      width: 156px;
      height: 42px;
    }

    .btn-clean-filters {
      width: 156px;
      height: 44px;
    }
  }

  & .right-toolbar {
    justify-content: flex-end;
    align-items: flex-start;
    display: flex;
    padding: 0;

    .btn-remove-users {
      width: 285px;
      height: 44px;
    }
  }
`;

type UsersListCardProps = {
  setNotification: (message: string, type: NotificationState) => void;
  reload: () => void;
  refresh: number;
};

type ResponseApi = {
  code: number;
  message: string;
};

type ErrorApi = {
  id: string;
  message: string;
};
let source: CancelTokenSource;

const UsersListCard: React.FC<UsersListCardProps> = ({ setNotification, reload, refresh }) => {
  const [evtName, setEvtName] = React.useState<"writing" | "pagination" | undefined>();
  const [listUsersStatus, setListUsersStatus] = React.useState<string>("init");
  const [listUsers, setListUsers] = React.useState<UserList | undefined>();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [showRemoveModal, setShowRemoveModal] = React.useState(false);
  const [showRemoveMultipleModal, setShowRemoveMultipleModal] = React.useState(false);
  const [userToRemove, setUserToRemove] = React.useState<UserToRemove>({
    id: "",
    name: "",
    rut: "",
    rolId: "",
    rolName: "",
  });
  const [selected, setSelected] = React.useState<number[]>([]);
  const [positions] = React.useState<number[]>([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const [listCheckUsers, setListCheckUsers] = React.useState<CheckAppType[]>([]);
  const [isRemoving, setIsRemoving] = React.useState(false);
  const [searchText, setSearchText] = React.useState<string>("");

  const tableRef = React.useRef<HTMLDivElement | null>(null);

  const appInsights = useAppInsightsContext();
  const { getAccessTokenSilently } = useAuth0();

  const handleAllCheck = () => () => {
    const tempList = listCheckUsers;
    if (numberOfChecked(selected, positions) === positions.length) {
      setSelected(not(selected, positions));
      /* eslint no-param-reassign: "error" */
      tempList.forEach((check) => {
        check.isChecked = false;
      });
    } else {
      /* eslint no-param-reassign: "error" */
      tempList.forEach((check) => {
        check.isChecked = true;
      });
      setSelected(union(selected, positions));
    }

    setListCheckUsers(tempList);
  };

  const updateCheckUser = (userId: string) => {
    const elementsIndex = listCheckUsers.findIndex((element) => element.value === userId);
    const newArray = [...listCheckUsers];

    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      isChecked: !newArray[elementsIndex].isChecked,
    };

    setListCheckUsers(newArray);
  };

  const handleRowCheck = (value: number, userId: string) => {
    const currentIndex = selected.indexOf(value);
    const newSelected = [...selected];

    updateCheckUser(userId);

    if (currentIndex === -1) {
      newSelected.push(value);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
  };

  const handleDeleteUserSelected = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsRemoving(true);
    e.preventDefault();

    const tempList = listCheckUsers;
    const ListPromise: Promise<ResponseApi | PromiseRejectedResult>[] = [];
    const token = await getAccessTokenSilently();

    tempList.forEach((check) => {
      if (check.isChecked) {
        const r = new Promise<ResponseApi | PromiseRejectedResult>((resolve, reject) => {
          const url = deleteUseraAth0(check.value);

          api
            .authorized(token)
            .delete(url)
            .then((response) => {
              resolve(response.data as ResponseApi);
            })
            .catch((err) => {
              reject(err);
            });
        });
        ListPromise.push(r);
      }
    });

    const data = await Promise.allSettled(ListPromise);

    const err = (data.find((res) => res.status === "rejected") as PromiseRejectedResult | undefined)
      ?.reason;

    if (err) {
      setIsRemoving(false);
      setSelected([]);
      setShowRemoveMultipleModal(false);
      reload();
      if (err.response.status === 409) {
        const errorApi = err.response.data as ErrorApi;
        setNotification(errorApi.message, "warning");
      } else {
        setNotification("Ocurrió un problema eliminando los usuarios.", "error");
        appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
      }
    } else {
      setSelected([]);
      setIsRemoving(false);
      setShowRemoveMultipleModal(false);
      reload();
      setNotification("Usuarios eliminados.", "success");
    }
  };

  React.useEffect(() => {
    let url = listUsersSummary(currentPage, 10);

    if (searchText.trim().length >= 3 && evtName === "writing") {
      url = listSearchUsersSummary(searchText.trim(), 1, 10);
    } else if (searchText.trim().length >= 3 && evtName === "pagination") {
      url = listSearchUsersSummary(searchText.trim(), currentPage, 10);
    } else if (searchText.trim().length > 0) return;

    source = verifyCancelToken(source);
    setListUsersStatus("fetching");
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.authorized(token).get(url, { cancelToken: source.token });
        setListUsers(response.data);
        setSelected([]);
        setListCheckUsers([]);
        (response.data as UserList).Data.map((u) =>
          setListCheckUsers((prevState) => [
            ...prevState,
            { value: u.Id, label: u.Name, isChecked: false },
          ])
        );

        setListUsersStatus("fetched");
      } catch (err) {
        if (axios.isCancel(err)) {
          logger.info("Cancel request");
        } else {
          setListUsersStatus("error");
          appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
        }
      }
    })();
  }, [getAccessTokenSilently, appInsights, currentPage, searchText, evtName, refresh]);

  return (
    <>
      <Wrapper>
        <PrimaryText type="h5">Lista de usuarios</PrimaryText>
        <WrapperTable ref={tableRef}>
          <Toolbar container lg={12} md={12} sm={12}>
            <Grid item className="left-toolbar" lg={6} md={12} sm={12}>
              <p>
                Para realizar una búsqueda debes escribir al menos 3 caracteres del nombre, email o
                ingresar el rut completo del usuario sin puntos, con guión y dígito verificador.
              </p>
            </Grid>
            <Grid item className="left-toolbar" lg={9} md={12} sm={12}>
              <SearchInput
                id="input-search-users"
                className="input-search-users"
                placeholder="Buscar usuarios"
                onChange={(evt) => {
                  setSearchText(evt.currentTarget.value);
                  setCurrentPage(1);
                  setEvtName("writing");
                }}
                value={searchText}
              />
            </Grid>
            <Grid item className="right-toolbar" lg={3} md={12} sm={12}>
              <ArpLightButton
                disabled={selected.length === 0 || isRemoving}
                color="primary"
                text="Eliminar usuarios seleccionados"
                icon={isRemoving ? "ticon-loading" : "ticon-misuse"}
                className="btn-remove-users"
                onClick={() => {
                  setShowRemoveMultipleModal(true);
                }}
              />
            </Grid>
          </Toolbar>
          {listUsersStatus === "fetching" && (
            <GenericTableLoader
              width={(tableRef?.current?.offsetWidth ?? 1150) - 10}
              height={680}
            />
          )}
          {listUsers && listUsersStatus === "fetched" && (
            <Table borderless>
              <TableHead>
                <TableRow>
                  <TableCell component="th">
                    <Checkbox
                      value="all"
                      label=""
                      onChange={handleAllCheck()}
                      checked={
                        numberOfChecked(selected, positions) === positions.length &&
                        positions.length !== 0
                      }
                    />
                  </TableCell>
                  <TableCell component="th">Nombre de usuarios</TableCell>
                  <TableCell component="th">RUT</TableCell>
                  <TableCell component="th">Email</TableCell>
                  <TableCell component="th">Roles asignados</TableCell>
                  <TableCell component="th" />
                  <TableCell component="th" />
                  <TableCell component="th" />
                  <TableCell component="th" />
                </TableRow>
              </TableHead>
              <TableBody>
                {listUsers && listUsersStatus === "fetched" && listUsers.Data.length === 0 && (
                  <TableRow key={0}>
                    <TableCell />
                    <TableCell title="Nombre de usuarios">No existen usuarios</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                )}
                {listUsersStatus === "fetched" &&
                  listUsers.Data.map((u, index) => (
                    <RowUserItem
                      key={`RowUserItem-${u.Id}`}
                      index={index}
                      userDataRow={u}
                      setNotification={setNotification}
                      reload={reload}
                      handleRowCheck={handleRowCheck}
                      selected={selected.indexOf(index) !== -1}
                      setUserToDelete={setUserToRemove}
                      setShowDeleteModal={setShowRemoveModal}
                    />
                  ))}
              </TableBody>
            </Table>
          )}
        </WrapperTable>
        {listUsers && listUsersStatus === "fetched" && (
          <PaginationStyled
            activePage={currentPage}
            totalPages={listUsers.TotalPages === 0 ? 1 : listUsers.TotalPages}
            clickAction={(data: paginationProps) => {
              setCurrentPage(data.goTo ?? 1);
              setEvtName("pagination");
            }}
            showFirstPage
            showLastPage
          />
        )}
      </Wrapper>

      <ModalRemoveUser
        user={userToRemove}
        open={showRemoveModal}
        setOpen={setShowRemoveModal}
        reload={reload}
        setNotification={setNotification}
      />
      <ModalRemoveMultipleUsers
        open={showRemoveMultipleModal}
        setOpen={setShowRemoveMultipleModal}
        handleDeleteUsers={handleDeleteUserSelected}
        deleting={isRemoving}
      />
    </>
  );
};

export default UsersListCard;
