import React from "react";
import ReactDOM from "react-dom";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { GlobalStyle } from "@tanner/partner";
import reportWebVitals from "./reportWebVitals";

import "./utils/yup";

import App from "./components/commons/App";
import AuthProvider from "./components/commons/AuthProvider";
import { reactPlugin } from "./components/commons/AppInsights";

import "./theme.scss";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  // <React.StrictMode>
  <AppInsightsContext.Provider value={reactPlugin}>
    <AuthProvider>
      <GlobalStyle />
      <App />
    </AuthProvider>
  </AppInsightsContext.Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
