import { Order } from "../utils/enums";
import { sanitizeIdForQuery } from "../utils/string";

export const getAllPermissionSummary = (
  pageNumber: number,
  pageSize: number,
  order: Order = Order.ASC
) => {
  return `permission/allpermission?pagenumber=${pageNumber}&pagesize=${pageSize}&orden=${order}`;
};

export const getAllPermissionSummaryByApp = (
  applicationId: string,
  pageNumber: number,
  pageSize: number,
  order: Order = Order.ASC
) => {
  return `permission/allpermission?pagenumber=${pageNumber}&pagesize=${pageSize}&applicationid=${applicationId}&orden=${order}`;
};

export const createPermission = (applicationId: string) => {
  return `/permission/${applicationId}`;
};

export const getAllPermissionsByApp = (
  appId: string,
  pageNumber: number,
  pageSize: number,
  order: Order = Order.ASC
) => {
  const idCleaned = sanitizeIdForQuery(appId);
  return `/permission/${idCleaned}?pagenumber=${pageNumber}&pagesize=${pageSize}&orden=${order}`;
};

export const getAllPermissionsByAppByName = (
  appId: string,
  permissionName: string,
  pageNumber: number,
  pageSize: number,
  order: Order = Order.ASC
) => {
  const idCleaned = sanitizeIdForQuery(appId);
  return `/permission/byname/${idCleaned}/${permissionName}?pagenumber=${pageNumber}&pagesize=${pageSize}&orden=${order}`;
};

export const createPermissionApp = (appId: string) => {
  const idCleaned = sanitizeIdForQuery(appId);
  return `/permission/${idCleaned}`;
};

export const deletePermissionApp = (appId: string, permId: string) => {
  const idCleaned = sanitizeIdForQuery(appId);
  return `/permission/${idCleaned}/${permId}`;
};

export const updatePermission = (appId: string, permId: string) => {
  return `/permission/${appId}/${permId}`;
};
