import * as React from "react";
import { DocumentAdd } from "@tanner/icons";
import { Surface, Grid, SecondaryButton } from "@tanner/partner";
import styled from "styled-components";
import FullContainer from "../../layouts/FullContainer";
import UsersListCard from "../../components/users/UsersListCard";
import Notification from "../../components/commons/Notification";
import useNotification from "../../hooks/useNotification";
import FormUserAdd from "../../components/users/FormUserAdd/FormUserAdd";

const Wrapper = styled.div`
  .alert-height {
    height: 44px;
  }
`;

const DivAppUtilitiesBox = styled.div`
  width: 100%;
  text-align: left;
`;

const DivButton = styled.div`
  display: inline-block;
  margin: 5px;

  .btn-new-per {
    padding: 8px 16px;
    height: 44px;
  }
`;

interface AppUtilitiesBoxProps {
  setShowNewUserForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppUtilitiesBox: React.FC<AppUtilitiesBoxProps> = ({ setShowNewUserForm }) => {
  return (
    <>
      <DivAppUtilitiesBox>
        <DivButton>
          <SecondaryButton
            className="btn-new-per"
            onClick={() => {
              setShowNewUserForm(true);
            }}
          >
            <DocumentAdd />
            Crear usuario
          </SecondaryButton>
        </DivButton>
      </DivAppUtilitiesBox>
    </>
  );
};

const UsersPage = () => {
  const {
    displayNotification,
    variantNotification,
    messageNotification,
    setNotification,
    setDisplayNotification,
  } = useNotification();
  const [refresh, setRefresh] = React.useState(1);
  const [showNewUserForm, setShowNewUserForm] = React.useState(false);

  const reload = () => {
    setRefresh(refresh + 1);
  };

  return (
    <FullContainer
      title="Usuarios"
      utilitiesBox={<AppUtilitiesBox setShowNewUserForm={setShowNewUserForm} />}
    >
      {showNewUserForm && (
        <FormUserAdd
          setShowForm={setShowNewUserForm}
          clickHandlerCancel={() => setShowNewUserForm(false)}
          setNotification={setNotification}
          reload={reload}
        />
      )}

      <Surface shadow={1} radius="medium" nomargin>
        <Grid container>
          <Grid item lg={12} nomargin nopadding>
            <Wrapper>
              <Notification
                displayNotification={displayNotification}
                variantNotification={variantNotification}
                messageNotification={messageNotification}
                setDisplayNotification={setDisplayNotification}
              />
            </Wrapper>
          </Grid>
        </Grid>
        <UsersListCard setNotification={setNotification} refresh={refresh} reload={reload} />
      </Surface>
    </FullContainer>
  );
};

export default UsersPage;
