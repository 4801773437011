import * as React from "react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import fileDownload from "js-file-download";
import axios, { CancelTokenSource } from "axios";
import { DocumentAdd } from "@tanner/icons";
import {
  Checkbox,
  Grid,
  Pagination,
  SecondaryButton,
  Surface,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@tanner/partner";
import { paginationProps } from "@tanner/partner/dist/core/pagination/_types";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import {
  downloadAddRoleFile,
  getRolesFull,
  getApplications,
  uploadAddRolesFile,
  deleteRolApp,
  searchRoleFull,
} from "../../api";
import ToolbarSelectApp from "../../components/commons/ToolbarSelectApp";
import RoleAdd from "../../components/roles/RoleAdd";
import useFetch from "../../hooks/useFetch";
import FullContainer from "../../layouts/FullContainer";
import * as api from "../../api";
import {
  ApplicationsResume,
  Option,
  ListRolesFull,
  RoleToDelete,
  CheckAppType,
  GenericConfirmationModalProps,
} from "../../types";
import ArpLightButton from "../../ui/ArpLightButton";
import SearchInput from "../../ui/SearchInput";
import Notification from "../../components/commons/Notification";
import GenericConfirmationModal from "../../components/commons/GenericConfirmationModal";
import useNotification from "../../hooks/useNotification";
import DeleteRoleModal from "../../components/roles/DeleteRoleModal";
import RoleEditRow from "../../components/roles/RoleEditRow";
import GenericTableLoader from "../../components/loaders/GenericTableLoader";
import { MimeType } from "../../utils/enums";
import UploadFile from "../../components/commons/UploadFile";
import { not, numberOfChecked, union } from "../../utils/math";
import { verifyCancelToken } from "../../utils/http";
import logger from "../../utils/logger";

const DivAppUtilitiesBox = styled.div`
  width: 100%;
  text-align: left;
`;
const DivButton = styled.div`
  display: inline-block;
  margin: 5px;

  .btn-new-rol {
    padding: 8px 16px;
    height: 44px;
  }
`;

const Wrapper = styled.div`
  .alert-height {
    height: 44px;
  }
`;

const UploadSurface = styled(Surface)`
  margin: 0 0 15px 0;
`;

const WrapperTable = styled.div<{ enable: boolean }>`
  padding: 0;

  h3 {
    margin: 0;
    color: ${(props) => (props.enable ? "#646464" : "#b5b5b5")};
  }

  .header {
    background-color: #f1fae5;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8em;
  }

  .subheader {
    border-bottom: 1px solid #f7f7f7;
    padding: 0.8em;
  }

  .wrapper__actions {
    display: flex;
    align-items: center;
    min-height: 90px;
    width: 100%;
    border-bottom: 1px solid #f7f7f7;

    .wrapper__actions-left {
      display: flex;
      justify-content: flex-start;

      input::-ms-input-placeholder {
        color: #afafaf !important;
      }

      input::placeholder {
        color: #afafaf !important;
      }
    }

    .wrapper__search-btn {
      width: 290px;
    }

    .wrapper__actions-right {
      display: flex;
      justify-content: flex-end;

      button {
        margin-right: 10px;
        width: 290px;
        height: 42px;
      }
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0 10px;

    th {
      padding-bottom: 0 !important;
    }

    thead tr {
      color: #afafaf;
      font-size: 14px;
      font-weight: 600;

      th:first-child {
        padding-left: 15px !important;
        width: 50px;
      }
    }

    tbody {
      tr {
        border: 1px solid #ebebeb !important;
        background-color: #f7f7f7;
        height: 50px;
        border-radius: 4px;

        td:first-child {
          padding-left: 15px !important;
        }

        td:nth-child(7) {
          width: 110px;
        }

        td:nth-child(8) {
          width: 120px;
        }
      }
    }

    .table__cell-delete {
      color: #3055bd;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 25px;
      cursor: pointer;

      span {
        margin-right: 10px;
      }
    }
  }
`;
const PaginationStyled = styled(Pagination)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface AppUtilitiesBoxProps {
  setShowNewRoleForm: React.Dispatch<React.SetStateAction<boolean>>;
  setShowUpload: React.Dispatch<React.SetStateAction<boolean>>;
  handleDownloadFile: () => void;
  downloading: boolean;
  enable: boolean;
}

const AppUtilitiesBox: React.FC<AppUtilitiesBoxProps> = ({
  setShowNewRoleForm,
  setShowUpload,
  handleDownloadFile,
  downloading,
  enable,
}) => {
  if (!enable) return <></>;
  return (
    <DivAppUtilitiesBox>
      <DivButton>
        <ArpLightButton
          color="secondary"
          text="Descarga planilla roles"
          icon={downloading ? "ticon-loading" : "ticon-file-download"}
          onClick={() => {
            handleDownloadFile();
          }}
        />
      </DivButton>
      <DivButton>
        <ArpLightButton
          color="secondary"
          text="Carga de roles"
          icon="ticon-upload1"
          onClick={() => {
            setShowNewRoleForm(false);
            setShowUpload((p) => !p);
          }}
        />
      </DivButton>
      <DivButton>
        <SecondaryButton
          className="btn-new-rol"
          onClick={() => {
            setShowNewRoleForm(true);
            setShowUpload(false);
          }}
        >
          <DocumentAdd />
          Crear rol
        </SecondaryButton>
      </DivButton>
    </DivAppUtilitiesBox>
  );
};

type ErrorApi = {
  id: string;
  message: string;
};

type ResponseApi = {
  code: number;
  message: string;
};

let source: CancelTokenSource;

const RolesPage: React.FC = () => {
  const [evtName, setEvtName] = React.useState<"writing" | "pagination" | undefined>();
  const [selectApp, setSelectApp] = React.useState({} as Option);
  const [searchText, setSearchText] = React.useState<string>("");
  const [isSelectApp, setIsSelectApp] = React.useState(false);
  const [isRemoving, setIsRemoving] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [roles, setRoles] = React.useState<ListRolesFull | undefined>();
  const [roleStatus, setRoleStatus] = React.useState<string>("init");
  const [refresh, setRefresh] = React.useState(1);
  const [showUpload, setShowUpload] = React.useState(false);
  const { status: appStatus, data: apps } = useFetch<ApplicationsResume>(getApplications(1, 999));
  const [showNewRoleForm, setShowNewRoleForm] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showDeleteAllModal, setShowDeleteAllModal] = React.useState(false);
  const [roleToDelete, setRoleToDelete] = React.useState<RoleToDelete>({
    id: "",
    name: "",
  });

  const [listCheckRoles, setListCheckRoles] = React.useState<CheckAppType[]>([]);

  const [downloading, setDownloading] = React.useState(false);

  const [selected, setSelected] = React.useState<number[]>([]);
  const [positions] = React.useState<number[]>([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  const tableRef = React.useRef<HTMLDivElement | null>(null);

  const {
    displayNotification,
    variantNotification,
    messageNotification,
    setNotification,
    setDisplayNotification,
  } = useNotification();
  const appInsights = useAppInsightsContext();
  const { getAccessTokenSilently } = useAuth0();

  const genericConfirmationModalProps: GenericConfirmationModalProps = {
    type: "delete",
    header: "Eliminar Roles",
    messageBody: "Se eliminarán los roles seleccionados en el listado",
    messageConfirm: "¿Estás seguro de que deseas continuar?",
    buttonConfirmText: "Eliminar roles",
    buttonCancelText: "Cancelar",
    textProcessing: "Eliminando",
  };

  const handleToggleAll = () => () => {
    const tempList = listCheckRoles;
    if (numberOfChecked(selected, positions) === positions.length) {
      setSelected(not(selected, positions));
      /* eslint no-param-reassign: "error" */
      tempList.forEach((check) => {
        check.isChecked = false;
      });
    } else {
      /* eslint no-param-reassign: "error" */
      tempList.forEach((check) => {
        check.isChecked = true;
      });
      setSelected(union(selected, positions));
    }

    setListCheckRoles(tempList);
  };

  const updateCheckRole = (RoleId: string) => {
    const elementsIndex = listCheckRoles.findIndex((element) => element.value === RoleId);
    const newArray = [...listCheckRoles];

    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      isChecked: !newArray[elementsIndex].isChecked,
    };

    setListCheckRoles(newArray);
  };

  const reload = () => {
    setRefresh(refresh + 1);
    setSelected([]);
  };

  const handleDeleteRoleSelected = async () => {
    setIsRemoving(true);

    const tempList = listCheckRoles;
    const ListPromise: Promise<ResponseApi | PromiseRejectedResult>[] = [];
    const token = await getAccessTokenSilently();
    tempList.forEach((check) => {
      if (check.isChecked) {
        const r = new Promise<ResponseApi | PromiseRejectedResult>((resolve, reject) => {
          const url = deleteRolApp(`:${selectApp.value}`, check.value);

          api
            .authorized(token)
            .delete(url)
            .then((response) => {
              resolve(response.data as ResponseApi);
            })
            .catch((err) => {
              reject(err);
            });
        });
        ListPromise.push(r);
      }
    });

    const data = await Promise.allSettled(ListPromise);

    const err = (data.find((res) => res.status === "rejected") as PromiseRejectedResult | undefined)
      ?.reason;

    if (err) {
      setIsRemoving(false);
      setSelected([]);
      reload();
      if (err.response.status === 409) {
        const errorApi = err.response.data as ErrorApi;
        setNotification(errorApi.message, "warning");
      } else {
        setNotification("Ocurrió un problema eliminando los roles.", "error");
        appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
      }
    } else {
      setSelected([]);
      setIsRemoving(false);
      reload();
      setNotification("Roles eliminados.", "success");
    }
    setShowDeleteAllModal(false);
  };

  const handleRowCheck = (value: number, RoleId: string) => {
    const currentIndex = selected.indexOf(value);
    const newSelected = [...selected];

    updateCheckRole(RoleId);

    if (currentIndex === -1) {
      newSelected.push(value);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
  };

  const handleOnSelectApp = (opt: Option) => {
    setSelectApp(opt);
    setIsSelectApp(true);
  };

  const handleDownloadFile = async () => {
    setDownloading(true);
    const token = await getAccessTokenSilently();
    api
      .authorized(token)
      .get(downloadAddRoleFile(), { responseType: "blob" })
      .then((response) => {
        fileDownload(response.data, "Plantilla roles.xlsx");
        setNotification("Plantilla descargada correctamente", "success");
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logger.info("Cancel request");
        } else {
          appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
          setNotification("Ocurrió un error descargando la plantilla.", "error");
        }
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  React.useEffect(() => {
    if (isSelectApp) {
      let url = getRolesFull(`:${selectApp.value}`, currentPage, 10);

      if (searchText.trim().length > 0 && evtName === "writing") {
        url = searchRoleFull(`:${selectApp.value}`, searchText.trim(), 1, 10);
      }

      if (searchText.trim().length > 0 && evtName === "pagination") {
        url = searchRoleFull(`:${selectApp.value}`, searchText.trim(), currentPage, 10);
      }

      source = verifyCancelToken(source);
      setRoleStatus("fetching");
      setSelected([]);

      (async () => {
        try {
          const token = await getAccessTokenSilently();
          const response = await api.authorized(token).get(url, { cancelToken: source.token });

          setRoles(response.data);
          setRoleStatus("fetched");

          setListCheckRoles([]);
          (response.data as ListRolesFull).Data.map((r) =>
            setListCheckRoles((prevState) => [
              ...prevState,
              { value: r.Id, label: r.Name, isChecked: false },
            ])
          );
        } catch (err) {
          if (axios.isCancel(err)) {
            logger.info("Cancel request");
          } else {
            setRoleStatus("error");
            appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
          }
        }
      })();
    }
  }, [
    evtName,
    selectApp.value,
    appInsights,
    appStatus,
    isSelectApp,
    currentPage,
    refresh,
    searchText,
    getAccessTokenSilently,
  ]);

  return (
    <>
      <FullContainer
        title="Roles"
        utilitiesBox={
          <AppUtilitiesBox
            setShowNewRoleForm={setShowNewRoleForm}
            setShowUpload={setShowUpload}
            handleDownloadFile={async () => handleDownloadFile()}
            downloading={downloading}
            enable={isSelectApp}
          />
        }
      >
        {showNewRoleForm && (
          <RoleAdd
            setShowNewRole={setShowNewRoleForm}
            clickHandlerCancel={() => setShowNewRoleForm(false)}
            selectApp={selectApp}
            setNotification={setNotification}
            reload={reload}
          />
        )}

        {showUpload && (
          <UploadSurface shadow={1} radius="medium">
            <UploadFile
              url={uploadAddRolesFile(`:${selectApp.value}`)}
              btnText="Carga lista de roles"
              filesAccept={[MimeType.ApplicationExcel]}
              setShowUpload={setShowUpload}
              setNotification={setNotification}
              backgroundWhite
              reload={reload}
            />
          </UploadSurface>
        )}

        <Surface shadow={1} radius="medium" nomargin>
          <Grid container>
            <Grid item lg={12} nomargin nopadding>
              <Wrapper>
                <Notification
                  displayNotification={displayNotification}
                  variantNotification={variantNotification}
                  messageNotification={messageNotification}
                  setDisplayNotification={setDisplayNotification}
                />
              </Wrapper>
            </Grid>
          </Grid>
          <Grid container center nomargin>
            <Grid item sm={12}>
              <ToolbarSelectApp
                apps={apps?.Data}
                onSelect={handleOnSelectApp}
                isLoading={appStatus === "fetching"}
              />
            </Grid>
          </Grid>
          <WrapperTable ref={tableRef} enable={isSelectApp}>
            <h3>Lista de roles</h3>
            <Grid container sm={12} md={12} lg={12} nopadding nomargin className="wrapper__actions">
              <Grid item md={6} lg={6} nomargin nopadding className="wrapper__actions-left">
                <SearchInput
                  id="input-search-roles"
                  placeholder="Buscar por nombre"
                  className="wrapper__search-btn"
                  onChange={(evt) => {
                    setSearchText(evt.currentTarget.value);
                    setCurrentPage(1);
                    setEvtName("writing");
                  }}
                  value={searchText}
                  disabled={!isSelectApp}
                />
              </Grid>
              <Grid item md={6} lg={6} nomargin nopadding className="wrapper__actions-right">
                <ArpLightButton
                  color="primary"
                  disabled={selected.length === 0 || isRemoving}
                  text="Eliminar roles seleccionados"
                  icon={isRemoving ? "ticon-loading" : "ticon-misuse"}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowDeleteAllModal(true);
                  }}
                />
              </Grid>
            </Grid>

            {roleStatus === "fetching" && (
              <GenericTableLoader
                height={600}
                width={(tableRef?.current?.offsetWidth ?? 1150) - 10}
              />
            )}
            {roleStatus === "fetched" && (
              <Table borderless>
                <TableHead>
                  <TableRow>
                    <TableCell component="th">
                      <Checkbox
                        value="all"
                        label=""
                        onChange={handleToggleAll()}
                        checked={
                          positions.length !== 0 &&
                          numberOfChecked(selected, positions) === positions.length
                        }
                      />
                    </TableCell>
                    <TableCell component="th">Nombre de rol</TableCell>
                    <TableCell component="th">Descripción</TableCell>
                    <TableCell component="th">Permisos</TableCell>
                    <TableCell component="th">Usuarios</TableCell>
                    <TableCell component="th" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roles &&
                    roleStatus === "fetched" &&
                    roles.Data.map((r, index) => (
                      <RoleEditRow
                        key={`roleeditrow-${r.Id}`}
                        index={index}
                        role={r}
                        handleRowCheck={handleRowCheck}
                        selected={selected.indexOf(index) !== -1}
                        setRoleToDelete={setRoleToDelete}
                        setShowDeleteModal={setShowDeleteModal}
                        setNotification={setNotification}
                        appId={selectApp.value}
                        reload={reload}
                      />
                    ))}
                  {roles && roleStatus === "fetched" && roles.Data.length === 0 && (
                    <TableRow key={0}>
                      <TableCell />
                      <TableCell title="Nombre de role">
                        No existen roles para esta aplicación.
                      </TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </WrapperTable>
          {roles && roleStatus === "fetched" && (
            <PaginationStyled
              activePage={currentPage}
              totalPages={roles.TotalPages === 0 ? 1 : roles.TotalPages}
              clickAction={(data: paginationProps) => {
                setCurrentPage(data.goTo ?? 1);
                setEvtName("pagination");
              }}
              showFirstPage
              showLastPage
            />
          )}
        </Surface>

        <DeleteRoleModal
          role={roleToDelete}
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          reload={reload}
          setNotification={setNotification}
          appId={selectApp.value === undefined ? "" : selectApp.value}
        />

        <GenericConfirmationModal
          open={showDeleteAllModal}
          setOpen={setShowDeleteAllModal}
          processing={isRemoving}
          handleConfirmation={handleDeleteRoleSelected}
          genericConfirmationModalProps={genericConfirmationModalProps}
        />
      </FullContainer>
    </>
  );
};
export default RolesPage;
