import * as React from "react";
import { Button } from "@tanner/partner";
import { ButtonProps } from "@tanner/partner/dist/core/common/button/types";
import classNames from "classnames";
import styled, { css } from "styled-components";

type Props = {
  color: "primary" | "secondary" | "gray";
  className?: string;
  icon?: string;
  text: string;
  disabled?: boolean;
} & ButtonProps;

type ButtonStyledProps = Omit<Props, "icon" | "text">;

const ButtonStyled = styled(Button)<ButtonStyledProps>`
  width: 100%;
  border: ${(props) => {
    let border: string;
    switch (props.color) {
      case "primary":
        border = "solid 1px #005CB9";
        break;
      case "gray":
        border = "solid 1px #DFDFDF";
        break;
      default:
        border = "solid 1px #fff";
    }
    return border;
  }};
  // border: solid 1px ${(props) => (props.color === "primary" ? "#005CB9" : "#fff")};
  font-weight: 600;
  font-size: 1rem;
  line-height: 21px;
  color: ${(props) => {
    let color: string;
    switch (props.color) {
      case "primary":
        color = "#005CB9";
        break;
      case "gray":
        color = "#AFAFAF";
        break;
      default:
        color = "#fff";
    }
    return color;
  }};
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 10px 10px;

  ${(props) =>
    props.disabled &&
    css`
      background-color: #ebebeb;
      border: none;
      color: #afafaf;
      cursor: auto;
    `}
  i {
    width: 14px;
    height: 14px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: 9px;

    &::before {
      font-size: 18px;
    }
  }
`;

const ArpLightButton: React.FC<Props> = (props) => {
  const { icon, text, onClick, color, className, disabled = false } = props;
  return (
    <ButtonStyled className={className} onClick={onClick} color={color} disabled={disabled}>
      {icon && <i className={classNames(icon)} />} {text}
    </ButtonStyled>
  );
};

export default ArpLightButton;
