import React from "react";
import ContentLoader from "react-content-loader";

const ItemCreatedCardLoader = () => (
  <ContentLoader
    speed={2}
    width={300}
    height={150}
    viewBox="0 0 400 200"
    backgroundColor="#f7f7f7"
    foregroundColor="#ecebeb"
  >
    <rect x="35" y="20" rx="0" ry="0" width="70" height="75" />
    <rect x="120" y="20" rx="0" ry="0" width="90" height="45" />
    <rect x="120" y="75" rx="0" ry="0" width="148" height="20" />
  </ContentLoader>
);

export default ItemCreatedCardLoader;
