import React from "react";
import ContentLoader from "react-content-loader";

const RolesAppsTableLoader = () => (
  <ContentLoader
    speed={2}
    width={800}
    height={550}
    viewBox="0 0 800 550"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="14" y="23" rx="3" ry="3" width="88" height="17" />
    <rect x="14" y="53" rx="3" ry="3" width="800" height="50" />
    <rect x="14" y="114" rx="3" ry="3" width="800" height="50" />
    <rect x="14" y="172" rx="3" ry="3" width="800" height="50" />
    <rect x="14" y="231" rx="3" ry="3" width="800" height="50" />
    <rect x="14" y="291" rx="3" ry="3" width="800" height="50" />
    <rect x="189" y="21" rx="3" ry="3" width="88" height="17" />
    <rect x="312" y="22" rx="3" ry="3" width="88" height="17" />
  </ContentLoader>
);

export default RolesAppsTableLoader;
