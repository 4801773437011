import { sanitizeIdForQuery } from "../utils/string";

export const downloadAddPermissionFile = (appId: string) => {
  const appIdCleaned = sanitizeIdForQuery(appId);
  return `/loads/template/addpermissions/${appIdCleaned}`;
};

export const uploadAddPermissionFile = (appId: string) => {
  const appIdCleaned = sanitizeIdForQuery(appId);
  return `/loads/addpermissions/${appIdCleaned}`;
};

export const downloadAddRoleFile = () => {
  return `/loads/template/addroles/`;
};

export const uploadAddRolesFile = (appId: string) => {
  const appIdCleaned = sanitizeIdForQuery(appId);
  return `/loads/addroles/${appIdCleaned}`;
};

export const templateAddUsers = (appId: string) => {
  const appIdCleaned = sanitizeIdForQuery(appId);
  return `/loads/template/addusers/${appIdCleaned}`;
};

export const uploadAddUsers = (appId: string) => {
  const appIdCleaned = sanitizeIdForQuery(appId);
  return `/loads/addusers/${appIdCleaned}`;
};
