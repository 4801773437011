import { sanitizeIdForQuery } from "../utils/string";

export const getAllRolesSummaryByUser = (userId: string, pageNumber: number, pageSize: number) => {
  return `role/allroles?pagenumber=${pageNumber}&pagesize=${pageSize}&userid=${userId}`;
};

export const getAllRolesSummary = (pageNumber: number, pageSize: number) => {
  return `role/allroles?pagenumber=${pageNumber}&pagesize=${pageSize}`;
};

export const getAllRolesSummaryByApp = (
  applicationId: string,
  pageNumber: number,
  pageSize: number
) => {
  return `role/${applicationId}?pagenumber=${pageNumber}&pagesize=${pageSize}`;
};

export const getRolesFull = (applicationId: string, pageNumber: number, pageSize: number) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `role/full/${idCleaned}?pagenumber=${pageNumber}&pagesize=${pageSize}`;
};
export const createRolApp = (applicationId: string) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `role/${idCleaned}`;
};

export const deleteRolApp = (applicationId: string, roleId: string) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `role/${idCleaned}/${roleId}`;
};
export const removeUserRol = (applicationId: string, roleId: string, userId: string) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `role/removeuser/${idCleaned}/${roleId}/${userId}`;
};
export const getUserByRol = (
  applicationId: string,
  roleId: string,
  pageNumber: number,
  pageSize: number
) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `role/users/${idCleaned}/${roleId}?pagenumber=${pageNumber}&pagesize=${pageSize}`;
};
export const getPermissionsByRol = (
  applicationId: string,
  roleId: string,
  pageNumber: number,
  pageSize: number
) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `role/permissions/${idCleaned}/${roleId}?pagenumber=${pageNumber}&pagesize=${pageSize}`;
};
export const searchPermissionInRol = (
  applicationId: string,
  roleId: string,
  permissionName: string,
  pageNumber: number,
  pageSize: number
) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `role/search/permissions/${idCleaned}/${roleId}?permissionname=${permissionName}&pagenumber=${pageNumber}&pagesize=${pageSize}`;
};
export const searchUserInRol = (
  applicationId: string,
  roleId: string,
  userName: string,
  pageNumber: number,
  pageSize: number
) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `role/search/users/${idCleaned}/${roleId}?username=${userName}&pagenumber=${pageNumber}&pagesize=${pageSize}`;
};
export const revokePermissionRol = (
  applicationId: string,
  roleId: string,
  permissionid: string
) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `role/revokepermission/${idCleaned}/${roleId}/${permissionid}`;
};
export const getUsersForAddToRole = (
  applicationId: string,
  roleId: string,
  userName: string,
  pageNumber: number,
  pageSize: number
) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `role/getusersforaddtorole/${idCleaned}/${roleId}?username=${userName}&pagenumber=${pageNumber}&pagesize=${pageSize}`;
};

export const addUserToRole = (applicationId: string, roleId: string, userId: string) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `role/adduser/${idCleaned}/${roleId}/${userId}`;
};

export const getPermissionsForAddToRole = (
  applicationId: string,
  roleId: string,
  permissionName: string,
  pageNumber: number,
  pageSize: number
) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `role/getpermissionsforaddtorole/${idCleaned}/${roleId}?permissionname=${permissionName}&pagenumber=${pageNumber}&pagesize=${pageSize}`;
};

export const addPermissionToRole = (
  applicationId: string,
  roleId: string,
  permissionId: string
) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `role/addpermission/${idCleaned}/${roleId}/${permissionId}`;
};

export const searchRoleFull = (
  applicationId: string,
  rolename: string,
  pageNumber: number,
  pageSize: number
) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `role/search/${idCleaned}?rolename=${rolename}&pagenumber=${pageNumber}&pagesize=${pageSize}`;
};

export const getRolesFromUser = (
  applicationId: string,
  userId: string,
  pageNumber: number,
  pageSize: number
) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `role/fromuser/${idCleaned}/${userId}?pagenumber=${pageNumber}&pagesize=${pageSize}`;
};

export const updateRole = (applicationId: string, roleId: string) => {
  const idCleaned = sanitizeIdForQuery(applicationId);
  return `role/${idCleaned}/${roleId}`;
};
