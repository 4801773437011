import * as React from "react";
import { TextField, Button } from "@tanner/partner";

import styled from "styled-components";
import { api, getUsersForAddToRole, addUserToRole } from "../../../api";
import { ListUserAuth0 } from "../../../types";
import { NotificationState } from "../../../hooks/useNotification";

type InputSearchUserForAddRoleProps = {
  appId: string;
  roleId: string;
  setNotification: (message: string, type: NotificationState) => void;
  reload: () => void;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  setCountUser: React.Dispatch<React.SetStateAction<number>>;
};

const ItemsSelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  transition: 0.9s;
  overflow: hidden;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16), 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 1px 3px 0 rgba(0, 0, 0, 0.16);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const Wrapper = styled.div`
  z-index: 99;
  .input-md {
    margin-bottom: 0 !important;
  }
`;

const Option = styled.span`
  margin: 0 25px 0 16px;
  border-bottom: 0.5px solid #ededed;
  cursor: pointer;
  min-height: 43px;
  display: flex;
  align-items: center;
  width: 93%;
  color: #727171;
  line-height: 19px;

  p {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
  }

  span[class^="ticon"] {
    font-size: 22px;
    color: #3055bd;
  }

  span.ticon-user {
    color: #727171;
    position: relative;
    right: 0;
  }

  &:nth-child(6) {
    border-style: none !important;
  }
`;

const InputSearchUserForAddRole: React.FC<InputSearchUserForAddRoleProps> = ({
  appId,
  roleId,
  setNotification,
  reload,
  setShowForm,
  setCountUser,
}) => {
  const [searchText, setSearchText] = React.useState<string>("");
  const [userDetailStatus, setUserDetailStatus] = React.useState<string>("init");
  const [addSelectUserToRolStatus, setAddSelectUserToRolStatus] = React.useState<string>("init");
  const [listUserDetail, setListUserDetail] = React.useState<ListUserAuth0 | undefined>();

  const handleSearch = (text: string): void => {
    setSearchText(text);
    if (text.trim() === "" || text.trim().length === 0) {
      setListUserDetail(undefined);
      return;
    }
    if (userDetailStatus === "fetched" || userDetailStatus === "init") {
      setUserDetailStatus("fetching");
      const url = getUsersForAddToRole(appId, roleId, text.trim(), 1, 10);
      api
        .get(url)
        .then((response) => {
          setListUserDetail(response.data);
          setUserDetailStatus("fetched");
        })
        .catch(() => {
          setListUserDetail(undefined);
        });
    }
  };

  const addSelectUserToRol = (userId: string) => {
    if (addSelectUserToRolStatus === "fetched" || addSelectUserToRolStatus === "init") {
      setAddSelectUserToRolStatus("fetching");
      const url = addUserToRole(appId, roleId, userId);
      api
        .post(url)
        .then(() => {
          setAddSelectUserToRolStatus("fetched");
          setNotification("Usuario asignado al rol correctamente.", "success");

          setShowForm(false);
          setCountUser((p) => p + 1);
          reload();
        })
        .catch(() => {
          setAddSelectUserToRolStatus("error");
          setNotification("Ocurrió un error asignando el usuario al rol.", "error");
        });
    }
  };

  return (
    <Wrapper>
      <label htmlFor="nameRoleInput">
        Agregar usuario
        <TextField
          className="input-md"
          placeholder="Escribir nombre de usuario"
          onChange={(evt) => handleSearch(evt.currentTarget.value)}
          value={searchText}
          disabled={addSelectUserToRolStatus === "fetching"}
        />
      </label>
      {addSelectUserToRolStatus === "fetching" && (
        <ItemsSelect>
          <Option>
            <span className="ticon-loading" />
            <p>Asignando...</p>
          </Option>
        </ItemsSelect>
      )}
      {addSelectUserToRolStatus === "init" && (
        <ItemsSelect>
          {userDetailStatus === "fetching" && (
            <Option>
              <span className="ticon-loading" />
            </Option>
          )}
          {listUserDetail?.Data.slice(0, 10).map((u) => (
            <Option key={`op-${u.user_id}`}>
              <span className="ticon-follow" />
              <Button
                onClick={() => {
                  addSelectUserToRol(u.user_id);
                }}
              >
                <p>{u.name}</p>
              </Button>
            </Option>
          ))}
        </ItemsSelect>
      )}
    </Wrapper>
  );
};

export default InputSearchUserForAddRole;
