import * as React from "react";
import styled from "styled-components";
import useFetch from "../../hooks/useFetch";
import { getUrlApiVersion } from "../../api";
import { version } from "../../../package.json";

const FooterStyled = styled.footer`
  min-height: 30px;
  position: relative;
  bottom: 0;
  margin: 40px 0 0 0 !important;

  & span {
    font-size: 0.75em;
    font-weight: 300;
    color: #595959;
  }
`;

const Footer: React.FC = () => {
  const { data } = useFetch<string>(getUrlApiVersion());

  return (
    <FooterStyled>
      <span>
        © {new Date().getFullYear()} - Tanner ARP WEB - Version: {version} / Tanner ARP API -
        Version: {data}
      </span>
    </FooterStyled>
  );
};

export default Footer;
