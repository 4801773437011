import * as React from "react";
import styled from "styled-components";
import { Checkbox, TableCell } from "@tanner/partner";
import { User, UserToRemove } from "../../../types";
import UserEditRowForm from "../UserEditRowForm";
import UserMetadataEditRowForm from "../UserMetadataEditRowForm";
import { NotificationState } from "../../../hooks/useNotification";

type UserRowItemRowProps = {
  index: number;
  userDataRow: User;
  handleRowCheck: (text: number, userId: string) => void;
  selected: boolean;
  setUserToDelete: React.Dispatch<React.SetStateAction<UserToRemove>>;
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setNotification: (message: string, type: NotificationState) => void;
  reload: () => void;
};

const TableRowStyled = styled.tr`
  background-color: #ebebeb;

  .title {
    font-size: 16px;
  }

  .header-box-btn {
    flex-direction: row;

    button {
      height: 44px;
      width: 180px;
      justify-content: center;
      margin: 0 25px 0 0;
    }

    .btn-cancel {
      color: #005cb9;
      background-color: transparent;
      border: 1px solid #005cb9 !important;
    }
  }

  .grid-center {
    display: flex;
    justify-content: flex-start;
  }

  .body {
    max-height: 400px;
    padding: 0.8em;

    .form-user {
      padding-right: 1.5em;

      label {
        font-size: 14px;
      }

      input,
      textarea {
        width: 386px;
        padding: 11px 20px 11px 15px !important;
        margin: 0 !important;

        ::placeholder {
          color: #bcbcbc;
        }

        ::-ms-input-placeholder {
          color: #bcbcbc;
        }
      }
    }
  }
`;

const UserRowItem: React.FC<UserRowItemRowProps> = ({
  userDataRow,
  handleRowCheck,
  setUserToDelete,
  setShowDeleteModal,
  setNotification,
  reload,
  selected,
  index,
}) => {
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openEditMetadata, setOpenEditMetadata] = React.useState(false);
  React.useEffect(() => {}, [selected, handleRowCheck]);
  return (
    <>
      <TableRowStyled key={`UserRowItem-row-${userDataRow.Id}`}>
        <TableCell>
          <Checkbox
            value={userDataRow.Id}
            label=""
            checked={selected}
            onChange={() => handleRowCheck(index, userDataRow.Id)}
          />
        </TableCell>
        <TableCell title="Nombre de usuarios">{userDataRow.Name}</TableCell>
        <TableCell title="RUT">{userDataRow.RUT}</TableCell>
        <TableCell title="Email">{userDataRow.Email}</TableCell>
        <TableCell title="Roles asignados">+{userDataRow.UserRoleCounter}</TableCell>
        <TableCell />
        <TableCell className="table__cellwidth-edit">
          <div
            role="button"
            tabIndex={-1}
            onKeyDown={() => {
              setOpenEditMetadata(false);
              setOpenEdit(!openEdit);
            }}
            className="table__cell-delete"
            onClick={() => {
              setOpenEditMetadata(false);
              setOpenEdit(!openEdit);
            }}
          >
            <span className="ticon-edit" />
            Editar
          </div>
        </TableCell>
        <TableCell className="table__cellwidth-edit">
          <div
            role="button"
            tabIndex={-1}
            onKeyDown={() => {
              setOpenEdit(false);
              setOpenEditMetadata(!openEditMetadata);
            }}
            className="table__cell-delete"
            onClick={() => {
              setOpenEdit(false);
              setOpenEditMetadata(!openEditMetadata);
            }}
          >
            <span className="ticon-edit" />
            Modificar Metadata
          </div>
        </TableCell>
        <TableCell className="table__cellwidth-edit">
          <div
            role="button"
            tabIndex={-1}
            onKeyDown={() => {
              setUserToDelete({
                id: userDataRow.Id,
                name: userDataRow.Name,
                rut: userDataRow.RUT,
                rolId: "",
                rolName: "",
              });
              setShowDeleteModal(true);
            }}
            className="table__cell-delete"
            onClick={() => {
              setUserToDelete({
                id: userDataRow.Id,
                name: userDataRow.Name,
                rut: userDataRow.RUT,
                rolId: "",
                rolName: "",
              });
              setShowDeleteModal(true);
            }}
          >
            <span className="ticon-delete" />
            Eliminar
          </div>
        </TableCell>
      </TableRowStyled>
      {openEdit && (
        <UserEditRowForm
          key={`edit-${userDataRow.Id}`}
          userDataRow={userDataRow}
          setShowEdit={setOpenEdit}
          appId={userDataRow.Id}
          setNotification={setNotification}
          reload={reload}
        />
      )}
      {openEditMetadata && (
        <UserMetadataEditRowForm
          key={`editmetadata-${userDataRow.Id}`}
          userDataRow={userDataRow}
          setShowEditMetadata={setOpenEditMetadata}
          appId={userDataRow.Id}
          setNotification={setNotification}
          reload={reload}
        />
      )}
    </>
  );
};

export default UserRowItem;
