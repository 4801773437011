import React from "react";
import styled from "styled-components";
import eclipse from "../../../assets/images/eclipse.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #315bb9;

  .ticon-loading {
    color: white;
    font-size: 120px;
  }
`;

const Loading: React.FC = () => {
  return (
    <Wrapper>
      <img src={eclipse} alt="Cargando..." />
    </Wrapper>
  );
};

export default Loading;
