import React from "react";
import ContentLoader from "react-content-loader";

const SelectLoader = () => (
  <ContentLoader
    speed={2}
    width={550}
    height={130}
    viewBox="0 0 550 130"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="34" y="64" rx="3" ry="3" width="167" height="17" />
    <rect x="33" y="91" rx="3" ry="3" width="508" height="50" />
    <rect x="118" y="21" rx="3" ry="3" width="351" height="24" />
  </ContentLoader>
);

export default SelectLoader;
