import * as React from "react";
import { ErrorButton, Modal, SecondaryButton } from "@tanner/partner";
import styled from "styled-components";
import { ApplicationToDelete } from "../../../types";

const ModalStyled = styled(Modal)`
  padding-top: 25vh;

  div:first-child {
    margin-left: 0;
    margin-right: 0;
  }

  .modal-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .ticon-warning {
      font-size: 74px;
      color: #f48f00;
    }

    h4 {
      margin: 15px 0 10px 0;
      color: #005cb9;
    }

    p {
      color: #333333;
    }
    .msj {
      width: 600px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
  }

  div[class*="modal-title"] {
    height: 0 !important;
    margin: 0 !important;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    gap: 80px;
    width: 100%;
    margin-bottom: 10px;

    button {
      color: #005cb9;
    }

    button:first-child {
      background-color: transparent;
    }

    button:nth-child(2) {
      color: #fff;
      background-color: #f36963;
    }
  }
`;

type Props = {
  app: ApplicationToDelete;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleting: boolean;
  handleDeleteApp: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type BodyProps = Pick<Props, "app">;

const Body: React.FC<BodyProps> = (props) => {
  const { app } = props;
  return (
    <div className="modal-body">
      <span className="ticon-warning" />
      <h4>Eliminar aplicación</h4>
      <p className="msj">
        Se eliminará la aplicación: <strong>{app.name} </strong>({app.id}).
      </p>
      <p>¿Estás seguro de que deseas continuar?</p>
    </div>
  );
};

type FooterProps = {
  deleting: boolean;
  handleDeleteApp: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
} & Pick<Props, "setOpen">;

const Footer: React.FC<FooterProps> = (props) => {
  const { setOpen, handleDeleteApp, deleting } = props;

  return (
    <div className="modal-footer">
      <ErrorButton className="btn-cancel" onClick={() => setOpen(false)}>
        Cancelar
      </ErrorButton>
      <SecondaryButton onClick={handleDeleteApp} disabled={deleting}>
        {deleting && <span className="ticon-loading" />}
        {deleting ? "Eliminando" : "Confirmar"}
      </SecondaryButton>
    </div>
  );
};

const DeleteApplicationModal: React.FC<Props> = (props) => {
  const { open, setOpen, app, handleDeleteApp, deleting } = props;
  return (
    <ModalStyled
      isOpen={open}
      title=""
      size="md"
      content={<Body app={app} />}
      actions={<Footer setOpen={setOpen} handleDeleteApp={handleDeleteApp} deleting={deleting} />}
    />
  );
};

export default DeleteApplicationModal;
