import * as React from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import classNames from "classnames";

import { Drawer, DrawerItem, DrawerItemSeparator } from "@tanner/partner";

import { useAuth0 } from "@auth0/auth0-react";
import { RoutesPath } from "../../../utils/enums";

const Wrapper = styled.div`
  nav {
    min-height: 100% !important;
    position: fixed !important;
  }
  nav .drawer-bottom-box {
    width: 100% !important;
    position: absolute !important;
    bottom: 0 !important;
  }
  nav .drawer-bottom-box hr {
    left: -10px !important;
    position: relative !important;
  }
`;

const NavigationDrawer: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const { logout } = useAuth0();

  return (
    <Wrapper>
      <Drawer
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        iconColor="#FFF"
      >
        <NavLink to={RoutesPath.Home} activeClassName="active">
          <DrawerItem
            icon={
              <span
                className={classNames(
                  "ticon-data-1",
                  "icon-drawer",
                  location.pathname === RoutesPath.Home && "active"
                )}
              />
            }
            label="Dashboard"
            active={location.pathname === RoutesPath.Home}
            className=""
          />
        </NavLink>
        <NavLink to={RoutesPath.Applications} activeClassName="active">
          <DrawerItem
            className=""
            icon={
              <span
                className={classNames(
                  "ticon-application",
                  "icon-drawer",
                  location.pathname.includes(RoutesPath.Applications) && "active"
                )}
              />
            }
            label="Aplicaciones"
            active={location.pathname.includes(RoutesPath.Applications)}
          />
        </NavLink>
        <NavLink to={RoutesPath.Roles} activeClassName="active">
          <DrawerItem
            className=""
            icon={
              <span
                className={classNames(
                  "ticon-role",
                  "icon-drawer",
                  location.pathname === RoutesPath.Roles && "active"
                )}
              />
            }
            label="Roles"
            active={location.pathname === RoutesPath.Roles}
          />
        </NavLink>
        <NavLink to={RoutesPath.Permissions} activeClassName="active">
          <DrawerItem
            className=""
            icon={
              <span
                className={classNames(
                  "ticon-admin",
                  "icon-drawer",
                  location.pathname === RoutesPath.Permissions && "active"
                )}
              />
            }
            label="Permisos"
            active={location.pathname === RoutesPath.Permissions}
          />
        </NavLink>
        <NavLink to={RoutesPath.Users} activeClassName="active">
          <DrawerItem
            className=""
            icon={
              <span
                className={classNames(
                  "ticon-follow",
                  "icon-drawer",
                  location.pathname === RoutesPath.Users && "active"
                )}
              />
            }
            label="Usuarios"
            active={location.pathname === RoutesPath.Users}
          />
        </NavLink>
        <div className="drawer-bottom-box">
          <DrawerItemSeparator />
          <DrawerItem
            className=""
            icon={<span className="ticon-exit icon-drawer" />}
            label="Cerrar sesión"
            onClick={() => logout({ returnTo: window.location.origin })}
            active={false}
          />
        </div>
      </Drawer>
    </Wrapper>
  );
};

export default NavigationDrawer;
