import * as React from "react";
import { TextField, Button } from "@tanner/partner";

import styled from "styled-components";
import { api, getPermissionsForAddToRole, addPermissionToRole } from "../../../api";
import { PermissionsApp } from "../../../types";
import { NotificationState } from "../../../hooks/useNotification";

type InputSearchUserForAddRoleProps = {
  appId: string;
  roleId: string;
  setNotification: (message: string, type: NotificationState) => void;
  reload: () => void;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  setCountPerm: React.Dispatch<React.SetStateAction<number>>;
};

const ItemsSelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  transition: 0.9s;
  overflow: hidden;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16), 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 1px 3px 0 rgba(0, 0, 0, 0.16);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const Wrapper = styled.div`
  z-index: 99;
  .input-md {
    margin-bottom: 0 !important;
  }
`;

const Option = styled.span`
  margin: 0 25px 0 16px;
  border-bottom: 0.5px solid #ededed;
  cursor: pointer;
  min-height: 43px;
  display: flex;
  align-items: center;
  width: 93%;
  color: #727171;
  line-height: 19px;

  p {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
  }

  span[class^="ticon"] {
    font-size: 22px;
    color: #3055bd;
  }

  span.ticon-user {
    color: #727171;
    position: relative;
    right: 0;
  }

  &:nth-child(6) {
    border-style: none !important;
  }
`;

const InputSearchPermissionForAddRole: React.FC<InputSearchUserForAddRoleProps> = ({
  appId,
  roleId,
  setNotification,
  reload,
  setShowForm,
  setCountPerm,
}) => {
  const [searchText, setSearchText] = React.useState<string>("");
  const [permissionDetailStatus, setPermissionDetailStatus] = React.useState<string>("init");
  const [
    addSelectPermissionToRolStatus,
    setAddSelectPermissionToRolStatus,
  ] = React.useState<string>("init");
  const [listPermissionsDetail, setListPermissionsDetail] = React.useState<
    PermissionsApp | undefined
  >();

  const handleSearch = (text: string): void => {
    setSearchText(text);
    if (text.trim() === "" || text.trim().length === 0) {
      setListPermissionsDetail(undefined);
      return;
    }
    if (permissionDetailStatus === "fetched" || permissionDetailStatus === "init") {
      setPermissionDetailStatus("fetching");
      const url = getPermissionsForAddToRole(appId, roleId, text.trim(), 1, 10);
      api
        .get(url)
        .then((response) => {
          setListPermissionsDetail(response.data);
          setPermissionDetailStatus("fetched");
        })
        .catch(() => {
          setListPermissionsDetail(undefined);
        });
    }
  };

  const addSelectPermissionToRol = (permissionId: string) => {
    if (addSelectPermissionToRolStatus === "fetched" || addSelectPermissionToRolStatus === "init") {
      setAddSelectPermissionToRolStatus("fetching");
      const url = addPermissionToRole(appId, roleId, permissionId);
      api
        .post(url)
        .then(() => {
          setAddSelectPermissionToRolStatus("fetched");
          setNotification("Permiso asignado al rol correctamente.", "success");

          setShowForm(false);
          setCountPerm((p) => p + 1);
          reload();
        })
        .catch(() => {
          setAddSelectPermissionToRolStatus("error");
          setNotification("Ocurrió un error asignando el permiso al rol.", "error");
        });
    }
  };

  return (
    <Wrapper>
      <label htmlFor="nameRoleInput">
        Agregar permiso
        <TextField
          className="input-md"
          placeholder="Escribir nombre de permiso"
          onChange={(evt) => handleSearch(evt.currentTarget.value)}
          value={searchText}
          disabled={addSelectPermissionToRolStatus === "fetching"}
        />
      </label>
      {addSelectPermissionToRolStatus === "fetching" && (
        <ItemsSelect>
          <Option>
            <span className="ticon-loading" />
            <p>Asignando...</p>
          </Option>
        </ItemsSelect>
      )}
      {addSelectPermissionToRolStatus === "init" && (
        <ItemsSelect>
          {permissionDetailStatus === "fetching" && (
            <Option>
              <span className="ticon-loading" />
            </Option>
          )}
          {listPermissionsDetail?.Data.slice(0, 10).map((u) => (
            <Option key={`op-per-${u.Id}`}>
              <span className="ticon-admin" />
              <Button
                onClick={() => {
                  addSelectPermissionToRol(u.Id);
                }}
              >
                <p>{u.Action}</p>
              </Button>
            </Option>
          ))}
        </ItemsSelect>
      )}
    </Wrapper>
  );
};

export default InputSearchPermissionForAddRole;
