import * as React from "react";
import { Grid, SecondaryButton } from "@tanner/partner";
import { DocumentAdd } from "@tanner/icons";
import styled from "styled-components";
import fileDownload from "js-file-download";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import ArpLightButton from "../../../ui/ArpLightButton";
import { api, downloadAddPermissionFile, downloadAddRoleFile } from "../../../api";
import { NotificationState } from "../../../hooks/useNotification";

const HeaderStyled = styled(Grid)`
  width: 100%;
  min-height: 86px;
  background-color: #f1e2f5;
  color: #3055bd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 31px 25px;

  .header-box-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      width: 200px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }

    button + button {
      margin-left: 10px;
    }

    .header__upload-btn {
      width: 140px !important;
      padding: 11px 11px;

      span {
        text-align: center;
        white-space: nowrap;
      }
    }
  }

  h2 {
    flex: 1;
    margin: 5px;
  }
`;

type Props = {
  appId: string;
  appName: string;
  setShowCreate?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowUpload?: React.Dispatch<React.SetStateAction<boolean>>;
  setNotification?: (message: string, type: NotificationState) => void;
  pageType?: "role" | "permission" | "user";
};

const SubheaderApp: React.FC<Props> = (props) => {
  const {
    setShowCreate = () => {},
    setShowUpload = () => {},
    appId,
    appName,
    setNotification = () => {},
    pageType = "permission",
  } = props;

  const [downloading, setDownloading] = React.useState(false);

  const appInsights = useAppInsightsContext();

  const urls = {
    permission: downloadAddPermissionFile(appId),
    role: downloadAddRoleFile(),
    user: "",
  };

  const handleDownloadFile = () => {
    setDownloading(true);

    api
      .get(urls[pageType], { responseType: "blob" })
      .then((response) => {
        fileDownload(
          response.data,
          `Plantilla ${pageType === "permission" ? "permisos" : "roles"}.xlsx`
        );
        setNotification("Plantilla descargada correctamente", "success");
      })
      .catch((err) => {
        appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error });
        setNotification("Ocurrió un error descargando la plantilla.", "error");
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  return (
    <HeaderStyled container md={12} nopadding nomargin>
      <Grid item lg={pageType === "user" ? 12 : 3} sm={12} nopadding>
        <h2>{appName}</h2>
      </Grid>
      {pageType !== "user" && (
        <Grid item lg={9} sm={12} className="header-box-btn" nopadding>
          <ArpLightButton
            color="primary"
            text={`${
              pageType === "permission" ? "Descarga plantilla permisos" : "Descarga plantilla roles"
            }`}
            icon={downloading ? "ticon-loading" : "ticon-file-download"}
            onClick={handleDownloadFile}
          />
          <ArpLightButton
            color="primary"
            text={`${pageType === "permission" ? "Carga de permisos" : "Carga de roles"}`}
            icon="ticon-upload1"
            onClick={() => {
              setShowCreate(false);
              setShowUpload((p) => !p);
            }}
          />
          <SecondaryButton
            className="header__upload-btn"
            onClick={() => {
              setShowUpload(false);
              setShowCreate((p) => !p);
            }}
          >
            <DocumentAdd />
            <span>{pageType === "permission" ? "Crear permiso" : "Crear rol"}</span>
          </SecondaryButton>
        </Grid>
      )}
    </HeaderStyled>
  );
};
export default SubheaderApp;
